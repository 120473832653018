<template>
   <div class="profile-messangers">
      <div class="profile-messangers__label">
         <AdviceText>{{ $t('Profile.connectSocial') }}</AdviceText>
      </div>

      <div class="profile-messangers__box">
         <ContactsTwitter :imgName="'twitterReg.svg'" :isAdded="!!profile.twitter_id" />
         <ContactsFacebook :imgName="'facebookReg.svg'" :isAdded="!!profile.facebook_id" />
         <ContactsGoogle :imgName="'googleReg.svg'" :isAdded="!!profile.google_id" />
      </div>
   </div>
</template>

<script>
import ContactsGoogle from '../ContactsConponents/ContactsGoogle.vue';
import ContactsFacebook from '../ContactsConponents/ContactsFacebook.vue';
import ContactsTwitter from '../ContactsConponents/ContactsTwitter.vue';
import AdviceText from '../AdviceComponents/AdviceText.vue';
export default {
   components: {
      ContactsGoogle,
      ContactsFacebook,
      ContactsTwitter,
      AdviceText,
   },

   computed: {
      profile() {
         return this.$store.getters['profile/getProfile'];
      },
   },
};
</script>

<style scoped lang="sass">
@import '../../sass/_variables'

.profile-messangers
   margin-top: 20px
   padding-bottom: 10px
   width: 100%
   //border-bottom: 1px solid #f1f1f1

   &__label
      text-align: center
      font-weight: bold

   &__box
      margin-top: 20px
      width: 100%
      display: flex
      justify-content: center

      & > *
         width: 100px
         margin: 0 5px

      & > *:first-child
         margin-left: 0

@media (max-width: 440px)
   .profile-messangers__box
      display: flex
      flex-wrap: wrap

      & > *
         margin-left: 0
         margin-top: 10px

#dark .profile-messangers
   color: $app-dark-font-color

#sepia .profile-messangers
   //border-bottom: 1px solid $app-sepia-border
</style>