<template>
   <AdviceModal @closeModal="closeModal" :disableCross="true">
      <div class="player">
         <AdvicePlay :listToPlay="instructions" />
      </div>
      <div class="welcome__box">
         <div class="welcome__data">
            <div ref="heightOfAdvice" class="hide">
               <AdviceCategoriesAdviceItem :classes="['slider__item']" :item="testItem" />
            </div>
            <div class="advantages">
               <button class="slider__btn left" @click="handleSliderBack" :disabled="isAnimation || !isBackExist">
                  <svg><use xlink:href="../../assets/img/icons.svg#slider-left-arrow"></use></svg>
               </button>
               <button class="slider__btn right" @click="handleSliderForward" :disabled="isAnimation || isLastStep">
                  <svg><use xlink:href="../../assets/img/icons.svg#slider-left-arrow"></use></svg>
               </button>
               <div
                  class="slider__box"
                  ref="sliderBox"
                  @touchstart="touchStart"
                  @touchmove="touchMove"
                  @touchend="touchEnd"
               >
                  <div
                     v-for="index in lengthOfSlides"
                     :key="index"
                     class="advantages__box"
                     :ref="`item-${index}`"
                     :style="`transform: translateX(${index * 105}%);`"
                  >
                     <div class="advantages__wrap">
                        <AdviceCategoriesAdviceItem
                           v-for="item in [...instructions].splice(index * itemsPerSlide.length, itemsPerSlide.length)"
                           :key="item._id"
                           :classes="['slider__item']"
                           :item="item"
                        />
                     </div>
                  </div>
               </div>
            </div>
            <div class="welcome__item controls">
               <button
                  :disabled="isAnimation || isLastStep"
                  v-for="index in lengthOfSlides"
                  :key="index"
                  :class="isCurrentAdvatage(index) ? ['dot', 'active'] : ['dot']"
                  @click="switchAdvantage(index)"
               ></button>
            </div>
            <div class="welcome__item">
               <div class="welcome__actions">
                  <CheckBox @changed="doNotDisturbToggle">{{ $t('Welcome.doNotDisturb') }}</CheckBox>
                  <button class="welcome__btn" type="button" @click="btnContinue">
                     <AdviceText :bigger="true">{{ $t('Welcome.btnContinue') }}</AdviceText>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </AdviceModal>
</template>

<script>
import AdviceText from '../../components/AdviceComponents/AdviceText.vue';
import AdviceModal from '../../components/AdviceComponents/AdviceModal.vue';
import CheckBox from '../../components/UI/CheckBox.vue';
import mutations from '../../store/mutation-types';
import AdviceCategoriesAdviceItem from '../../components/AdviceCategoriesComponents/AdviceCategoriesAdviceItem.vue';
import AdvicePlay from '../../components/AdviceComponents/AdvicePlay.vue';
export default {
   components: {
      AdviceModal,
      CheckBox,
      AdviceText,
      AdviceCategoriesAdviceItem,
      AdvicePlay,
   },

   data() {
      return {
         firstMove: 0,
         lastMove: 0,
         currentStep: 0,
         stepToShow: 0,

         testItem: {
            _id: Math.random(),
            media: '/storage/audio/4_6003558462338894856.m4a',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lacinia condimentum pellentesque. Duis nisl quam, bibendum vel turpis a, volutpat hendrerit neque. Morbi gravida pretium condimentum. Nulla ullamcorper sem neque, nec blandit diam ornare at. Praesent ante sem, lobortis in velit ac, elementum tempor eros. Maecenas gravida lorem et pharetra dictum. In sagittis, neque quis scelerisque eleifend, risus elit eleifend ante, in blandit risus tortor et felis. Suspendisse eleifend vitae quam sed porttitor.',
         },

         advantages: [
            {
               step: 0,
               items: [
                  {
                     _id: Math.random(),
                     media: '/storage/audio/4_6003558462338894856.m4a',
                     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lacinia condimentum pellentesque. Duis nisl quam, bibendum vel turpis a, volutpat hendrerit neque. Morbi gravida pretium condimentum. Nulla ullamcorper sem neque, nec blandit diam ornare at. Praesent ante sem, lobortis in velit ac, elementum tempor eros. Maecenas gravida lorem et pharetra dictum. In sagittis, neque quis scelerisque eleifend, risus elit eleifend ante, in blandit risus tortor et felis. Suspendisse eleifend vitae quam sed porttitor.',
                  },
                  {
                     _id: Math.random(),
                     media: '/storage/audio/4_6003558462338894856.m4a',
                     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lacinia condimentum pellentesque. Duis nisl quam, bibendum vel turpis a, volutpat hendrerit neque. Morbi gravida pretium condimentum. Nulla ullamcorper sem neque, nec blandit diam ornare at. Praesent ante sem, lobortis in velit ac, elementum tempor eros. Maecenas gravida lorem et pharetra dictum. In sagittis, neque quis scelerisque eleifend, risus elit eleifend ante, in blandit risus tortor et felis. Suspendisse eleifend vitae quam sed porttitor.',
                  },
               ],
            },
            {
               step: 1,
               items: [
                  {
                     _id: Math.random(),
                     media: '/storage/audio/4_6003558462338894856.m4a',
                     text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis',
                  },
                  {
                     _id: Math.random(),
                     media: '/storage/audio/4_6003558462338894856.m4a',
                     text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis',
                  },
               ],
            },
            {
               step: 2,
               items: [
                  {
                     _id: Math.random(),
                     media: '/storage/audio/4_6003558462338894856.m4a',
                     text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis',
                  },
                  {
                     _id: Math.random(),
                     media: '/storage/audio/4_6003558462338894856.m4a',
                     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lacinia condimentum pellentesque. Duis nisl quam, bibendum vel turpis a, volutpat hendrerit neque. Morbi gravida pretium condimentum. Nulla ullamcorper sem neque, nec blandit diam ornare at. Praesent ante sem, lobortis in velit ac, elementum tempor eros. Maecenas gravida lorem et pharetra dictum. In sagittis, neque quis scelerisque eleifend, risus elit eleifend ante, in blandit risus tortor et felis. Suspendisse eleifend vitae quam sed porttitor.',
                  },
               ],
            },
         ],
         doNotDisturb: false,
         isAnimation: false,
         isClick: true,
         lastMoveY: 0,
         firstMoveY: 0,
         doNotMoveSides: false,
         itemsPerSlide: [],
         lengthOfSlides: [],
      };
   },

   computed: {
      /*    currentAdvantage() {
         return this.advantages.filter(advantage => advantage.step === this.currentStep)[0];
      }, */

      isLastStep() {
         return this.currentStep === this.lengthOfSlides.length - 1;
      },

      isBackExist() {
         return this.currentStep > 0;
      },

      profile() {
         return this.$store.getters['profile/getProfile'];
      },

      instructions() {
         return this.$store.getters['instruction/getInstructions'];
      },
   },

   watch: {
      currentStep() {
         /*             const removeAnimClass = () => {
               this.$refs.sliderBox.classList.remove('animShow');
               this.$refs.sliderBox.removeEventListener('animationend', removeAnimClass);
               this.isAnimation = false;
               this.stepToShow = this.currentStep;
               this.$refs.sliderBox.style.transform = `translateX(-${this.currentStep * 105}%)`;
            }; */

         this.stepToShow = this.currentStep;
         this.$refs.sliderBox.style.transform = `translateX(-${this.currentStep * 105}%)`;
         /* this.isAnimation = true; */
         /*             this.$refs.sliderBox.classList.add('animShow');
            this.$refs.sliderBox.addEventListener('animationend', removeAnimClass); */
      },
   },

   mounted() {
      this.findSlideParams();
   },

   methods: {
      doNotDisturbToggle(data) {
         this.doNotDisturb = data;
         this.$store.commit(`profile/${mutations.UPDATE_PROFILE}`, { DisturbSecond: this.doNotDisturb });
      },

      closeModal() {
         clearInterval(this.interval);
         if (this.profile.DisturbSecond && this.doNotDisturb) {
            this.$store.dispatch('profile/updateProfile');
         }
         this.$router.push({ name: 'app-main-all' });
      },

      btnContinue() {
         //here wiill be logic for saving user data
         if (this.doNotDisturb) localStorage.setItem('doNotDisturbSecond', this.doNotDisturb);
         this.closeModal();
      },

      isCurrentAdvatage(step) {
         return step === this.stepToShow;
      },

      handleSliderBack() {
         if (this.isBackExist) {
            this.currentStep -= 1;
         }
      },

      handleSliderForward() {
         if (!this.isLastStep) {
            this.currentStep += 1;
         }
      },

      switchAdvantage(step) {
         this.currentStep = step;
      },

      touchStart(e) {
         this.firstMove = e.touches[0].clientX;
         this.firstMoveY = e.touches[0].clientY;
      },

      touchMove(e) {
         this.lastMove = e.changedTouches[0].clientX;
         this.lastMoveY = e.changedTouches[0].clientY;
         if (Math.abs(this.firstMoveY - this.lastMoveY) > 10) {
            this.doNotMoveSides = true;
         }
         if (!this.doNotMoveSides) {
            let currentX = +`-${this.currentStep * 105}`;
            let moveRange = Math.ceil(((this.lastMove - this.firstMove) / this.$refs.sliderBox.offsetWidth) * 100);
            if (this.currentStep !== 0) moveRange = currentX + moveRange;
            this.$refs.sliderBox.style.transform = `translateX(${moveRange}%)`;
         }
      },

      touchEnd() {
         this.doNotMoveSides = false;
         if (this.lastMove === 0) return;
         let centerOfElems = this.$refs.sliderBox.offsetWidth / 2;
         const minMoveRange = 120;
         this.isClick = false;
         let rangeItemWasMoved = Math.abs(this.lastMove - this.firstMove);
         if (centerOfElems > this.lastMove && rangeItemWasMoved > minMoveRange) {
            //left swipe
            this.handleSliderForward();
         } else if (this.firstMove < this.lastMove && rangeItemWasMoved > minMoveRange) {
            //right swipe
            this.handleSliderBack();
         }

         if (rangeItemWasMoved < minMoveRange || !this.isBackExist || this.isLastStep) {
            this.$refs.sliderBox.style.transform = `translateX(-${this.currentStep * 105}%)`; //reset translate for slider
         }
         this.stepToShow = this.currentStep;

         this.$nextTick(() => {
            //for handling click insted of swipe
            this.isClick = true;
         });
         this.firstMove = 0;
         this.lastMove = 0;
      },

      findSlideParams() {
         this.itemsPerSlide = Array.from(
            Array(Math.ceil(this.$refs.sliderBox.offsetHeight / this.$refs.heightOfAdvice.offsetHeight)).keys(),
         );

         this.lengthOfSlides = Array.from(
            Array(Math.ceil(this.instructions.length / this.itemsPerSlide.length)).keys(),
         );
      },
   },
};
</script>
<style lang="sass">
.modal
   top: 0!important

.player .play svg
   fill: black
   stroke: black
   stroke-width: 0.1

#dark .player .play svg
   fill: white
   stroke: white
</style>

<style lang="sass" scoped>
@import '../../sass/_variables'
.show-enter-active,
.show-leave-enter
   display: block
   transform: translateX(0)
   transition: all .4s linear
   opacity: 1

.show-enter,
.show-leave-to
   opacity: 0
   display: none
   transform: translateX(100%)

@keyframes sliderAnim
   0%
      opacity: 100
      transform: translateX(0)
   100%
      opacity: 0
      transform: translateX(-50%)

.animShow
   animation: sliderAnim 0.5s

.welcome
   &__box
      height: fit-content
      margin: 0 auto
      max-width: 1300px
      width: 100%
      height: 100%
      overflow-x: hidden

   &__data
      height: 100%

   &__greet
      font-size: 2rem

   &__img
      margin-bottom: 30px

   &__item
      margin-top: 20px

      & label
         font-size: 1.5rem
         margin-bottom: 10px

      & input, & select
         display: block
         width: 100%
         font-size: 1.3rem
         padding: 10px
         border: 1px solid $app-main-color

   &__btn
      background-color: $app-main-color
      color: $font-color-white
      padding: 10px 20px
      border-radius: 20px 20px 20px 20px
      font-size: 1.5rem
      display: block
      transition: all 0.3s
      margin-left: 40px

      &:active
         background-color: darken($app-main-color, 20%)

      &:hover
         background-color: darken($app-main-color, 10%)

   &__actions
      display: flex
      align-items: center
      justify-content: center

#dark .welcom__btn
   color: $app-dark-font-color

@media (max-width: 380px)
   .welcome__btn
      font-size: 1.3rem

.advantages
   text-align: center
   font-size: 1.3rem
   position: relative
   height: calc( 100% - 100px)
   padding-top: 30px

   &__title
      font-size: 1.4rem
      font-weight: 500
      margin-bottom: 20px

   &__box
      overflow-y: auto
      position: absolute
      top: 0
      height: 100%
      left: 0
      width: 100%
      display: grid
      place-items: center
      padding: 0 39px

   &__text
      padding: 0 20px
      width: 100%
      border: 1px solid #f1f1f1
      padding: 15px 30px
      box-shadow: 0 0 1px #f1f1f1
      text-align: left
      transition: all 0.3s

.slider__box
   position: relative
   height: 100%
   transition: all 0.5s
   transform: translateX(0)

#dark .advantages__title, #dark .advantages__text
   color: $app-dark-font-color

#sepia .advantages__text
   border: 1px solid $app-sepia-border

.slider__btn
   position: absolute
   width: 30px
   height: 30px
   top: 50%
   z-index: 2999
   background-color: rgba($app-main-color, 50%)
   transition: all 0.3s
   border-radius: 50%
   transform: translateX(10%) translateY(-50%)
   display: inline-flex
   justify-content: center
   align-items: center

   &:not(&:disabled):hover
      background-color: rgba($app-main-color, 90%)

   &:disabled
      cursor: not-allowed

   &.left
      left: 0

   &.right
      right: 0
      transform: rotate(180deg) translateX(10%) translateY(50%)

   & svg
      width: 15px
      height: 15px

.slider__item
   padding: 0 20px

#dark .slider__btn svg
   fill: $app-dark-font-color

.controls
   display: flex
   justify-content: center
   align-items: center

   & .dot
      width: 6px
      height: 6px
      margin: 0 5px
      background-color: rgba($app-main-color, 50%)
      transition: all 0.3s

      &.active
         background-color: $app-main-color

.hide
   position: absolute
   opacity: 0
   transform: translateY(-100000px)
   max-width: 1300px

.player
   margin-left: auto
   padding: 0 50px
</style>