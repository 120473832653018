<template>
   <div class="login-selector">
      <button :class="selection ? ['login-selector__btn', 'active'] : 'login-selector__btn'" @click="updateSelection">
         Email
      </button>
      <button :class="!selection ? ['login-selector__btn', 'active'] : 'login-selector__btn'" @click="updateSelection">
         Phone
      </button>
   </div>
</template>

<script>
export default {
   props: {
      selection: {
         type: Boolean,
         required: true,
      },
   },

   methods: {
      updateSelection() {
         this.$emit('update:selection', !this.selection);
      },
   },
};
</script>

<style lang="sass" scoped>
.login-selector
   display: flex
   align-items: center

   & >:first-child
      margin-right: 20px

   &__btn
      cursor: pointer
      border: 1px solid #ccc
      padding: 10px
      border-radius: 5px
      transition: all 0.2s

      &.active
         background: #ccc
</style>