<template>
   <Modal v-model="isOpen">
      <div class="modal__content__box">
         <div class="modal__content">
            <div class="img__modal"><img src="../assets/img/girlModal.svg" alt="" /></div>
            <div class="item__modal">
               <Topografi size="bigger" weigth="weigth">{{ $t('ImprtovePrompt.title') }}</Topografi>
            </div>
            <div class="paragraf__modal">
               <Topografi
                  >We want to share tips on useful sleep habits that will allow you to improve your quality of sleep and
                  well-being</Topografi
               >
            </div>
            <div class="button_check__modal">
               <SWButton variant="primary" size="big">Check it out</SWButton>
            </div>
            <div class="button_skip__modal">
               <SWButton variant="secondary" size="big" @click="onSkip">Skip</SWButton>
            </div>
            <div class="checkbox__modal">
               <SWCheckbox></SWCheckbox>
            </div>
         </div>
      </div>
   </Modal>
</template>

<script>
import Modal from './UI/Modal.vue';
import Topografi from './UI/Topografi.vue';
import { SWButton, SWCheckbox } from 'sw-ui-kit';
export default {
   components: {
      Modal,
      Topografi,
      SWButton,
      SWCheckbox,
   },
   mounted() {
      if (sessionStorage.getItem('isShowImprovePrompt')) {
         try {
            this.isOpen = JSON.parse(sessionStorage.getItem('isShowImprovePrompt'));
         } catch (e) {
            this.isOpen = true;
         }
      } else {
         this.isOpen = true;
      }
   },
   data() {
      return {
         isOpen: false,
      };
   },
   methods: {
      onSkip() {
         sessionStorage.setItem('isShowImprovePrompt', false);
         this.isOpen = false;
      },
   },
};
</script>

<style scoped>
.modal__content__box {
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.modal__content {
   display: flex;
   flex-direction: column;
   max-width: 337px;
}
.button_check__modal {
   width: 100%;
}
.button_skip__modal {
   width: 100%;
}
.img__modal {
   margin-bottom: 24px;
}
.item__modal {
   margin-bottom: 32px;
}
.paragraf__modal {
   margin-bottom: 32px;
}
.button_check__modal {
   margin-bottom: 18px;
}
.button_skip__modal {
   margin-bottom: 40px;
}
.checkbox__modal {
   margin-right: 50%;
}
</style>
