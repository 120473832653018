<template>
   <div class="edit">
      <transition name="fade-list">
         <AdviceEditBtn
            :text="$t('Advice.hideBtn')"
            @click="hideSelected"
            :disabled="editItems.length === 0"
            v-if="!hiddenMode"
         />
      </transition>
      <transition name="fade-list">
         <AdviceEditBtn :text="$t('Advice.hideUnselectedBtn')" @click="hideUnselected" v-if="!hiddenMode" />
      </transition>
      <transition name="fade-list">
         <AdviceEditBtn
            :text="$t('Advice.showHiddenBtn')"
            :disabled="!isHiddenExist"
            @click="showHidden"
            v-if="!hiddenMode"
         />
      </transition>
      <transition name="fade-list">
         <AdviceEditBtn
            :text="$t('Advice.showSelectedBtn')"
            @click="showSelected"
            :disabled="editItems.length === 0"
            v-if="hiddenMode"
         />
      </transition>
      <transition name="fade-list">
         <AdviceEditBtn :text="$t('Advice.showUnselectedBtn')" @click="showUnselected" v-if="hiddenMode" />
      </transition>
      <transition name="fade-list">
         <AdviceEditBtn :text="$t('Advice.showAllBtn')" @click="showAll" v-if="hiddenMode" />
      </transition>
      <transition name="fade-list">
         <AdviceEditBtn :text="!hiddenMode ? $t('Advice.closeEditBtn') : $t('Advice.backBtn')" @click="closeEditMode" />
      </transition>
   </div>
</template>
<script>
import mutations from '../../store/mutation-types';
import AdviceEditBtn from '../AdviceComponents/AdviceEditBtn.vue';
export default {
   components: {
      AdviceEditBtn,
   },
   props: {
      items: {
         type: Array,
         default: () => [],
      },
      hiddenMode: {
         type: Boolean,
         required: true,
      },
      hiddenItems: {
         type: Array,
         default: () => [],
      },
   },
   computed: {
      isEditItemsExist() {
         return false;
      },
      isHiddenExist() {
         return this.hiddenItems.length > 0;
      },
      editItems() {
         return this.$store.getters['advice/getEditItems'];
      },
      unvisible() {
         return this.$store.getters['advice/getUnvisible'];
      },
   },

   mounted() {
      const turnOffEditMode = () => {
         this.$store.commit(`advice/${mutations.UPDATE_EDITE_MODE}`, false);
         window.removeEventListener('popstate', turnOffEditMode);
      };
      window.addEventListener('popstate', turnOffEditMode);
   },
   destroyed() {
      this.$store.commit(`advice/${mutations.UPDATE_EDITE_MODE}`, false);
      this.$store.commit(`advice/${mutations.SET_HIDDEN_MODE}`, false);
   },
   methods: {
      hideSelected() {
         this.updateCategoriesData([...this.unvisible, ...this.editItems], true);
      },
      hideUnselected() {
         let unselectedId = this.items.map(category => {
            if (!this.editItems.includes(category._id)) return category._id;
            else if (!this.editItems) return category._id;
         });
         this.updateCategoriesData([...unselectedId, ...this.unvisible], false);
      },
      showHidden() {
         this.$emit('clearListOfEditItems');
         this.$store.commit(`advice/${mutations.SET_HIDDEN_MODE}`, true);
      },
      showSelected() {
         let newUnvisible = [...this.unvisible].filter(id => !this.editItems.includes(id));
         this.updateCategoriesData(newUnvisible, true);
      },
      showUnselected() {
         let idToRemove = [];
         if (this.editItems.length === 0) {
            idToRemove = this.hiddenItems.map(({ _id }) => _id);
         } else {
            idToRemove = this.hiddenItems.filter(({ _id }) => !this.editItems.includes(_id)).map(({ _id }) => _id);
         }
         let newUnvisible = [...this.unvisible].filter(id => !idToRemove.includes(id));
         this.updateCategoriesData(newUnvisible, false);
      },
      showAll() {
         let idToRemove = this.hiddenItems.map(({ _id }) => _id);
         let newUnvisible = [...this.unvisible].filter(id => !idToRemove.includes(id));
         this.updateCategoriesData(newUnvisible, true);
      },

      closeEditMode() {
         if (this.hiddenMode) this.$store.commit(`advice/${mutations.SET_HIDDEN_MODE}`, false);
         else {
            this.$store.commit(`advice/${mutations.UPDATE_EDITE_MODE}`, false);
            this.$store.commit(`advice/${mutations.SET_IS_CONFIRM_ADVICES}`, true);
         }
      },
      updateCategoriesData(unvisible, isClearEditItems = false) {
         this.$store.dispatch('advice/updateUnvisible', unvisible);
         if (isClearEditItems) this.$emit('clearListOfEditItems');
      },
   },
};
</script>
<style lang="sass" scoped>
@import '../../sass/_variables'
.fade-list-enter-active, .fade-list-leave-active
   transition: opacity .5s
.fade-list-enter, .fade-list-leave-to
   opacity: 0
.edit
   display: flex
   justify-content: space-between
   flex-basis: 33.3333%
   width: 100%
   position: fixed
   bottom: 0
   left: 0
   background-color: #fff
   border-top: 1px solid #f1f1f1
#dark .edit, #sepia .edit
   box-shadow: none
#sepia .edit
   border-color: $app-sepia-border
</style>