<template>
   <button class="btn" @click="toggleEditMode">
      <img src="../../assets/img/advices/more.svg" alt="more btn" />
   </button>
</template>

<script>
import mutations from '../../store/mutation-types';
export default {
   computed: {
      editMode() {
         return this.$store.getters['advice/getEditMode'];
      },
   },

   methods: {
      toggleEditMode() {
         this.$store.commit(`advice/${mutations.UPDATE_EDITE_MODE}`, !this.editMode);
         if (this.editMode) {
            this.$store.commit(`advice/${mutations.SAVE_UNVISIBLE_TO_TEMP}`);
         } else {
            this.$store.commit(`advice/${mutations.SET_IS_CONFIRM_ADVICES}`, true);
         }
      },
   },
};
</script>

<style lang="sass" scoped>
.btn
   width: 30px
   height: 100%
   display: block

   & img
      height: inherit
      width: 100%
</style>