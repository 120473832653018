import Vue from 'vue';
import App from './App.vue';
import router from './router/router.js';
import store from './store/store.js';
import http from './plugins/http.js';
import { i18n } from './i18n';
import vuetify from './plugins/vuetify';
import 'sw-ui-kit/dist/styles.css';
import './sass/vuetiftyColor.scss';
import 'roboto-fontface/css/roboto/roboto-fontface.css';

Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.use(i18n);

new Vue({
   router,
   store,
   vuetify,
   i18n,
   render: h => h(App),
}).$mount('#app');
/* 
window.addEventListener('load', async () => {
	if ('serviceWorker' in navigator) {
		try {
			const result = await navigator.serviceWorker.register('/sw.js')
			console.log(result)
		} catch (e) {
			console.log('Service worker register fail')
			console.log(e)
		}
	}
})
 */
