<template>
   <div class="subscription-buy" v-if="subscriptions.length > 0">
      <div :style="border" v-for="subscription in subscriptionsWithoutTrial" :key="subscription.id">
         <ProfileSubsctiptionBuyItem :subscription="subscription" :coupon="addedCoupon" />
      </div>

      <div class="subscription-buy__item">
         <div class="subscription-buy__info">
            <div class="subscription-buy__parameter">
               <p>
                  {{ $t('Subscription.personalDiscout') }}
               </p>
               <p class="subscription-buy__parameter_value">
                  {{ $t('Subscription.discount', { discount: discount }) }}
               </p>
            </div>
         </div>
      </div>

      <div class="subscription-buy__item">
         <div class="subscription-buy__info">
            <div class="subscription-buy__parameter">
               <p>
                  {{ $t('Subscription.coupon') }}
               </p>
               <div>
                  <p class="subscription-buy__parameter_value">
                     <input
                        :disabled="!!addedCoupon"
                        @focus="clearCouponError"
                        @input="clearCouponError"
                        type="text"
                        v-model="coupon"
                        class="subscription-buy__coupon"
                     />
                  </p>
                  <p class="subscription-buy__error" v-if="couponError">{{ couponError }}</p>
               </div>
            </div>
         </div>
         <div class="subscription-buy__controls">
            <AdviceBtn class="subscription-buy__trial" @click.native="$emit('cancel')">
               {{ $t('Advice.btnCancel') }}
            </AdviceBtn>

            <AdviceBtn class="subscription-buy__trial" @click.native="applyCoupon" :disabled="!!addedCoupon">
               {{ $t('Subscription.applyCoupon') }}
            </AdviceBtn>
         </div>
      </div>
   </div>
</template>

<script>
import mutations from '../../store/mutation-types';
import AdviceBtn from '../AdviceComponents/AdviceBtn.vue';
import ProfileSubsctiptionBuyItem from './ProfileSubsctiptionBuyItem.vue';
export default {
   components: {
      AdviceBtn,
      ProfileSubsctiptionBuyItem,
   },

   data() {
      return {
         coupon: '',
         showError: false,
      };
   },

   computed: {
      discount() {
         return this.$store.getters['profile/getProfile'].discount;
      },

      currentTheme() {
         return this.$store.getters['advice/getCurrentTheme'];
      },

      color() {
         return this.$store.getters['advice/getThemeColor'];
      },

      border() {
         return this.currentTheme && this.currentTheme !== 'white'
            ? { borderBottom: `1px solid ${this.currentTheme.border}`, boxShadow: 'unset' }
            : '';
      },

      subscriptions() {
         return this.$store.getters['profile/getSubscriptions'];
      },

      subscriptionsWithoutTrial() {
         if (!this.subscriptions) return [];
         return this.subscriptions.filter(subscription => subscription.name.toLowerCase() !== 'trial');
      },

      addedCoupon() {
         return this.$store.getters['profile/getCoupon'];
      },

      couponError() {
         return this.$store.getters['profile/getCouponError'];
      },
   },

   mounted() {
      this.$store.dispatch('profile/fetchSubscriptions');
   },

   methods: {
      clearCouponError() {
         if (!this.couponError) return;
         this.$store.commit(`profile/${mutations.UPDATE_ERROR_COUPON}`, '');
      },

      buySubscription(name) {
         this.$store.dispatch('profile/changeSubscription', name);
      },

      applyCoupon() {
         this.$store.dispatch('profile/fetchCoupon', this.coupon);
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.subscription-buy
   max-width: 1500px
   width: 100%
   margin: 0 auto
   font-size: 1.125rem
   padding: 10px 10px
   height: 100vh
   overflow: auto

   &__item
      border-bottom: 1px solid #f1f1f1
      display: grid
      padding: 0 20px
      grid-template-columns: 3fr 1fr
      padding-bottom: 5px
      margin-bottom: 10px

   &__info
      padding-right: 10%

   &__trial
      height: fit-content
      place-self: center
      width: 100%
      font-size: inherit

   &__coupon
      border: 3px solid #e4e4e4
      padding: 5px
      border-radius: 5px
      width: 100%

   &__parameter
      display: grid
      grid-template-columns: 1fr 1fr
      align-items: center
      margin: 10px 0
      &_value
         margin: 0 auto
         text-align: center

   &__price
      display: block

      &.outline
         text-decoration: line-through

   &__title
      font-weight: bold
      text-transform: capitalize

   &__btn
      display: block
      background-color: $app-main-color
      color: white
      max-width: 80px
      width: 100%
      padding: 10px
      border: 1px solid #f1f1f1
      border-radius: 5px 5px 5px 5px

      & > *
         cursor: pointer

   &__error
      margin-top: 4px
      font-size: 13px
      color: red

   &__added
      margin-top: 4px
      font-size: 13px

   &__controls
      display: flex
      align-items: center
      justify-content: end

      & *:first-child
         margin-right: 10px

#sepia .subscription-buy__item
   border-bottom: 1px solid $app-sepia-border

#sepia .subscription-buy__coupon
   border: 1px solid $app-sepia-border

@media (max-width: 756px)
   .subscription-buy
      padding: 0 0 30px 0

      &__item
         grid-template-columns: 1fr

      &__info
         padding-right: 0

      &__parameter
         &_value
            margin: 0
            margin-left: auto
            text-align: center

@media (max-width: 568px)
   .subscription-buy__parameter
      column-gap: 10px
      margin: 5px 0

   .subscription-buy__item
      margin-bottom: 0

   .subscription-buy__trial
      padding: 5px 10px

@media (max-width: 378px)
   .subscription-buy

      &__parameter
         grid-template-columns: 1fr
         &_value
            margin-left: 0
            text-align: left
</style>