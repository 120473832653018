<template>
   <div>
      <div class="subscription" @click="openSubscriptionModal">
         <div class="subscription__icon">
            <svg><use xlink:href="../../assets/img/icons.svg#subscription"></use></svg>
         </div>
         <div class="subscription__box" :style="border">
            <p class="subscription__label"><AdviceText>Subscription</AdviceText></p>
            <p class="subscription__text">
               <AdviceText>
                  {{ profile.subscribtion }}
               </AdviceText>
            </p>
            <div class="subscription__edit"><ProfileEditBtn @edit="openSubscriptionModal" /></div>
         </div>
      </div>

      <transition name="fade">
         <AdviceModal v-if="isModalOpen" :disableCross="true" @closeModal="isModalOpen = false">
            <ProfileSubscriptionBuyModal @cancel="isModalOpen = false" />
         </AdviceModal>
      </transition>
   </div>
</template>

<script>
import ProfileSubscriptionBuyModal from './ProfileSubscriptionBuyModal.vue';
import ProfileEditBtn from './ProfileEditBtn.vue';
import AdviceText from '../AdviceComponents/AdviceText.vue';
import AdviceModal from '../AdviceComponents/AdviceModal.vue';
export default {
   components: {
      ProfileSubscriptionBuyModal,
      ProfileEditBtn,
      AdviceModal,
      AdviceText,
   },

   provide() {
      return {
         closeModal: this.closeModal,
      };
   },

   data() {
      return {
         currentSubscription: 'Trial',
         isModalOpen: false,
      };
   },

   computed: {
      currentTheme() {
         return this.$store.getters['advice/getCurrentTheme'];
      },

      border() {
         return this.currentTheme && this.currentTheme !== 'white'
            ? { borderBottom: `1px solid ${this.currentTheme.border}` }
            : '';
      },

      profile() {
         return this.$store.getters['profile/getProfile'];
      },
   },

   mounted() {
      if (this.$route.params.isOpenSubscription) {
         this.openSubscriptionModal();
      }
   },

   methods: {
      openSubscriptionModal() {
         this.isModalOpen = true;
      },

      closeModal() {
         this.isModalOpen = false;
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.subscription
   display: flex
   align-items: center

   &__box
      width: 100%
      position: relative

      border-bottom: 1px solid #f1f1f1
      padding: 15px 0

   &__edit
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)

   &__label
      font-size: 1.2rem

   &__text
      font-weight: 500
      font-size: 1.2rem
      text-transform: capitalize

   &__icon
      width: 25px
      height: 25px
      margin-right: 20px
      & svg
         width: 100%
         height: 100%
         fill: #76c4ba

#dark .subscription
   color: $app-dark-font-color

#sepia .subscription__box
   border-bottom: 1px solid $app-sepia-border
</style>