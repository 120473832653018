<template>
   <ProfileItem
      :labelText="labelText"
      :labelEditText="labelEditText"
      @edit="setFocus"
      :itemImg="itemImg"
      :variableName="variableName"
      :currentValue="currentValue"
   >
      <template v-slot:default="{ isEditModeEnable, offEditMode }">
         <AdviceText v-if="isEditModeEnable">
            <input
               ref="input"
               :name="inputNames"
               :autocomplete="inputNames"
               @focus="updateProfileItemEditing"
               @blur="offEditMode"
               :placeholder="labelEditText"
               v-model="value"
               type="text"
               class="item__value item__input"
            />
         </AdviceText>
      </template>
   </ProfileItem>
</template>

<script>
import mutations from '../../store/mutation-types';
import AdviceText from '../AdviceComponents/AdviceText.vue';
import ProfileItem from './ProfileItem.vue';

export default {
   components: {
      AdviceText,
      ProfileItem,
   },

   props: {
      labelText: {
         type: String,
         required: true,
      },

      labelEditText: {
         type: String,
         required: true,
      },

      currentValue: {
         type: String,
         default: '',
      },

      itemImg: {
         type: String,
      },

      variableName: {
         type: String,
         required: true,
      },
   },

   data() {
      return {
         value: this.currentValue,
      };
   },

   computed: {
      inputNames() {
         if (this.variableName === 'first_name') {
            return 'firstName';
         } else if (this.variableName === 'last_name') {
            return 'lastName';
         } else {
            return this.variableName;
         }
      },
   },

   watch: {
      value() {
         if (this.value.length > 0) {
            this.$store.commit(`profile/${mutations.UPDATE_PROFILE}`, { [this.variableName]: this.value });
         }
      },
   },

   methods: {
      editClicked() {
         this.$nextTick(() => {
            this.$refs['input'].focus();
         });
      },

      updateProfileItemEditing() {
         this.$store.commit(`profile/${mutations.PROFILE_ITEM_EDITING}`, true);
      },

      setFocus() {
         this.$nextTick(() => {
            this.$refs['input'].focus();
         });
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.item
   display: flex
   align-items: center

   &__icon
      width: 40px

      & img
         width: 20px

   &__box
      width: 97%
      position: relative
      border-bottom: 1px solid #f1f1f1
      padding: 15px 0

   &__value
      font-weight: 500
      text-transform: capitalize

   &__input
      width: 90%
      text-transform: unset

   &__edit-btn
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)

#dark .item
   color: $app-dark-font-color

#sepia .item__box
   border-bottom: 1px solid $app-sepia-border
</style>