<template>
   <ProfileItem
      :labelText="labelText"
      :labelEditText="labelEditText"
      @edit="setFocus"
      :itemImg="itemImg"
      :variableName="variableName"
      :currentValue="formatDate(value)"
   >
      <template v-slot:default="{ isEditModeEnable }">
         <AdviceText v-if="isEditModeEnable">
            <DatePicker
               style="width: 80%"
               ref="input"
               :editable="false"
               :popup-style="popUpStyle"
               v-model="value"
               :placeholder="formatDate(value)"
               format="MM-DD-YYYY"
            />
         </AdviceText>
      </template>
   </ProfileItem>
</template>

<script>
import mutations from '../../store/mutation-types';
import AdviceText from '../AdviceComponents/AdviceText.vue';
import DatePicker from 'vue2-datepicker';
import ProfileItem from './ProfileItem.vue';
import '../../css/WelcomeDatapicker.css';

export default {
   components: {
      AdviceText,
      ProfileItem,
      DatePicker,
   },

   props: {
      labelText: {
         type: String,
         required: true,
      },

      labelEditText: {
         type: String,
         required: true,
      },

      currentValue: {
         default: 'Enter your data',
      },

      itemImg: {
         type: String,
      },

      variableName: {
         type: String,
         required: true,
      },
   },

   data() {
      return {
         value: this.currentValue,
      };
   },

   computed: {
      popUpStyle() {
         return {
            zIndex: 10000,
            top: 'unset',
            bottom: '10px',
            position: 'fixed',
         };
      },
   },

   watch: {
      value() {
         this.$store.commit(`profile/${mutations.UPDATE_PROFILE}`, {
            [this.variableName]: this.value.toString(),
         });
         this.$store.dispatch(`profile/updateProfile`);
      },
   },

   methods: {
      editClicked() {
         this.$nextTick(() => {
            this.$refs['input'].focus();
         });
      },

      updateProfileItemEditing() {
         this.$store.commit(`profile/${mutations.PROFILE_ITEM_EDITING}`, true);
      },

      setFocus() {
         this.$nextTick(() => {
            this.$refs['input'].focus();
         });
      },

      formatDate(date) {
         if (!date) return;
         let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

         if (month.length < 2) month = '0' + month;
         if (day.length < 2) day = '0' + day;

         return [month, day, year].join('-');
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.item
   display: flex
   align-items: center

   &__icon
      width: 40px

      & img
         width: 20px

   &__box
      width: 97%
      position: relative
      border-bottom: 1px solid #f1f1f1
      padding: 15px 0

   &__value
      font-weight: 500
      text-transform: capitalize

   &__input
      width: 90%
      text-transform: unset

   &__edit-btn
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)

#dark .item
   color: $app-dark-font-color

#sepia .item__box
   border-bottom: 1px solid $app-sepia-border
</style>