<template>
   <div class="max-width">
      <div class="categories">
         <div class="category-list" ref="categoryList">
            <transition-group name="fade-list" v-if="!isHiddenMode">
               <AdviceCategory
                  v-for="category in categories"
                  :key="category._id"
                  :item="category"
                  @editItemsUpdate="addEditItem"
                  :isEditModeEnable="editMode"
                  :isSubCategory="false"
                  :advicesFountBySerachLength="isSearch ? getCategoryAdvicesLength(category.code) : 0"
               />
            </transition-group>
            <transition-group name="fade-list" v-else-if="isHiddenMode">
               <AdviceCategory
                  v-for="category in hiddenCategories"
                  :key="category._id"
                  :item="category"
                  @editItemsUpdate="addEditItem"
                  :isEditModeEnable="editMode"
                  :isSubCategory="false"
                  :isHidden="true"
               />
            </transition-group>
            <!--             <transition name="fade-list" v-else>
               <div>Loading</div>
            </transition> -->
         </div>
      </div>
      <div v-if="editMode" ref="editBtns" class="edit">
         <transition name="fade">
            <AdviceCategoriesListEditBtns
               :items="categories"
               :hiddenItems="hiddenCategories"
               @hiddenMode="changeHiddenMode"
               :hiddenMode="isHiddenMode"
               @clearListOfEditItems="listOfEditItems = []"
            />
         </transition>
      </div>
   </div>
</template>

<script>
import AdviceCategory from '../../components/AdviceCategoriesComponents/AdviceCategory.vue';
import AdviceCategoriesListEditBtns from '../../components/AdviceCategoriesComponents/AdviceCategoriesListEditBtns.vue';
import mutations from '../../store/mutation-types';
export default {
   components: {
      AdviceCategory,
      AdviceCategoriesListEditBtns,
   },

   data() {
      return {
         listOfEditItems: [],
      };
   },

   computed: {
      isHiddenMode() {
         return this.$store.getters['advice/getHiddenMode'];
      },

      editMode() {
         return this.$store.getters['advice/getEditMode'];
      },

      categories() {
         return this.$store.getters['advice/getCategories'];
      },

      hiddenCategories() {
         return this.$store.getters['advice/getHiddenCategories'];
      },

      isSubcategoty() {
         return this.$route.name === 'app-main-subcategory';
      },

      isCategoriesLoaded() {
         return this.$store.getters['advice/getIsCategoriesLoaded'];
      },

      isSearch() {
         return this.$store.getters['advice/getIsSearch'];
      },

      categoriesBySearch() {
         return this.$store.getters['advice/getCategoriesFilterSearch'];
      },
   },

   watch: {
      editMode() {
         const heightOfEditBtns = 56;
         this.editMode
            ? (this.$refs.categoryList.style.paddingBottom = heightOfEditBtns + 'px')
            : (this.$refs.categoryList.style.paddingBottom = 0);
      },

      listOfEditItems() {
         this.$store.commit(`advice/${mutations.UPDATE_EDIT_ITEMS}`, this.listOfEditItems);
      },

      isCategoriesLoaded: {
         handler() {
            if (this.isCategoriesLoaded) this.$store.dispatch('advice/updateData');
         },
         immediate: true,
      },
   },

   mounted() {
      this.$nextTick(() => {
         document.querySelector('.scroll').scrollTo(0, 0);
      });
   },

   methods: {
      addEditItem(id) {
         this.listOfEditItems.includes(id)
            ? this.listOfEditItems.splice(this.listOfEditItems.indexOf(id), 1)
            : this.listOfEditItems.push(id);
      },

      changeHiddenMode(data) {
         this.isHiddenMode = data;
      },

      getCategoryAdvicesLength(code) {
         return this.categoriesBySearch.filter(advice => advice.parent === code).length;
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.fade-list-enter-active, .fade-list-leave-active
   transition: opacity .5s

.fade-list-enter, .fade-list-leave-to
   opacity: 0

.categories
   position: relative
   width: 100%
   padding: 0 10px
   max-width: 1500px
   margin: 0 auto

   &__buy-btn
      text-align: center

.category-list
   padding-bottom: 20px

.edit
   display: flex
   justify-content: space-between
   flex-basis: 33.3333%
   width: 100%
   position: fixed
   bottom: 0
   left: 0
   background-color: #fff
   height: 54px

#dark .edit
   background-color: $app-dark-background

#sepia .edit
   background-color: $app-sepia-background

.max-width
   margin: 0 auto
   max-width: 1330px
   width: 100%
</style>