<template>
   <button @click="$emit('logout')" type="button">
      <svg><use xlink:href="../../assets/img/icons.svg#logout"></use></svg>
   </button>
</template>

<script>
export default {
   computed: {
      fontSize() {
         return Number(this.$store.getters['advice/getCurrentFontSize']);
      },
   },
};
</script>

<style lang="sass" scoped>
button
   width: 30px
   height: 30px
   transform: rotate(180deg)
   cursor: pointer
   display: block

   & svg
      width: inherit
      height: inherit
      fill: #fff

/** @media( max-width:450px )
 button
 width: 21px
 height: 21px **/
</style>