<template>
   <div class="add-screen">
      <div class="add-screen__header">
         <h1>Installing on {{ ios ? 'iOS' : 'Android' }}</h1>
         <button @click="$emit('close')" class="add-screen__cross">
            <svg><use xlink:href="../../assets/img/icons.svg#cross-icon"></use></svg>
         </button>
      </div>
      <div class="add-screen__container">
         <p class="add-screen__text" v-if="!ios">To install the app from Firefox on Android, tap the ellipsis icon:</p>
         <p class="add-screen__text" v-else>To install the app from Safari on iOS, tap the share button:</p>
         <div class="add-screen__img">
            <img src="../../assets/img/advices/firefox-1.png" alt="First instruction slide" v-if="!ios" />
            <img src="../../assets/img/advices/ios-1.png" alt="First instruction slide" v-else />
         </div>
         <p class="add-screen__text">Then swipe up to find and tap {{ ios ? '"Add to Home Screen"' : '"Install"' }}:</p>
         <div class="add-screen__img">
            <img src="../../assets/img/advices/firefox-2.png" alt="Second instruction slide" v-if="!ios" />
            <img src="../../assets/img/advices/ios-2.png" alt="First instruction slide" v-else />
         </div>
      </div>
      <div class="add-screen__btn-box">
         <button @click="$emit('close')">Close</button>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      ios: {
         type: Boolean,
         default: false,
      },
   },
};
</script>

<style lang="sass" scoped>
.add-screen
   position: fixed
   z-index: 100000
   height: 100%
   width: 100%
   top: 0
   left: 0
   background-color: #fff
   overflow-y: auto

   &__header
      border-bottom: 1px solid #BCBCBC
      display: flex
      align-items: center
      justify-content: space-between
      padding-right: 19px

   &__cross
      width: 25px
      height: 25px

      & svg
         width: inherit
         height: inherit

   &__header h1, &__btn-box
      padding: 13px 19px 8px 19px
      font-family: Roboto
      font-style: normal
      font-weight: bold
      font-size: 16px
      line-height: 19px

   &__btn-box
      border-top: 1px solid #BCBCBC

      & button
         font-size: inherit
         color: white
         font-family: inherit
         background: #74C350
         border-radius: 7px
         padding: 9px 19px

   &__container
      padding: 15px 19px

      & > *
         margin-bottom: 15px

   &__text
      font-family: Roboto
      font-style: normal
      font-weight: normal
      font-size: 12px
      line-height: 14px
      color: #000000

   &__img img
      width: 100%
</style>