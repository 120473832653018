const store = {
  namespaced: true,

  state: {
    instructions: [
      {
        _id: 0,
        text: "Perhaps some of the practices and ideas were already familiar to you, some will turn out to be new, ssunusual and even paradoxical.",
        media: "/storage/audio/4_6003558462338894853.mp3",
      },
      {
        _id: 1,
        text: "It is better to start any changes or good habits in the morning, not in the evening.",
        media: "/storage/audio/4_6003558462338894860.m4a",
      },
      {
        _id: 2,
        text: "Define a clear time for the implementation.",
        media: "/storage/audio/4_6003558462338894854.m4a",
      },
      {
        _id: 3,
        text: "If you decide to do something, but do not start within 72 hours, in 85% of cases you will never do it.",
        media: "/storage/audio/4_6003558462338894855.m4a",
      },
      {
        _id: 4,
        text: `You won't change a habit until you find a replacement.`,
        media: "/storage/audio/4_6003558462338894856.m4a",
      },
      {
        _id: 5,
        text: 'Avoid inhibitions on yourself. Motivation cannot be with the word “no”.  A positive goal with "not to do" doesn’t work, the attitude "to do something else" does.',
        media: "/storage/audio/4_6003558462338894857.m4a",
      },
      {
        _id: 6,
        text: "A habit under pressure or controlled by willpower will not last long.",
        media: "/storage/audio/4_6003558462338894858.m4a",
      },
      {
        _id: 7,
        text: "Do not take a lot of advice at once and do not implement all the advice at once, otherwise you will lose the desire.",
        media: "/storage/audio/4_6003558462338894861.m4a",
      },
      {
        _id: 8,
        text: "Take tips that inspire you and cheer you up.",
        media: "/storage/audio/4_6003558462338894863.m4a",
      },
      {
        _id: 9,
        text: `Don't take tips that you already do - be sure to add new ones.`,
        media: "/storage/audio/4_6003558462338894864.m4a",
      },
      {
        _id: 10,
        text: "Perhaps some of the practices and ideas were already familiar to you, some will turn out to be new, ssunusual and even paradoxical.",
        media: "/storage/audio/4_6003558462338894865.m4a",
      },
      {
        _id: 11,
        text: `Needless to say, tomorrow you will start following all our advice. Just read them as much as you want and take only the ones you like. You can determine for yourself which method is most suitable for your personally. Limit yourself to real and actionable advice today. Don't try to do as many of them as possible. Do what can be implemented right now.`,
        media: "/storage/audio/4_6003558462338894866.m4a",
      },
      {
        _id: 12,
        text: `Do not make promises to yourself with words “never”, “for the rest of my life” and similar ones. Set short intervals - 15 minutes, 1 hour, today etc. Determine what will be a success.`,
        media: "/storage/audio/4_6003558462338894868.m4a",
      },
      {
        _id: 13,
        text: "Treat advice solely with trust and incorporate it into your daily life. Make it your habit.",
        media: "/storage/audio/4_6003558462338894869.m4a",
      },
      {
        _id: 14,
        text: `Better to do very little than nothing at all. If you can't follow the advice fully - start with a part. Break the action into stages - into simple actions and make them your habits. Decide on which ones are the most important and difficult for you.`,
        media: "/storage/audio/4_6003558462338894870.m4a",
      },
      {
        _id: 15,
        text: `Strive not for the ideal, but for the progress. Even if your progress is minimal, praise yourself. You should have a clearly defined reward that you will receive now from (or after) a specific action, and not in the future.`,
        media: "/storage/audio/4_6003558462338894871.m4a",
      },
      {
        _id: 16,
        text: `Ask who is well done? Answer - I'm well done and tap yourself on the chest.`,
        media: "/storage/audio/4_6003558462338894872.m4a",
      },
      {
        _id: 17,
        text: `Track your progress.`,
        media: "/storage/audio/4_6003558462338894873.m4a",
      },
      {
        _id: 18,
        text: `Consider the positive consequences of a good habit and ignore the negative.`,
        media: "/storage/audio/4_6003558462338894874.m4a",
      },
      {
        _id: 19,
        text: `Do not worry if you did not manage to implement everything the first time, if you missed a day or something went wrong - forgive yourself and do not give up - consider this as a rear weakness.`,
        media: "/storage/audio/4_6003558462338894875.m4a",
      },
      {
        _id: 20,
        text: `In order to avoid returning to a bad habit - tell yourself “ok, but after 10 minutes” and get distracted by something else.`,
        media: "/storage/audio/4_6003558462338894853.m4a",
      },
      {
        _id: 21,
        text: `Try to keep your feelings of hunger, anger, loneliness or tiredness as minimal as possible during the day.`,
        media: "/storage/audio/4_6003558462338894854.m4a",
      },
      {
        _id: 22,
        text: `Remove emotional and physical barriers to following advice as much as possible. Imagine what you will do if it fails.`,
        media: "/storage/audio/4_6003558462338894855.m4a",
      },
      {
        _id: 24,
        text: `Make bad habits difficult, uncomfortable, and punishing.`,
        media: "/storage/audio/4_6003558462338894860.m4a",
      },
      {
        _id: 25,
        text: `You should not change the regime too abruptly and try to fall asleep at 23:00, if you usually go to bed at 02:00.`,
        media: "/storage/audio/4_6003558462338894861.m4a",
      },
      {
        _id: 26,
        text: `Better to go to bed 15 minutes earlier today, tomorrow - another 15 earlier and so on, until you reach the desired schedule.`,
        media: "/storage/audio/4_6003558462338894863.m4a",
      },
      {
        _id: 27,
        text: `It's completely normal for sleep to get a little bit worse at first, just as it's completely normal for you to make progress and then experience a few setbacks along the way. Progress is rarely linear - there will be ups and downs. However, if you stay committed and consistent, your sleep will improve.`,
        media: "/storage/audio/4_6003558462338894864.m4a",
      },
      {
        _id: 28,
        text: `Sleep-well.coach does not provide specific medical advice, but rather provides users with information and education to better understand ways for sleep improvements. It is not medical advice and is not intended to diagnose, treat, cure, or prevent any disease, disorder, or medical condition. It should never replace any advice given to you by your physician or any other licensed healthcare provider. Sleep-well.coach does not provide therapy, counselling, medical advice, or medical treatment.`,
        media: "/storage/audio/4_6003558462338894856.m4a",
      },
      {
        _id: 29,
        text: `Do not approach the problem with confidence to solve it, it is better to change your approach to the situation to the one that you will try to solve it.`,
        media: "/storage/audio/4_6003558462338894873.m4a",
      },
      {
        _id: 30,
        text: `Short time pain for long game.`,
        media: "/storage/audio/4_6003558462338894872.m4a",
      },
      {
        _id: 31,
        text: `If a habit helps with stress or anxiety, it will be difficult one to deal with. You need a working alternative.`,
        media: "/storage/audio/4_6003558462338894874.m4a",
      },
      {
        _id: 32,
        text: `Good luck and good sleep`,
        media: "/storage/audio/4_6003558462338894875.m4a",
      },
    ],
  },

  getters: {
    getInstructions: (state) => state.instructions,
  },

  mutations: {},

  actions: {},
};

export default store;
