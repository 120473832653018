<template>
   <div class="subscription-buy__item">
      <div class="subscription-buy__info">
         <div class="subscription-buy__parameter">
            <p class="subscription-buy__title">
               {{ $t('Subscription.prepay', { period: subscription.name }) }}
            </p>

            <p class="subscription-buy__parameter_value centered">
               <span class="subscription-buy__price" :class="{ outline: youSave > 0 }">${{ subscription.price }}</span>
               {{ $t('Subscription.perWeek', { period: getPeriodOfDiscont(subscription.time) }) }}
            </p>
         </div>
         <div class="subscription-buy__parameter">
            <div class="subscription-buy__per-day">
               <p>
                  Only
                  <span
                     :class="{
                        outline: (pricePerDayWidthDiscount || pricePerDayWithCoupon) && youSave > 0,
                     }"
                     >${{ pricePerDay }}</span
                  >

                  <span
                     v-if="pricePerDayWidthDiscount && pricePerDayWidthDiscount > 0 && !pricePerDayWithCoupon"
                     :class="{
                        outline: pricePerDayWithCoupon && pricePerDayWidthDiscount > 0,
                     }"
                     >${{ pricePerDayWidthDiscount }}</span
                  >

                  <transition name="fade">
                     <span v-if="pricePerDayWithCoupon && pricePerDayWithCoupon > 0">${{ pricePerDayWithCoupon }}</span>
                  </transition>

                  per day
               </p>
            </div>
            <div class="subscription-buy__parameter_value centered">
               <div class="subscription-buy__per-day">
                  <p v-if="priceWithDiscount && (!priceWithCoupon || priceWithCoupon === priceWithDiscount)">
                     <span>${{ priceWithDiscount }}</span>
                  </p>

                  <transition name="fade">
                     <p v-if="priceWithCoupon && priceWithCoupon != priceWithDiscount">
                        <span>${{ priceWithCoupon }}</span>
                     </p>
                  </transition>
               </div>
            </div>
         </div>
         <div class="subscription-buy__parameter" v-if="youSave">
            <p class="subscription-buy__save">
               {{ $t('Subscription.youSave') }} <span> ${{ youSave }}</span>
            </p>

            <div class="subscription-buy__parameter_value transparent">.</div>
         </div>
      </div>

      <AdviceBtn class="subscription-buy__trial" @click.native="toggleShowPayment">
         {{ $t('Subscription.trialDays', { days: subscription.free_trial_days }) }}
      </AdviceBtn>

      <transition name="fade">
         <AdviceModal v-if="showPayment" @closeModal="toggleShowPayment">
            <ProfileSubscriptionBuyPayment :subscription="subscription" :youSave="+youSave" />
         </AdviceModal>
      </transition>
   </div>
</template>

<script>
import AdviceBtn from '../AdviceComponents/AdviceBtn.vue';
import AdviceModal from '../AdviceComponents/AdviceModal.vue';
import ProfileSubscriptionBuyPayment from './ProfileSubscriptionBuyPayment.vue';
export default {
   components: {
      AdviceBtn,
      AdviceModal,
      ProfileSubscriptionBuyPayment,
   },

   inject: ['closeModal'],

   props: {
      subscription: {
         type: Object,
         required: true,
      },

      coupon: {
         type: Object,
      },
   },

   data() {
      return {
         showPayment: false,
      };
   },

   computed: {
      discount() {
         return this.$store.getters['profile/getProfile'].discount;
      },

      pricePerDay() {
         return this.coutnPricePerDay(this.subscription.price, this.subscription.time);
      },

      pricePerDayWidthDiscount() {
         return this.coutnPricePerDay(this.priceWithDiscount, this.subscription.time);
      },

      youSave() {
         if (this.priceWithCoupon) {
            return (this.subscription.price - this.priceWithCoupon).toFixed(2);
         } else if (this.priceWithDiscount) {
            return (this.subscription.price - this.priceWithDiscount).toFixed(2);
         } else {
            return 0;
         }
      },

      priceWithDiscount() {
         if (!this.discount) return false;
         return this.subscription.price - this.getPercentOfNumber(+this.subscription.price, +this.discount);
      },

      priceWithCoupon() {
         if (!this.coupon) return false;
         const price = this.priceWithDiscount || this.subscription.price;
         const result =
            this.coupon.type_of_discount === 'number'
               ? price - this.couponDiscount
               : price - this.getPercentOfNumber(this.couponDiscount, price);
         return result;
      },

      pricePerDayWithCoupon() {
         if (!this.couponDiscount) return false;
         const currentPricePerDay = this.pricePerDayWidthDiscount || this.pricePerDay;
         return currentPricePerDay - this.coutnPricePerDay(currentPricePerDay, this.subscription.time);
      },

      couponDiscount() {
         if (!this.coupon) return false;
         const name = this.subscription.name === 'Half-year' ? 'half_yearly' : this.subscription.name.toLowerCase();
         return this.coupon[name];
      },
   },

   methods: {
      getPeriodOfDiscont(time) {
         const period = time.match(/[a-zA-Z]/)[0];

         return period == 'd'
            ? 'day'
            : period === 'w'
            ? 'week'
            : time === '6M'
            ? 'half-year'
            : period === 'M'
            ? 'month'
            : period === 'm'
            ? 'minutes'
            : 'year';
      },

      coutnPricePerDay(price, time) {
         const periodNumber = +time.match(/\d/)[0];
         const period = time.match(/[a-zA-Z]/)[0];
         let days;
         let pricePerDay;
         switch (period) {
            case 'm':
               days = periodNumber;
               pricePerDay = +price / days;
               break;

            case 'd':
               days = periodNumber;
               pricePerDay = +price / days;
               break;

            case 'w':
               days = periodNumber * 7;
               pricePerDay = +price / days;
               break;

            case 'M':
               days = periodNumber * 30;
               pricePerDay = +price / days;
               break;
         }
         return pricePerDay.toFixed(2);
      },

      getPercentOfNumber(percent, number) {
         return (number * percent) / 100;
      },

      toggleShowPayment() {
         this.showPayment = !this.showPayment;
         /*  try {
            const data = { name: this.subscription.name };
            if (this.coupon) {
               data.coupon_id = this.coupon._id;
            }
            await this.$http.put('resources/profile/subscribtion', data);
            this.closeModal();
            this.$store.dispatch(`profile/${actions.FETCH}`);
            alert('Subscription was updated!');
         } catch (e) {
            //TODO: handle error
            console.log(e);
         } */
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.subscription-buy
   &__item
      border-bottom: 1px solid #f1f1f1
      display: grid
      grid-template-columns: 3fr 1fr
      padding: 0 20px
      margin-bottom: 10px

   &__info
      padding-right: 10%

   &__trial
      height: fit-content
      place-self: center
      width: 100%

   &__coupon
      border: 3px solid #e4e4e4
      padding: 5px
      border-radius: 5px
      width: 100%

   &__parameter
      display: grid
      grid-template-columns: 1fr 1fr
      align-items: center
      margin: 10px 0
      &_value
         margin: 0 auto
         text-align: center

         &.centered
            width: 100%
            justify-self: center

   &__trial
      font-size: inherit

   &__price
      display: block

   &__title
      font-weight: bold
      text-transform: capitalize

   &__save span
      font-weight: 600

   &__btn
      display: block
      background-color: $app-main-color
      color: white
      max-width: 80px
      width: 100%
      padding: 10px
      border: 1px solid #f1f1f1
      border-radius: 5px 5px 5px 5px

      & > *
         cursor: pointer
   &__per-day
      display: grid
      row-gap: 10px

      & span
         font-weight: 600
         margin: 0 5px

   &__error
      margin-top: 4px
      font-size: 13px
      color: red

   &  .outline
      text-decoration: line-through
      color: #FF0200

   &  .transparent
      color: transparent

   & .smaller
      font-size: 0.9rem

#sepia .subscription-buy__item
   border-bottom: 1px solid $app-sepia-border

#sepia .subscription-buy__coupon
   border: 1px solid $app-sepia-border

@media (max-width: 756px)
   .subscription-buy
      margin-top: 95px
      padding: 0 0 30px 0

      &__item
         grid-template-columns: 1fr

      &__info
         padding-right: 0

      &__parameter
         &_value
            margin: 0
            margin-left: auto
            text-align: center

@media (max-width: 568px)
   .subscription-buy__parameter
      column-gap: 10px
      grid-template-columns: 3fr 1fr
      margin: 5px 0

   .subscription-buy__item
      margin-bottom: 0

   .subscription-buy__trial
      padding: 5px 10px

@media (max-width: 378px)
   .subscription-buy

      &__parameter
         grid-template-columns: 1fr
         &_value
            margin-left: 0
            text-align: left
</style>