<template>
   <div>
      <button @click="isOpen = true" class="password-change">
         <AdviceText>
            {{ text }}
         </AdviceText>
      </button>
      <AdviceModal v-if="isOpen" @closeModal="isOpen = false">
         <slot :close="closeModal"> </slot>
      </AdviceModal>
   </div>
</template>

<script>
import AdviceText from '../AdviceComponents/AdviceText.vue';
import AdviceModal from '../AdviceComponents/AdviceModal.vue';
export default {
   components: {
      AdviceModal,
      AdviceText,
   },

   props: {
      text: {
         type: String,
         required: true,
      },
   },

   data() {
      return {
         isOpen: false,
      };
   },

   methods: {
      closeModal() {
         this.isOpen = false;
      },
   },
};
</script>

<style lang="sass" scoped>
.password-change
   & > *
      cursor: pointer
</style>