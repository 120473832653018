<template>
   <div class="container_layout">
      <div class="layout_logo">
         <div class="box_logo">
            <img src="../../assets/img/layoutLogo.svg" alt="" />
         </div>
         <div class="title_logo">
            <p>Sleep Well Coach</p>
         </div>
      </div>
      <div class="header_layout">
         <div class="link__layout">
            <div class="mobal__img">
               <button class="mobal__img_box">
                  <img src="../../assets/img/text.svg" alt="" />
               </button>
            </div>
            <div class="box__link">
               <div class="advice__box">
                  <div class="advice__img">
                     <img src="../../assets/img/bulb.png" alt="" />
                  </div>
                  <div class="advices__link">
                     <router-link :to="{ name: 'advice' }">Advices</router-link>
                  </div>
               </div>
               <div class="habits__box active">
                  <div class="habits__img">
                     <img src="../../assets/img/ligthning.png" alt="" />
                  </div>
                  <div class="habits__link">
                     <router-link :to="{ name: 'habits' }">Habits</router-link>
                  </div>
               </div>
               <div class="settings__box">
                  <div class="settings__img">
                     <img src="../../assets/img/nut.png" alt="" />
                  </div>
                  <div class="setting__link">
                     <router-link :to="{ name: 'setting' }">Settings</router-link>
                  </div>
               </div>
            </div>
            <div class="box__search">
               <div class="search__layout">
                  <SWSearchinput placeholder="Search for advice, habits" @input.native="handleSearch" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { SWSearchinput } from 'sw-ui-kit';
export default {
   components: {
      SWSearchinput,
   },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;800&display=swap');
.container_layout {
   max-width: 1360px;
   width: 100%;
   padding: 10px 20px;
   margin: 0 auto;
   margin-bottom: 20px;
}
.layout_logo {
   display: flex;
   padding-top: 20px;
   justify-content: start;
   align-items: center;
}
.box_logo {
   width: 40px;
   height: 40px;
   margin-right: 16px;
}
.title_logo {
   font-size: 24px;
   line-height: 32px;
   font-weight: 700;
   font-family: 'Baloo 2';
   color: #1073eb;
}
.header_layout {
   max-width: 876px;
   width: 100%;
   margin: 0 auto;
}
.advice__img,
.habits__img,
.settings__img {
   margin-right: 13px;
   width: 28px;
}
.advice__box,
.habits__box,
.settings__box {
   display: flex;
   font-size: 16px;
   font-weight: 400;
   align-items: center;
   margin-right: 60px;
}
.active {
   color: #1073eb;
}

.link__layout {
   display: flex;
   margin-top: 51px;
   flex-wrap: wrap;
   justify-content: space-between;
}
.mobal__img {
   display: none;
}
.box__link {
   display: flex;
}
@media screen and (max-width: 804px) {
   .box__link {
      display: none;
   }
}
@media screen and (max-width: 804px) {
   .mobal__img {
      display: block;
   }
}
</style>
