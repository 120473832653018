<template>
   <div>
      <transition name="fade-op" mode="out-in">
         <div class="messangers">
            <p class="messangers__title">{{ $t('Login.useMessangers') }}</p>
            <div class="messangers__box">
               <ContactsTwitter :imgName="'twitterReg.svg'" :isAuth="auth" />
               <ContactsFacebook :imgName="'facebookReg.svg'" :isAuth="auth" />
               <ContactsGoogle :imgName="'googleReg.svg'" :isAuth="auth" />
            </div>
         </div>
      </transition>
   </div>
</template>

<script>
import ContactsGoogle from './ContactsGoogle.vue';
import ContactsFacebook from './ContactsFacebook.vue';
import ContactsTwitter from './ContactsTwitter.vue';

export default {
   provide() {
      return {
         authOperation: this.authOperation,
      };
   },

   components: {
      ContactsGoogle,
      ContactsFacebook,
      ContactsTwitter,
   },

   props: {
      auth: {
         type: Boolean,
         default: false,
      },
   },

   methods: {
      async authOperation(postValue) {
         try {
            let data;
            if (!this.auth) {
               data = await this.$http.post('/auth/register', postValue);
            } else {
               const { provider, google_id, facebook_id, twitter_id } = postValue;
               let authData = {
                  email: '',
                  phone: '',
                  password: '',
                  provider: provider,
                  google_id: google_id,
                  facebook_id: facebook_id,
                  twitter_id: twitter_id,
               };

               data = await this.$http.post('/auth/login', authData);
            }
            localStorage.user_token = data.data.token;
         } catch (e) {
            this.error = e;
         }
      },
   },
};
</script>

<style lang="sass" scoped>
.fade-op-enter-active,
.fade-op-leave-active
   transition: all 0.2s
   max-height: 384px

.fade-op-enter,
.fade-op-leave-to
   opacity: 0
   max-height: 0px

.messangers
   margin: 1rem 0

   &__title
      text-align: center
      font-size: 1.2rem
      font-weight: 500
      margin-bottom: 1rem

   &__box
      display: grid
      grid-gap: 1rem
      grid-template-columns: repeat(3, 1fr)

   &__item
      border-radius: 4px
      border: solid 1px #ebebeb
      background-color: #f5f7f9
      display: inline-flex
      align-items: center
      justify-content: center
      padding: 0.5rem 1rem
      cursor: pointer

      &:hover
         box-shadow: -2px 2px 14px 0 #ebedef

      & img
         height: 32px

      & p
         font-size: 1rem

.back-to-contacts
   text-align: center
   font-size: 1rem
   cursor: pointer
   text-decoration: underline
   margin: 1rem auto 0
   display: block
   height: 20px
   width: 20px
</style>