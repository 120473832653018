<template>
   <button class="again-btn" type="button" @click.stop="sendCodeAgain" :disabled="!canBeSendAgain">
      <p class="again-btn__text">Send Code Again <span v-if="!canBeSendAgain">in</span></p>
      <TimerToEnd
         v-if="!canBeSendAgain"
         :timeToEndInMiliseconds="fiveMinInMiliseconds"
         @timeIsEnded="canBeSendAgain = true"
      />
   </button>
</template>

<script>
import TimerToEnd from '../UI/TimerToEnd.vue';
export default {
   components: {
      TimerToEnd,
   },

   props: {
      identifyer: {
         type: String,
         required: true,
      },
   },

   data() {
      return {
         fiveMinInMiliseconds: 60000,
         canBeSendAgain: false,
         timeToSendAgain: 0,
         timeWhenStarted: Date.now(),
         timeToEnd: 0,
      };
   },

   computed: {
      timeToSendInMinutes() {
         let minutes = Math.floor(this.timeToSendAgain / 60000);
         let seconds = ((this.timeToSendAgain % 60000) / 1000).toFixed(0);
         return !this.canBeSendAgain
            ? `in 0${minutes ? minutes : 0}:${seconds.length > 1 ? seconds : '0' + seconds}`
            : '';
      },
   },

   mounted() {
      this.timer();
   },

   methods: {
      async sendCodeAgain() {
         try {
            this.canBeSendAgain = false;
            await this.$http.post(
               'auth/forgot/password/resend/phone',
               {
                  phone: this.identifyer,
               },
               { withCredentials: true },
            );
            this.timer();
         } catch (e) {
            alert('"SMS sending limit per day exceeded');
            sessionStorage.removeItem('codeModal');
            this.$router.push({ name: 'app-login' });
         }
      },

      timer() {
         this.timeToSendAgain = this.fiveMinInMiliseconds;
         this.timeToEnd = this.timeWhenStarted + this.fiveMinInMiliseconds;
         this.interval = setInterval(() => {
            let currentDateNow = Date.now();
            if (currentDateNow > this.timeToEnd) {
               this.timeWhenStarted = this.fiveMinInMiliseconds;
               this.canBeSendAgain = true;
               clearInterval(this.interval);
               return;
            }
            this.timeToSendAgain = this.timeToEnd - currentDateNow;
         }, 1000);
      },
   },

   destroyed() {
      clearInterval(this.interval);
   },
};
</script>

<style lang="sass" scoped>
.again-btn
   display: flex
   font-size: 0.9rem
   cursor: pointer

   & > *
      cursor: pointer

   &:disabled
      color: #ccc
      cursor: not-allowed

   &__text
      margin-right: 5px
</style>