<template>
   <div class="play">
      <button v-if="!isOpen" class="play-svg" @click="play" :disabled="isPlaying">
         <svg><use xlink:href="../../assets/img/icons.svg#play-player"></use></svg>
      </button>
      <div class="play__box" v-if="isOpen">
         <!--          <button @click.stop="decreaseIndex" :disabled="isPrevIndexPossible">
            <svg><use xlink:href="../../assets/img/icons.svg#next"></use></svg>
         </button> -->
         <button @click.stop="stop" class="pause">
            <svg><use xlink:href="../../assets/img/icons.svg#pause-icon"></use></svg>
         </button>
         <!--          <button @click.stop="increaseIndex" :disabled="isNextIndexPossible">
            <svg><use xlink:href="../../assets/img/icons.svg#next"></use></svg>
         </button> -->
      </div>
   </div>
</template>

<script>
import mutations from '../../store/mutation-types';
import remoteOrigin from '../../store/remoteOrigin';
export default {
   props: {
      listToPlay: {
         type: Array,
      },
   },

   data() {
      return {
         isOpen: false,
         audio: '',
         adviceToPlay: [],
         delimeterAudio: new Audio('/audio/delimiter.mp3'),
         isDelimeterPlaying: false,
      };
   },

   computed: {
      advices() {
         return this.$store.getters['advice/getAdvicesToPlay'];
      },

      isPrevIndexPossible() {
         return this.index <= 0;
      },

      isNextIndexPossible() {
         return this.adviceToPlay.length === this.index + 1;
      },

      isMaxIndexReached() {
         return this.adviceToPlay.length === this.index;
      },

      isPlaying() {
         return this.$store.getters['advice/getPlay'];
      },

      fontSize() {
         return Number(this.$store.getters['advice/getCurrentFontSize']);
      },

      index() {
         return this.$store.getters['advice/getPlayerIndex'];
      },

      isPlayDelimeter() {
         return this.$store.getters['advice/getIsPlayDelimeter'];
      },

      isMainPage() {
         return this.$route.name === 'app-main-all';
      },

      isPlayingPlayer() {
         return this.$store.getters['advice/getIsPlayerPlaying'];
      },

      isHiddenMode() {
         return this.$store.getters['advice/getHiddenMode'];
      },
   },

   mounted() {
      this.delimeterAudio.volume = 0.1;
   },

   watch: {
      index() {
         if (this.isOpen) {
            this.playAudio();
         }
      },

      isHiddenMode() {
         this.adviceToPlay = [...this.advices];
         this.offPlayer();
      },

      advices: {
         handler() {
            this.adviceToPlay = this.listToPlay || [...this.advices];
            this.offPlayer();
         },
         immediate: true,
      },

      isPlayDelimeter() {
         if (this.isPlayDelimeter) {
            this.playDelitemer();
         }
      },

      isOpen() {
         if (this.isOpen) {
            this.playAudio();
         } else {
            this.pause();

            if (this.isDelimeterPlaying) {
               this.clearDelimeter();
            }
         }
      },

      isPlay() {
         if (this.isPlay) {
            this.play();
         } else {
            this.pause();
         }
      },
   },

   methods: {
      play() {
         this.isOpen = true;
         this.$store.commit(`advice/${mutations.UPDATE_IS_PLAYER}`, true);
      },

      stop() {
         this.isOpen = false;
         this.$store.commit(`advice/${mutations.UPDATE_IS_PLAYER}`, false);

         if (!this.isMainPage) {
            this.$store.commit(`advice/${mutations.UPDATE_PLAYER_INDEX}`, null);
         }
      },

      pause() {
         if (this.audio) {
            this.audio.pause();
         }
      },

      playAudio() {
         if (!this.index) this.$store.commit(`advice/${mutations.UPDATE_PLAYER_INDEX}`, 0);
         else if (this.index >= this.adviceToPlay.length) {
            this.stop();
            this.$store.commit(`advice/${mutations.UPDATE_PLAYER_INDEX}`, 0);
            return;
         }

         if (!this.audio) {
            if (this.isMainPage) {
               //for playind advice on categories page
               this.audio = new Audio(`${remoteOrigin}${this.adviceToPlay[this.index].media}`);
               this.audio.addEventListener('ended', this.handleIndex);
               this.audio.play();
            } else {
               this.$store.commit(`advice/${mutations.SET_ID_OF_PLAYING_ADVICE}`, this.adviceToPlay[this.index]._id); //for playind advice on advices pages
            }
         } else {
            this.audio.play();
         }
      },

      handleIndex() {
         this.audio.removeEventListener('ended', this.handleIndex);
         this.audio = '';

         this.playDelitemer();
      },

      playDelitemer() {
         this.delimeterAudio.play();
         this.isDelimeterPlaying = true;
         this.delimeterAudio.addEventListener('ended', this.delimeterEnded);
      },

      delimeterEnded() {
         this.$store.commit(`advice/${mutations.PLAY_DELIMETER}`, false);
         this.isDelimeterPlaying = false;
         this.clearDelimeter();

         this.increaseIndex();
      },

      clearDelimeter() {
         this.delimeterAudio.removeEventListener('ended', this.delimeterEnded);
         this.delimeterAudio.pause();
         this.delimeterAudio.currentTime = 0;
      },

      increaseIndex() {
         if (this.adviceToPlay.length >= this.index) {
            this.$store.commit(`advice/${mutations.UPDATE_PLAYER_INDEX}`, this.index + 1);
         } else {
            this.stop();
            this.$store.commit(`advice/${mutations.UPDATE_PLAYER_INDEX}`, 0);
         }
      },

      decreaseIndex() {
         this.$store.commit(`advice/${mutations.UPDATE_PLAYER_INDEX}`, this.index - 1);
      },

      offPlayer() {
         if (this.audio) {
            this.audio.stop();
            this.audio.removeEventListener('ended', this.handleIndex);
         }
         this.stop();
         this.$store.commit(`advice/${mutations.UPDATE_PLAYER_INDEX}`, null);
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.play
   width: 30px
   height: 30px
   &__box
      & button
         display: inline-flex
         &:first-child
            transform: rotate(180deg)
   & svg
      fill: #fff
      width: 30px
      height: 30px

   & .play-svg, .pause
      fill: #fff
      stroke: white
      stroke-width: 1

   & button:disabled svg
      fill: #ccc
      stroke: #ccc
</style>