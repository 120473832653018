<template>
   <div id="" class="theme app scroll">
      <transition name="fade" mode="out-in">
         <router-view></router-view>
      </transition>

      <transition name="fade" mode="out-in">
         <AdviceLogOutModal @closeModal="isLogoutModalOpen = false" v-if="isLogoutModalOpen" />
      </transition>
      <transition name="fade" mode="out-in">
         <AdviceConfirmSaveAdvices v-if="isConfirmAdvices" />
      </transition>
   </div>
</template>

<script>
import AdviceLogOutModal from '../../components/AdviceComponents/AdviceLogOutModal.vue';
import AdviceConfirmSaveAdvices from '../../components/AdviceCategoriesComponents/AdviceConfirmSaveAdvices.vue';
import mutations from '../../store/mutation-types';
export default {
   provide() {
      return {
         setCurrentTheme: this.setCurrentTheme,
         getCurrentFontSize: this.getCurrentFontSize,
         openLogoutModal: this.openLogoutModal,
      };
   },

   components: {
      AdviceLogOutModal,
      AdviceConfirmSaveAdvices,
   },

   data() {
      return {
         fontSize: {
            small: 20,
            medium: 22,
            large: 28,
         },
         isLogoutModalOpen: false,
         prev: 0,
      };
   },

   computed: {
      isConfirmAdvices() {
         return this.$store.getters['advice/getIsConfirmAdvices'];
      },
   },

   created() {
      this.getCurrentFontSize();
   },

   mounted() {
      this.setCurrentTheme();

      const scrollElem = document.querySelector('.scroll');
      scrollElem.addEventListener('scroll', this.scollChecker);
   },

   methods: {
      setCurrentTheme() {
         let theme = localStorage.theme ? localStorage.theme : '';
         document.querySelector('.theme').style.transition = 'all 0.3s';
         document.querySelector('.theme').setAttribute('id', localStorage.theme);
         return theme;
      },

      getCurrentFontSize() {
         if (!localStorage.fontSize) localStorage.fontSize = 'medium';
         let font = this.fontSize[localStorage.fontSize];
         this.$store.commit(`advice/${mutations.UPDATE_FONT_SIZE}`, font);
         return font;
      },

      openLogoutModal() {
         this.isLogoutModalOpen = true;
      },

      scollChecker(e) {
         const pxelsScrollToUpdate = 100;
         if (
            this.prev + pxelsScrollToUpdate < e.target.scrollTop ||
            this.prev - pxelsScrollToUpdate > e.target.scrollTop
         ) {
            this.$store.commit('advice/updateScroll', true);
            this.prev = e.target.scrollTop;
         } else {
            this.$store.commit('advice/updateScroll', false);
         }
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'
.app
   height: 100%
   width: 100%
   overflow-y: auto
   font-family: 'Georgia', sans-serif

#sepia
   background-color: $app-sepia-background

#dark
   background-color: $app-dark-background
</style>