<template>
   <label for="email">
      <span>{{ label }}</span>
      <div class="icon">
         <input
            type="email"
            name="email"
            autocomplete="email"
            id="email"
            placeholder="mail@expample.com"
            ref="inputField"
            @blur="loseFocus"
            v-model="userInput"
         />
      </div>
   </label>
</template>

<script>
export default {
   props: {
      needValidation: {
         type: Boolean,
         default: true,
      },
      label: {
         type: String,
      },
   },

   data() {
      return {
         userInput: '',
      };
   },

   computed: {
      isInputValid() {
         const regexp =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

         return new RegExp(regexp).test(this.userInput);
      },
   },

   watch: {
      userInput() {
         this.$emit('userInput', this.userInput);
         this.$emit('isValid', this.isInputValid);
         this.$refs['inputField'].classList.remove('error');
      },
   },

   methods: {
      loseFocus() {
         if (this.isInputValid) {
            return;
         }
         if (this.needValidation) {
            this.$refs['inputField'].classList.add('error');

            let removeError = setTimeout(() => {
               this.$refs['inputField'].classList.remove('error');
               clearTimeout(removeError);
            }, 2000);
         }
      },
   },
};
</script>

<style lang="sass" scoped>
label
   width: 100%
   display: block

   & .icon
      position: relative
      margin-top: 0.5rem

   & .icon::before
      content: url('../../assets/img/envelope.svg')
      position: absolute
      top: 50%
      left: 1rem
      width: 20px
      height: 20px
      transform: translateY(-50%)

   & span
      text-transform: uppercase
      font-size: 0.8rem
      letter-spacing: 0.04em

   & > *
      display: block

input
   width: 100%
   border: 1px solid #ccc
   border-radius: 4px
   padding: 1rem 1rem 1rem 3rem
   font-size: 1rem

.error
   border: 1px solid red !important
</style>