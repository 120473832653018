<template>
   <div class="advice-box" :class="{ hidden: isHidden }" v-if="item">
      <span class="playing-progress-bar" ref="progressBar" :class="{ playing: isCurrentAdvicePlaying }"></span>
      <button class="text" :class="classes" ref="item" @click="handleClick">
         <p>
            <AdviceText @click="openFullText" :style="stylesForMoreThanMaxLength" ref="text">
               {{ text }}
            </AdviceText>
         </p>
      </button>
      <div class="play">
         <button v-if="isPlay" @click.stop="stop" class="stop" :disabled="isPlayingPlayer">
            <svg><use xlink:href="../../assets/img/icons.svg#pause-icon"></use></svg>
         </button>
         <button v-if="!isPlay" @click.stop="play" :disabled="isPlayingPlayer">
            <svg><use xlink:href="../../assets/img/icons.svg#play"></use></svg>
         </button>
      </div>
   </div>
</template>

<script>
import mutations from '../../store/mutation-types';
import remoteOrigin from '../../store/remoteOrigin';
import AdviceText from '../AdviceComponents/AdviceText.vue';
export default {
   MAX_LENGTH_TEXT: 240,

   components: {
      AdviceText,
   },
   props: {
      item: {
         required: true,
      },
      isEditModeEnable: {
         type: Boolean,
      },

      isHidden: {
         type: Boolean,
         default: false,
      },
      classes: {
         type: Array,
      },
   },

   data() {
      return {
         isOpenFullText: false,
         isPlay: false,
         audio: '',
         isInView: false,
      };
   },

   computed: {
      isMoreThanMaxLength() {
         return this.item.text.length > this.$options.MAX_LENGTH_TEXT;
      },

      text() {
         return this.isMoreThanMaxLength && !this.isOpenFullText ? this.cutText(this.item.text) : this.item.text;
      },

      stylesForMoreThanMaxLength() {
         return this.isMoreThanMaxLength ? { cursor: 'pointer' } : { cursor: 'unset' };
      },

      isPlaying() {
         return this.$store.getters['advice/getPlay'];
      },

      isPlayingPlayer() {
         return this.$store.getters['advice/getIsPlayerPlaying'];
      },

      idOfPlayingAdvice() {
         return this.$store.getters['advice/getIdOfPlayingAdvice'];
      },

      isEditMode() {
         return this.$store.getters['advice/getEditMode'];
      },

      isCurrentAdvicePlaying() {
         return (
            this.idOfPlayingAdvice === this.item._id ||
            (this.idOfPlayingAdvice === this.item._id && this.isPlayingPlayer)
         );
      },

      timeOfPlaying() {
         return this.$store.getters['advice/getTimeOfPlaying'];
      },

      isUpdatedScroll() {
         return this.$store.getters['advice/getIsUpdatedScroll'];
      },

      index() {
         return this.$store.getters['advice/getPlayerIndex'];
      },
   },

   watch: {
      index() {
         if (this.index === 0) {
            sessionStorage.removeItem('wasInView');
         }
      },

      isEditModeEnable() {
         if (!this.isEditModeEnable) this.$refs.item.classList.remove('checked');
      },

      /*       index() {
         if (this.idOfPlayingAdvice === this.item._id && this.isPlayingPlayer) {
            this.playAudio();
            this.isPlay = true;
         } else if (this.idOfPlayingAdvice !== this.item._id && this.isPlayingPlayer) {
            this.clearAudio();
         }
      }, */

      idOfPlayingAdvice() {
         if (this.idOfPlayingAdvice === this.item._id && this.isPlay && !this.isPlayingPlayer) {
            this.playAudio();
         } else if (this.idOfPlayingAdvice !== this.item._id && this.isPlay && !this.isPlayingPlayer) {
            this.clearAudio();
         } else if (this.idOfPlayingAdvice === this.item._id && this.isPlayingPlayer) {
            this.checkifInView();
            this.playAudio();
            this.isPlay = true;
         } else if (this.idOfPlayingAdvice !== this.item._id && this.isPlayingPlayer) {
            this.clearAudio();
         }
      },

      isPlayingPlayer() {
         if (this.idOfPlayingAdvice === this.item._id && this.isPlay && !this.isPlayingPlayer) {
            this.stop();
            this.clearAudio();
            sessionStorage.removeItem('wasInView');
            this.isPlay = false;
         } else if (this.idOfPlayingAdvice === this.item._id && !this.isPlay && this.isPlayingPlayer) {
            this.checkifInView();
            this.playAudio();
            this.isPlay = true;
         }
      },

      timeOfPlaying() {
         if (this.idOfPlayingAdvice === this.item._id && this.isPlayingPlayer) {
            this.updateProgressBar();
         }
      },

      isUpdatedScroll() {
         this.checkifInView();
      },
   },

   mounted() {
      this.audio = new Audio(`${remoteOrigin}${this.item.media}`);
   },

   destroyed() {
      sessionStorage.removeItem('wasInView');
      this.$store.commit(`advice/${mutations.UPDATE_PLAY}`, false);
      if (this.item._id === this.idOfPlayingAdvice) {
         this.audio.pause();
         this.audio.removeEventListener('timeupdate', this.updateProgressBar);
         this.audio.removeEventListener('ended', this.clearAudio);
         if (this.isPlayingPlayer) {
            this.$store.commit(`advice/${mutations.UPDATE_IS_PLAYER}`, false);
         }
      }
   },

   methods: {
      cutText(text) {
         return text.slice(0, this.$options.MAX_LENGTH_TEXT - 3) + '...';
      },

      openFullText() {
         if (!this.isMoreThanMaxLength) return;
         this.isOpenFullText = !this.isOpenFullText;
      },

      handleClick() {
         this.isEditModeEnable
            ? (this.$emit('editItemsUpdate', this.item._id), this.$refs.item.classList.toggle('checked'))
            : this.openFullText();
      },

      play() {
         if (this.idOfPlayingAdvice === this.item._id) {
            this.playAudio();
         } else {
            this.audio.currentTime = 0;
         }
         this.isPlay = true;
         this.$store.commit(`advice/${mutations.UPDATE_PLAY}`, true);
         this.$store.commit(`advice/${mutations.SET_ID_OF_PLAYING_ADVICE}`, this.item._id);
      },

      stop() {
         this.audio.pause();
         this.isPlay = false;
      },

      clearAudio() {
         if (this.isPlayingPlayer && this.audio.currentTime === this.audio.duration) {
            this.$store.commit(`advice/${mutations.PLAY_DELIMETER}`, true);
         }

         this.isPlay = false;

         if (!this.isPlayingPlayer) {
            this.$store.commit(`advice/${mutations.SET_ID_OF_PLAYING_ADVICE}`, null);
            this.$store.commit(`advice/${mutations.UPDATE_PLAY}`, false);
         }
         this.audio.currentTime = 0;
         this.$refs.progressBar.style.width = 0;
         this.audio.pause();
         this.audio.removeEventListener('timeupdate', this.updateProgressBar);
         this.audio.removeEventListener('ended', this.clearAudio);
      },

      playAudio() {
         if (this.isPlayingPlayer && !this.isInView && !sessionStorage.wasInView) {
            this.$store.commit(`advice/${mutations.UPDATE_PLAYER_INDEX}`, this.index + 1);
         } else {
            sessionStorage.wasInView = true;
            this.audio.play();
            this.audio.addEventListener('ended', this.clearAudio);
            this.audio.addEventListener('timeupdate', this.updateProgressBar);
         }
      },

      updateProgressBar() {
         this.$refs.progressBar.style.width = (this.audio.currentTime / this.audio.duration) * 100 + '%';
      },

      checkifInView() {
         let rect = this.$refs.item.getBoundingClientRect();
         this.isInView =
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth);
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'
.advice-box
   position: relative

.text
   width: 100%
   border: 1px solid #f1f1f1
   padding: 15px 30px
   box-shadow: 0 0 8px #f1f1f1
   margin-top: 10px
   text-align: left
   transition: all 0.3s
   cursor: auto

.checked
   background-color: darken(#fff, 0.5%)
   margin-top: 12px

   position: relative

   &:active
      margin-top: 12px

   &::after
      content: ''
      position: absolute
      right: 5px
      top: 50%
      width: 20px
      height: 20px
      border: 2px solid #ccc
      border-radius: 100%
      background-color: lighten($app-secondary-color, 20%)
      transform: translate(0, -50%)

.playing-progress-bar
   display: none
   position: absolute
   background-color: green
   height: 4px
   bottom: 0
   transition: all 0.1s

.playing
   display: unset

#sepia .text.checked
   background-color: darken($app-sepia-background, 0.8%)

#sepia .text
   border: 1px solid $app-sepia-border
   box-shadow: 0 0 8px lighten($app-sepia-border, 30%)

   &:active
      background-color: darken($app-sepia-background, 0.8%)

#dark .text.checked
   background-color: darken($app-dark-background, 0.8%)

#dark .text
   &:active
      background-color: darken($app-dark-background, 0.8%)

   & p
      color: $app-dark-font-color

   &::after
      background-color: lighten($app-secondary-color, 0)

.play
   position: absolute
   bottom: 5px
   right: 5px
   & button
      & svg
         fill: #000
         width: 25px
         height: 25px
         stroke: #000
         stroke-width: 6

   & .stop svg
      stroke-width: 1

   & button:disabled svg
      opacity: 0.4

.hidden
   background-color: darken(white, 3%)

#dark .play button svg
   fill: #fff
   stroke: $app-dark-font-color
   stroke-width: 6

#sepia .hidden
   background-color: darken($app-sepia-background, 2%)

#dark .hidden
   background-color: darken($app-dark-background, 2%)

.slider__item + .play
   right: 5px

@media (max-width: 600px)
   .text
      padding: 9px 35px 9px 15px

   .text.slider__item
      padding-left: 33px
      padding-right: 33px

   .slider__item + .play
      right: 5px

.slider__item
   padding-right: 33px
   padding-left: 33px
</style>