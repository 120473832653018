import { advicesRouter } from '../childs/advices'
import VueRouter from "vue-router";

const routeApp = new VueRouter({
   mode: 'history',
   routes: [
      {
         path: '/',
         name: 'app',
         component: () => import('../../views/AdvicesApp/TheAdvices.vue'),
         redirect: { name: 'app-main' },
         children: advicesRouter,
      },
      {
         path: '/*',
         name: '404',
         component: () => import('../../views/The404Page.vue'),
      },
   ],
});

export default routeApp