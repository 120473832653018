<template>
   <div>
      <label :for="id">
         <span class="label_text" :style="disabled ? { color: '#ccc' } : ''"
            ><AdviceText :smaller="true">{{ label }} </AdviceText></span
         >
         <AdviceText>
            <input
               type="tel"
               name="phone"
               autocomplete="phone"
               id="number"
               placeholder="+1 (900) 000-0000"
               ref="inputField"
               maxlength="13"
               @blur="loseFocus"
               v-model="userInput"
               @input="validatePhone"
            />
         </AdviceText>
      </label>
      <p class="error__message" v-if="error || showError">{{ errorMessage }}</p>
   </div>
</template>

<script>
import AdviceText from '../AdviceComponents/AdviceText.vue';
export default {
   components: {
      AdviceText,
   },
   props: {
      needValidation: {
         type: Boolean,
         default: true,
      },
      label: {
         type: String,
      },

      errorMessage: {
         type: String,
      },

      disabled: {
         type: Boolean,
         default: false,
      },

      value: {
         type: String,
         required: true,
      },

      isValid: {
         type: Boolean,
         required: true,
      },
      showError: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         userInput: this.value,
         isValueValid: this.isValid,
         error: false,
      };
   },

   computed: {
      isInputValid() {
         const regexp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
         return new RegExp(regexp).test(this.userInput);
      },

      id() {
         return Math.floor((1 + Math.random()) * 0x10000);
      },
   },

   watch: {
      userInput() {
         this.$emit('update:value', this.userInput);
         this.$emit('update:isValid', this.isInputValid);
         this.$refs['inputField'].classList.remove('error');

         if (!this.isInputValid) {
            this.$refs['inputField'].classList.add('error');
            this.error = true;
         } else {
            this.error = false;
            this.$refs['inputField'].classList.remove('error');
         }
      },
   },

   methods: {
      loseFocus() {
         if (this.isInputValid && this.needValidation === false) {
            this.error = false;
            return;
         }
         if (this.needValidation && !this.isInputValid) {
            this.error = true;
            this.$refs['inputField'].classList.add('error');
         }
      },

      validatePhone() {
         this.userInput = this.userInput.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
         this.userInput = '+' + this.userInput;
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'
label
   width: 100%
   display: block
   position: relative

   & span
      text-align: left
      text-transform: uppercase
   & > *
      display: block

input
   width: 100%
   border: 1px solid #ccc
   border-radius: 4px
   padding: 1rem 1rem 1rem 1rem
   margin-top: 0.5rem

   &:disabled
      cursor: not-allowed
      border: 1px solid lighten(#ccc, 12%)

.error
   border: 1px solid red !important

.error__message
   text-align: left
   color: red
   font-size: 0.8rem

#sepia input
   border-color: $app-sepia-border

@media (max-width: 500px) and (max-height: 700px) and (orientation: portrait)
   input, .label_text
      font-size: 0.7rem

@media (max-width: 350px) and (max-height: 600px) and (orientation: portrait)
   input
      padding: 0.5rem 0.5rem 0.5rem 2rem
</style>