<template>
   <div class="modal-wrapper" @click="$emit('modalClose')">
      <div tabindex="-1" ref="modalBox" class="modal-content" @click.stop @keydown.esc="$emit('modalClose')">
         <button v-if="!disableCross" class="modal__close" @click="$emit('modalClose')">
            <svg><use xlink:href="../assets/img/icons.svg#cross-icon"></use></svg>
         </button>
         <div class="modal__inner">
            <transition name="bounce">
               <slot></slot>
            </transition>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      isOpen: {
         type: Boolean,
         default: false,
      },
      disableCross: {
         type: Boolean,
         default: false,
      },
   },

   emits: ['modalClose', ''],

   mounted() {
      this.$nextTick(() => {
         this.$refs.modalBox.focus();
      });
   },
};
</script>

<style lang="sass" scoped>
@import '../sass/_variables'
.bounce-enter-active
   animation: bounce-in .5s

.bounce-leave-active
   animation: bounce-in .5s reverse

@keyframes bounce-in
   0%
      transform: translate(-1000%, 20%)

   100%
      transform: translate(-50%, 20%)

.modal-wrapper
   width: 100%
   height: 100%
   padding: 20px 0
   overflow-y: scroll
   position: fixed
   top: 0
   left: 50%
   transform: translate(-50%, 0)
   background-color: rgba(000, 000, 000, 0.5)
   z-index: 2100

.modal-content
   outline: none
   margin: 0 auto
   max-width: 50vw
   width: 100%
   background-color: $font-color-white
   border: 1px solid black

   position: fixed
   top: 50%
   left: 50%
   transform: translate(-50%, -50%)

.modal__inner
   max-height: 800px
   overflow-y: auto

@media (max-width: 850px)
   .modal-content
      max-width: 90vw

@media (max-heigh: 500px) and (orientation: landscape)
   .modal-content
      height: 100%

.modal__close
   position: absolute
   top: 10px
   right: 10px

   & svg
      width: 20px
      height: 20px
</style>
