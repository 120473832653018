const mutations = {
	INCREASE_DISCOUNT: "INCREASE_DISCOUNT",
	DECREASE_DISCOUNT: "DECREASE_DISCOUNT",
	UPDATE_DISCOUNT: "UPDATE_DISCOUNT",
	CHANGE_NAME: "CHANGE_NAME",
	CHANGE_EMAIL: "CHANGE_EMAIL",
	CHANGE_PHONE: "CHANGE_PHONE",
	UPDATE_RESULTS: "UPDATE_RESULTS",
	SET_USER_ANSWERS: "SET_USER_ANSWERS",
	FIND_CATEGORY: "FIND_CATEGORY",
	FIND_SUBCATEGORIES: "FIND_SUBCATEGORIES",
	FIND_SUBCATEGORY: "FIND_SUBCATEGORY",
	UPDATE_PROFILE: "UPDATE_PROFILE",
	SET_PROFILE: "SET_PROFILE",
	UPDATE_TITLE: "UPDATE_TITLE",
	UPDATE_LOCALE: "UPDATE_LOCALE",
	UPDATE_IMG: "UPDATE_IMG",
	UPDATE_LASTNAME: "UPDATE_LASTNAME",
	UPDATE_FIRSTNAME: "UPDATE_FIRSTNAME",
	UPDATE_EDITE_MODE: "UPDATE_EDITE_MODE",
	SET_DISCOUNT: "SET_DISCOUNT",
	UPDATE_AUTH_ADMIN: "UPDATE_AUTH_ADMIN",
	UPDATE_DISCOUNT_PER_STEP: "UPDATE_DISCOUNT_PER_STEP",
	UPDATE_THEME: "UPDATE_THEME",
	UPDATE_FONT_SIZE: "UPDATE_FONT_SIZE",
	UPDATE_ADMIN_RESOURCES: "UPDATE_ADMIN_RESOURCES",
	UPDATE_ADMIN_SETTINGS: "UPDATE_ADMIN_SETTINGS",
	UPDATE_LOADING: "UPDATE_LOADING",
	UPDATE_CATEGORIES: "UPDATE_CATEGORIES",
	FIND_ADVICES: "FIND_ADVICES",
	UPDATE_SEARCH: "UPDATE_SEARCH",
	UPDATE_PLAY: "UPDATE_PLAY",
	SET_ID_OF_PLAYING_ADVICE: "SET_ID_OF_PLAYING_ADVICE",
	UPDATE_HIDDEN_CATEGORIES: "UPDATE_HIDDEN_CATEGORIES",
	PROFILE_ITEM_EDITING: "PROFILE_ITEM_EDITING",
	REMOVE_UNVISIBLE: "REMOVE_UNVISIBLE",
	SET_IS_MODAL_OPEN: "SET_IS_MODAL_OPEN",
	UPDATE_EDIT_ITEMS: "UPDATE_EDIT_ITEMS",
	UPDATE_UNVISIBLE: "UPDATE_UNVISIBLE",
	SET_PROFILE_LOADED: "SET_PROFILE_LOADED",
	SET_IS_SEARCH: "SET_IS_SEARCH",
	SET_HIDDEN_MODE: "SET_HIDDEN_MODE",
	SAVE_UNVISIBLE_TO_TEMP: "SAVE_UNVISIBLE_TO_TEMP",
	SET_IS_CONFIRM_ADVICES: "SET_IS_CONFIRM_ADVICES",
	UPDATE_IS_PLAYER: "UPDATE_IS_PLAYER",
	UPDATE_PLAYER_INDEX: "UPDATE_PLAYER_INDEX",
	PLAY_DELIMETER: "PLAY_DELIMETER",
	UPDATE_SUBSCRIPTIONS: "UPDATE_SUBSCRIPTIONS",
	SET_LOG_OUT_OPEN: "SET_LOG_OUT_OPEN",
	SET_COUPON: "SET_COUPON",
	UPDATE_ERROR_COUPON: "UPDATE_ERROR_COUPON"
}

export default mutations