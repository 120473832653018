<template>
   <div>
      <button type="button" @click="show = true" class="forgot-password">{{ $t('Login.forgotPassword') }}</button>
      <LoginForgotPasswordModal v-if="show" @modalClose="show = false" @phoneCode="handlePhoneCode" />
      <!-- <LoginSmsCode v-if="codeModal" :phone="phone" @closeModal="codeModal = false" /> -->
      <LoginSmsCode v-if="codeModal" :phone="phone" @closeModal="codeModal = false" />
   </div>
</template>

<script>
import LoginForgotPasswordModal from './LoginForgotPasswordModal.vue';
import LoginSmsCode from './LoginSmsCode.vue';
export default {
   components: { LoginForgotPasswordModal, LoginSmsCode },
   data() {
      return {
         show: false,
         codeModal: false,
         phone: '',
      };
   },

   mounted() {
      if (sessionStorage.forgetPhone) {
         this.phone = sessionStorage.forgetPhone;
      }
      if (sessionStorage.codeModal) {
         this.codeModal = JSON.parse(sessionStorage.codeModal);
      }
   },

   methods: {
      handlePhoneCode(phone) {
         this.show = false;
         this.codeModal = true;
         sessionStorage.codeModal = this.codeModal;
         this.phone = phone;
         sessionStorage.forgetPhone = this.phone;
      },
   },
};
</script>

<style lang="sass" scoped>
.forgot-password
   padding: 0 5px
   font-size: 0.9rem
</style>