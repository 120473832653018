<template>
   <div>
      <AdviceText class="error" v-if="error">{{ error }}</AdviceText>
      <form @submit.prevent="changePassword" ref="form">
         <ProfilePasswordInput
            :errorMessage="$t('Auth.passwordError')"
            :label="'current Password'"
            :value.sync="currentPassowrd"
            :isValid.sync="isCurrentValid"
         />
         <ProfilePasswordInput
            style="margin-top: 20px"
            :errorMessage="$t('Auth.passwordError')"
            :label="'New Password'"
            :value.sync="newPassword"
            :isValid.sync="isNewValid"
            :disabled="!isCurrentValid"
         />
         <ProfilePasswordInput
            style="margin-top: 20px"
            :errorMessage="'Password do not match'"
            :label="'Confirm Password'"
            :value.sync="passwordConfirm"
            :isValid.sync="isPasswordConfirmValid"
            :disabled="!isNewValid"
            :needValidation="false"
            :showError="!isPasswordConwirmed && isNewValid"
         />
         <AdviceBtn
            style="margin-top: 20px; margin-left: auto"
            :disabled="!isPasswordConwirmed"
            @click.native="changePassword"
            >{{ $t('Profile.saveBtn') }}</AdviceBtn
         >
      </form>
   </div>
</template>

<script>
import AdviceBtn from '../AdviceComponents/AdviceBtn.vue';
import AdviceText from '../AdviceComponents/AdviceText.vue';
import ProfilePasswordInput from './ProfilePasswordInput.vue';
export default {
   components: {
      ProfilePasswordInput,
      AdviceBtn,
      AdviceText,
   },
   data() {
      return {
         currentPassowrd: '',
         isCurrentValid: false,
         newPassword: '',
         isNewValid: false,
         passwordConfirm: '',
         isPasswordConfirmValid: false,
         error: '',
      };
   },
   computed: {
      isPasswordConwirmed() {
         if (!this.newPassword && !this.passwordConfirm) return false;
         return this.newPassword === this.passwordConfirm;
      },
   },

   methods: {
      async changePassword() {
         try {
            await this.$http.put('resources/profile/password', {
               password: this.currentPassowrd,
               new_password: this.newPassword,
            });
            this.$emit('closeModal');
            alert('Password has been changed');
         } catch (error) {
            this.error = error.data.message;
            let errorTimeout = setTimeout(() => {
               this.error = '';
               clearTimeout(errorTimeout);
            }, 2000);
            this.$refs['form'].reset();
         }
      },
   },
};
</script>

<style lang="sass" scoped>
.error
   color: darken(red, 10%)
   text-align-last: center
   font-weight: bold

.error__message
   text-align: left
   color: red
   font-size: 0.8rem
</style>