<template>
   <button class="btn" :type="type" :disabled="disabled">
      <slot></slot>
   </button>
</template>

<script>
export default {
   props: {
      disabled: {
         type: Boolean,
         default: false,
      },
      type: {
         type: String,
         default: 'button',
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.btn
   background-color: $app-main-color
   color: $font-color-white
   padding: 10px 20px
   border-radius: 20px 20px 20px 20px
   font-size: 1.5rem
   display: block
   transition: all 0.3s

   & *
      cursor: pointer

   &:active
      background-color: darken($app-main-color, 20%)

   &:hover
      background-color: darken($app-main-color, 10%)

   &:disabled
      cursor: not-allowed
      background-color: $app-main-color
</style>