<template>
   <TheModalWindow @modalClose="$emit('closeModal')">
      <form class="form" @submit.prevent="verifyCode">
         <label>
            <p>{{ $t('Registration.ConfirmPhone.label') }}</p>
            <input :class="showError ? 'error' : ''" type="text" v-model="value" />
            <p class="error__message" v-if="showError">{{ $t('Registration.ConfirmPhone.error') }}</p>
         </label>
         <div class="login-btn">
            <LoginBtn :isDisabled="isValueValid">{{ $t('ForgotPassword.send') }}</LoginBtn>
         </div>
         <ContactsSendCodeAgain :identifyer="phone" />
      </form>
   </TheModalWindow>
</template>

<script>
import ContactsSendCodeAgain from '../ContactsConponents/ContactsSendCodeAgain.vue';
import TheModalWindow from '../TheModalWindow.vue';
import LoginBtn from './LoginBtn.vue';

export default {
   components: { TheModalWindow, LoginBtn, ContactsSendCodeAgain },
   props: {
      phone: {
         type: String,
      },
   },
   data() {
      return {
         value: '',
         showError: false,
      };
   },

   computed: {
      isValueValid() {
         return new RegExp(/^[0-9]{8}$/).test(this.value);
      },
   },

   watch: {
      value() {
         this.isValueValid ? (this.showError = false) : (this.showError = true);
      },
   },

   methods: {
      async verifyCode() {
         try {
            const response = await this.$http.post('auth/forgot/check/phone', { code: this.value, phone: this.phone });
            if (response.status === 202) {
               sessionStorage.removeItem('codeModal');
               this.$router.push({ name: 'app-forgot-reset', params: { code: this.value, phone: this.phone } });
            }
         } catch (error) {
            alert('Wrong code, try again');
         }
      },
   },
};
</script>

<style lang="sass" scoped>
.form
   padding: 20px

   & > div
      margin-top: 20px

label
   width: 100%
   display: block
   & p
      text-transform: uppercase
      font-size: 1.3rem
      letter-spacing: 0.02em

   & > *
      display: block

input
   width: 100%
   border: 1px solid #ccc
   border-radius: 4px
   padding: 1rem 1rem 1rem 3rem
   font-size: 1.2rem
   margin-top: 0.5rem
   padding: 15px

   &:disabled
      cursor: not-allowed
      border: 1px solid lighten(#ccc, 12%)

.login-btn
   margin-bottom: 10px

.error
   border: 1px solid red !important

.error__message
   color: red
   font-size: 1rem
</style>