<template>
   <div class="wrapper" ref="wrapper">
      <div>
         <transition name="fade" mode="out-in">
            <router-view></router-view>
         </transition>
         <PWAprompt @cancel="cancel" :isInstallPWA.sync="isInstallPWA" />
      </div>
   </div>
</template>

<script>
import PWAprompt from './components/AppComponents/PWAprompt.vue';
import './css/app.css';
export default {
   provide() {
      return {
         installPWA: this.installPWA,
         cancelPWA: this.cancel,
      };
   },

   components: { PWAprompt },

   data() {
      return {
         isInstallPWA: false,
      };
   },

   methods: {
      installPWA() {
         this.isInstallPWA = true;
      },

      cancel() {
         this.isInstallPWA = false;
      },
   },
};
</script>

<style lang="sass">
@import './sass/_variables'

@media print
  html, body
    display: none

#installApp
  position: fixed
  background-color: white
  bottom: 0
  left: 50%
  transform: translate(-50%, -50%)
  font-size: 1.15rem
  z-index: 20000
  padding: 10px
  display: flex
  justify-content: center
  align-items: center
  border-radius: 10px
  font-family: 'Georgia', sans-serif
  max-width: 600px
  width: 91%
  border: 1px solid black

  &.top
    bottom: unset
    transform: translate(-50%, 30%)
    top: 0

  & svg
    width: 12px
    height: 12px
    fill: black

  & .content
    display: flex
    align-items: center
    justify-content: center

  & .logo
    max-width: 35px
    border-radius: 10px
    margin-right: 20px
    width: 100%

  & .install
    background-color: #395e44
    color: white
    padding: 5px 10px
    border-radius: 20px
    margin-left: 10px
    margin-right: 5px

  & .close
    position: absolute
    top: 5px
    right: 10px

@media (max-width: 600px)
  #installApp
    font-size: 1rem

    & .content
      text-align: center

    & .logo
      display: none

    & .install
      display: flex
      justify-content: center
      margin: 0 5px
      padding: 5px 10px

    & .logo
      margin-right: 10px

.fade-enter
  opacity: 0
  transform: translateY(10%)

.fade-enter-active
  transition: all 0.4s ease

.fade-leave-active
  transition: all 0.4s ease
  opacity: 0
  transform: translateY(10%)

h1, h2,
html, body,
ul, p
  margin: 0
  padding: 0

ul
  list-style: none

button
  border: none
  background-color: transparent
  padding: 0
  margin: 0

*, *::before, *::after
  box-sizing: border-box

*:focus
  outline: none

*:active
  outline: none

p:hover, h1:hover, h2:hover, h3:hover, h4:hover, h5:hover, h6:hover
  cursor: default

button:focus, button:active
  outline: none

body
  font-family: $font-primary
  font-weight: 300
  font-size: 1rem

body, html
  height: 100%

*
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

input, input:before, input:after
  -webkit-user-select: initial
  -khtml-user-select: initial
  -moz-user-select: initial
  -ms-user-select: initial
  user-select: initial

.wrapper
  height: 100%

  & > div
    height: inherit
</style>
