<template>
   <div>
      <label :for="id">
         <span class="label_text">{{ label }}</span>
         <input
            type="password"
            name="password"
            :id="id"
            placeholder="******"
            ref="inputField"
            @blur="loseFocus"
            v-model="userInput"
            :disabled="disabled"
            @focus="$emit('focus')"
            maxlength="32"
         />
      </label>
      <p class="error__message" v-if="error">{{ errorMessage }}</p>
   </div>
</template>

<script>
export default {
   props: {
      needValidation: {
         type: Boolean,
         default: true,
      },
      label: {
         type: String,
      },

      errorMessage: {
         type: String,
      },

      disabled: {
         type: Boolean,
         default: false,
      },

      errorShow: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         userInput: '',
         error: false,
      };
   },

   computed: {
      isInputValid() {
         const regexp = /^(?=.*[0-9])((?=.*[A-Z]))[a-zA-Z0-9!@#$%^&*]{6,32}$/;

         return new RegExp(regexp).test(this.userInput);
      },

      id() {
         return Math.floor((1 + Math.random()) * 0x10000);
      },
   },

   watch: {
      userInput() {
         this.$emit('userInput', this.userInput);
         this.$emit('isValid', this.isInputValid);
         this.$refs['inputField'].classList.remove('error');

         if (!this.isInputValid) {
            this.$refs['inputField'].classList.add('error');
            this.error = true;
         } else {
            this.error = false;
            this.$refs['inputField'].classList.remove('error');
         }
      },

      errorShow() {
         this.errorShow
            ? this.$refs['inputField'].classList.add('error')
            : this.$refs['inputField'].classList.remove('error');
      },
   },

   methods: {
      loseFocus() {
         if (this.isInputValid && this.needValidation === false) {
            this.error = false;
            return;
         }
         if (this.needValidation && !this.isInputValid) {
            this.error = true;
            this.$refs['inputField'].classList.add('error');

            let removeError = setTimeout(() => {
               this.error = false;
               this.$refs['inputField'].classList.remove('error');
               clearTimeout(removeError);
            }, 2000);
         }
      },
   },
};
</script>

<style lang="sass" scoped>
label
   width: 100%
   display: block
   position: relative

   &::before
      content: url('../../assets/img/padlock.svg')
      position: absolute
      top: 56%
      left: 1rem
      width: 20px
      height: 20px

   & span
      text-transform: uppercase
      font-size: 0.8rem
      letter-spacing: 0.04em

   & > *
      display: block

input
   width: 100%
   border: 1px solid #ccc
   border-radius: 4px
   padding: 1rem 1rem 1rem 3rem
   font-size: 1rem
   margin-top: 0.5rem

   &:disabled
      cursor: not-allowed
      border: 1px solid lighten(#ccc, 12%)

.error
   border: 1px solid red !important

.error__message
   color: red
   font-size: 0.8rem

@media (max-width: 500px) and (max-height: 700px) and (orientation: portrait)
   input, .label_text
      font-size: 0.7rem

@media (max-width: 350px) and (max-height: 600px) and (orientation: portrait)
   input
      padding: 0.5rem 0.5rem 0.5rem 2rem
   label::before
      top: 35%
      transform: translate(0, 50%) scale(0.7)
      left: 5px
</style>