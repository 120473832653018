<template>
   <component :is="tag" class="defaultSize" :class="className">
      <slot></slot>
   </component>
</template>

<script>
const BIG_SIZE = 'small';
const BIGGER_SIZE = 'bigger';
const BIG_WEIGHT = 'weigth';
const CURRENT_COLOR = 'darkColor';

export default {
   props: {
      tag: {
         type: String,
         default: 'p',
      },
      size: {
         type: String,
         default: 'big',
      },
      weigth: {
         type: String,
      },
      color: {
         type: String,
      },
   },
   computed: {
      className() {
         return {
            small: this.size === BIG_SIZE,
            bigger: this.size === BIGGER_SIZE,
            weigth: this.weigth === BIG_WEIGHT,
            darkColor: this.color === CURRENT_COLOR,
         };
      },
   },
};
</script>

<style scoped>
.defaultSize {
   font-size: 16px;
   color: inherit;
   font-weight: 400;
   line-height: 1.3em;
}
.small {
   font-size: 14px;
   color: inherit;
}
.bigger {
   font-size: 24px;
   color: inherit;
}
.weigth {
   font-weight: 700;
}
</style>
