<template>
   <div class="item" @click="editClicked">
      <div class="item__icon">
         <svg><use :xlink:href="itemImgSource"></use></svg>
      </div>
      <div class="item__box">
         <AdviceText :smaller="true" class="item__label">
            {{ labelText }}
         </AdviceText>
         <AdviceText
            v-if="!showInput"
            v-show="!isEditModeEnable"
            class="item__value"
            :style="variableName === 'email' ? { textTransform: 'lowercase' } : ''"
         >
            {{ currentValue || labelEditText }}
         </AdviceText>
         <slot :offEditMode="offEditMode" :isEditModeEnable="isEditModeEnable"></slot>
         <div class="item__edit-btn" v-if="!disabled">
            <ProfileEditBtn />
         </div>
      </div>
   </div>
</template>

<script>
import mutations from '../../store/mutation-types';
import AdviceText from '../AdviceComponents/AdviceText.vue';
import ProfileEditBtn from './ProfileEditBtn.vue';

export default {
   components: {
      ProfileEditBtn,
      AdviceText,
   },

   props: {
      labelText: {
         type: String,
         required: true,
      },

      labelEditText: {
         type: String,
         required: true,
      },

      currentValue: {
         default: '',
      },

      itemImg: {
         type: String,
      },

      variableName: {
         type: String,
         required: true,
      },

      showInput: {
         type: Boolean,
         default: false,
      },

      disabled: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         isEditModeEnable: false,
      };
   },

   computed: {
      isProfileItemEditing() {
         return this.$store.getters['profile/getProfileItemEditing'];
      },

      itemImgSource() {
         return '/img/icons.svg#' + this.itemImg;
      },
   },

   methods: {
      editClicked() {
         if (this.disabled) return;
         if (this.isProfileItemEditing) return;
         this.isEditModeEnable = true;
         this.$emit('edit');
      },

      offEditMode() {
         this.isEditModeEnable = false;
         this.$store.dispatch(`profile/updateProfile`);
         this.$store.commit(`profile/${mutations.PROFILE_ITEM_EDITING}`, false);
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.item
   display: flex
   align-items: center

   &__icon
      width: 25px
      height: 25px
      margin-right: 20px
      & svg
         width: 100%
         height: 100%
         fill: #76c4ba

   &__box
      width: 97%
      position: relative
      border-bottom: 1px solid #f1f1f1
      padding: 15px 0

   &__value
      font-weight: 500
      text-transform: capitalize

   &__input
      width: 90%

   &__edit-btn
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)

#dark .item
   color: $app-dark-font-color

#sepia .item__box
   border-bottom: 1px solid $app-sepia-border
</style>