<template>
   <p>{{ timeToSendInMinutes }}</p>
</template>

<script>
export default {
   props: {
      timeToEndInMiliseconds: {
         type: Number,
         required: true,
      },
   },

   data() {
      return {
         fiveMinInMiliseconds: this.timeToEndInMiliseconds,
         canBeSendAgain: false,
         timeToSendAgain: 0,
         timeWhenStarted: Date.now(),
         timeToEnd: 0,
      };
   },

   computed: {
      timeToSendInMinutes() {
         let minutes = Math.floor(this.timeToSendAgain / 60000);
         let seconds = ((this.timeToSendAgain % 60000) / 1000).toFixed(0);
         return !this.canBeSendAgain ? `0${minutes ? minutes : 0}:${seconds.length > 1 ? seconds : '0' + seconds}` : '';
      },
   },

   created() {
      this.timeToSendAgain = this.fiveMinInMiliseconds;
      this.timeToEnd = this.timeWhenStarted + this.fiveMinInMiliseconds;
   },

   mounted() {
      this.interval = setInterval(() => {
         let currentDateNow = Date.now();
         if (currentDateNow > this.timeToEnd) {
            this.timeWhenStarted = this.fiveMinInMiliseconds;
            this.$emit('timeIsEnded');
            clearInterval(this.interval);
            return;
         }
         this.timeToSendAgain = this.timeToEnd - currentDateNow;
      }, 1000);
   },
};
</script>

<style>
</style>