<template>
   <SocialButton @click="handleClick" :isAdded="isAdded">
      <img ref="imgMessanger" :src="'/img/' + imgName" alt="" />
   </SocialButton>
</template>

<script>
import SocialButton from './SocialButton.vue';
export default {
   components: {
      SocialButton,
   },

   props: {
      imgName: {
         required: true,
         type: String,
      },

      isAdded: {
         type: Boolean,
         default: false,
      },
   },

   methods: {
      async handleClick() {
         if (this.isAdded) return;
         let url = await this.fetchUrl();
         if (this.$route.name === 'app-profile') {
            localStorage.isAssign = true;
         }
         window.location.href = url;
      },

      async fetchUrl() {
         let response = await this.$http.post('auth/twitter/login/url', null, { withCredentials: true });
         return response.data.redirectUrl;
      },
   },
};
</script>