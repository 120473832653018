import { render, staticRenderFns } from "./TheInstructions.vue?vue&type=template&id=70e775a4&scoped=true&"
import script from "./TheInstructions.vue?vue&type=script&lang=js&"
export * from "./TheInstructions.vue?vue&type=script&lang=js&"
import style0 from "./TheInstructions.vue?vue&type=style&index=0&lang=sass&"
import style1 from "./TheInstructions.vue?vue&type=style&index=1&id=70e775a4&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e775a4",
  null
  
)

export default component.exports