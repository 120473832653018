export const isIOS = [
   'iPad Simulator',
   'iPhone Simulator',
   'iPod Simulator',
   'iPad',
   'iPhone',
   'iPod'
].includes(navigator.platform)
   // iPad on iOS 13 detection
   || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

export const isAndriod = navigator.userAgent.toLowerCase().indexOf("android") > -1

export const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);