<template>
   <button @click="openBuyModal" class="get-all">
      <AdviceText>{{ $t('Advice.Categories.getAllBtn') }}</AdviceText>
   </button>
</template>

<script>
import AdviceText from '../AdviceComponents/AdviceText.vue';
export default {
   components: {
      AdviceText,
   },

   inject: ['openBuyModal'],
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.get-all
   cursor: pointer
   display: block
   transition: all 0.3s
   color: $font-color-white
   margin: 0 auto
   font-size: 1.4rem
   font-weight: 500
   background-color: $app-main-color
   border-radius: 5px 5px 5px 5px
   padding: 10px 20px

   &:hover
      background-color: darken($app-main-color, 8%)

   &:active
      background-color: darken($app-main-color, 10%)

   & > *
      cursor: pointer
</style>