import axios from 'axios';
//import router from '../router/router.js';
import remoteOrigin from '../store/remoteOrigin.js';

const http = axios.create({
   //baseURL: 'http://localhost:7000/',
   baseURL: process.env.VUE_APP_API === 'local' ? 'http://localhost' : remoteOrigin,
});

http.interceptors.request.use(
 	function (config) {
 		if (!sessionStorage.isAuth) {
 			let url = new URL(window.location)
			if (url.searchParams.get('token')) {
 				localStorage.user_token = url.searchParams.get('token')
 				localStorage.social_img = url.searchParams.get('img')
 				window.location.replace(window.location.origin)
 			}
 		}
 		config.headers.Authorization = 'Bearer ' + localStorage.user_token;
 		return config;
 	},
 	function (error) {
 		return Promise.reject(error);
 	},
 );

// http.interceptors.response.use(
// 	res => {
// 		return res;
// 	},
// 	err => {
// 		if (err?.response?.data?.message) {

// 			if (err.response.status === 401) {
// 				router.push({
// 					name: 'home',
// 				});
// 			} else {
// 				return Promise.reject(err.response);
// 			}
// 		}
// 		return Promise.reject(err);
// 	},
// );

export default http;
