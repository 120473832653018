<template>
   <TheModalWindow @modalClose="$emit('modalClose')">
      <div class="forgot-password__modal">
         <LoginEmailPhoneSelector :selection.sync="selection" />
         <p class="error" v-if="error">
            {{ selection ? $t('ForgotPassword.emailNotFound') : $t('ForgotPassword.phoneNotFound') }}
         </p>
         <form @submit.prevent="sendReset" class="forgot-password__form">
            <InputEmail
               v-if="selection"
               :label="$t('Auth.emailLabel')"
               @isValid="updateIsIdentifyerValid"
               @userInput="updateIdentifyer"
            />
            <InputPhone
               v-else
               :label="$t('Auth.phoneLabel')"
               @isValid="updateIsIdentifyerValid"
               @userInput="updateIdentifyer"
            />
            <LoginBtn class="forgot-password__send" :isDisabled="isIdentifyerValid">
               {{ $t('ForgotPassword.send') }}
            </LoginBtn>
         </form>
      </div>
   </TheModalWindow>
</template>

<script>
import TheModalWindow from '../TheModalWindow.vue';
import InputEmail from '../UI/InputEmail.vue';
import InputPhone from '../UI/InputPhone.vue';
import LoginBtn from './LoginBtn.vue';
import LoginEmailPhoneSelector from './LoginEmailPhoneSelector.vue';
import { USER_NOT_FOUND } from '../../store/apiErrors';
export default {
   components: { TheModalWindow, LoginEmailPhoneSelector, InputEmail, InputPhone, LoginBtn },
   data() {
      return {
         selection: true,
         isIdentifyerValid: false,
         identifyer: '',
         error: false,
      };
   },

   watch: {
      selection() {
         this.identifyer = '';
         this.isIdentifyerValid = false;
      },
   },

   methods: {
      updateIsIdentifyerValid(e) {
         this.isIdentifyerValid = e;
      },

      updateIdentifyer(e) {
         this.identifyer = e;
      },

      sendReset() {
         switch (this.selection) {
            case true: {
               this.sendRequest('email');
               break;
            }
            case false: {
               this.sendRequest('phone');
               break;
            }
         }
      },

      removeError() {
         let timeOut = setTimeout(() => {
            this.error = false;
            clearTimeout(timeOut);
         }, 2500);
      },

      async sendRequest(provider) {
         try {
            const response = await this.$http.post(
               `auth/forgot/get/${provider}`,
               { [provider]: this.identifyer },
               { withCredentials: true },
            );
            if (response.status === 200) {
               if (provider === 'email') {
                  alert('Email was sent');
                  this.$emit('modalClose');
               } else {
                  alert('Code was sent to your phone');
                  this.$emit('phoneCode', this.identifyer);
               }
            }
         } catch (error) {
            if (error?.data?.message === USER_NOT_FOUND) {
               this.error = true;
               this.removeError();
            } else if (error.status === 422) {
               alert('Something went wrong, try again later.');
            } else {
               alert('"SMS sending limit per day exceeded');
               sessionStorage.removeItem('codeModal');
               this.$router.push({ name: 'app-login' });
            }
         }
      },
   },
};
</script>

<style lang="sass" scoped>
.forgot-password
   &__modal
      width: 100%
      padding: 20px
   &__form
      margin-top: 20px
      text-align: left

   &__send
      margin-top: 20px

.error
   color: red
</style>