<template>
   <div class="form-box">
      <div class="login-selector">
         <LoginEmailPhoneSelector :selection.sync="isEmailSelection" />
      </div>
      <form action="#" class="form" @submit.prevent="auth" ref="authForm">
         <div class="item">
            <InputEmail
               v-if="isEmailSelection"
               :label="$t('Auth.emailLabel')"
               @isValid="updateIsIdentifyerValid"
               @userInput="updateIdentifyer"
            />
            <InputPhone
               v-else
               :label="$t('Auth.phoneLabel')"
               @isValid="updateIsIdentifyerValid"
               @userInput="updateIdentifyer"
            />
         </div>

         <div class="item">
            <InputPassword
               @isValid="updateIsPasswordValid"
               @userInput="updatePassword"
               :errorMessage="$t('Auth.passwordError')"
               :label="$t('Auth.passwordLabel')"
               :errorShow="isWrongData"
               @focus="isWrongData = false"
            />
         </div>
         <p v-if="isWrongData" class="error-message">{{ $t('Auth.wrongDataText') }}</p>
         <div class="btns">
            <LoginBtn :disabled="!isFormValid" :isDisabled="isFormValid" :isLoading="isLoading">
               {{ $t('Login.signIn') }}
            </LoginBtn>
            <LoginForgotPasswordBtn />
         </div>
      </form>
   </div>
</template>

<script>
import InputPassword from '../components/UI/InputPassword.vue';
import InputEmail from '../components/UI/InputEmail.vue';
import InputPhone from '../components/UI/InputPhone.vue';
import LoginForgotPasswordBtn from './LoginComponents/LoginForgotPasswordBtn.vue';
import LoginEmailPhoneSelector from './LoginComponents/LoginEmailPhoneSelector.vue';
import LoginBtn from './LoginComponents/LoginBtn.vue';
export default {
   components: {
      InputPassword,
      InputEmail,
      InputPhone,
      LoginForgotPasswordBtn,
      LoginEmailPhoneSelector,
      LoginBtn,
   },

   data() {
      return {
         isPasswordValid: false,
         identifyer: '',
         isIdentifyerValid: false,
         isWrongData: false,
         isEmailSelection: true,
         isLoading: false,
      };
   },

   computed: {
      isFormValid() {
         return this.isIdentifyerValid && this.isPasswordValid;
      },
   },

   watch: {
      isEmailSelection() {
         this.$refs.authForm.reset();
         this.password = '';
      },
   },

   methods: {
      updateIsIdentifyerValid(e) {
         this.isIdentifyerValid = e;
      },

      updateIsPasswordValid(e) {
         this.isPasswordValid = e;
      },

      updateIdentifyer(e) {
         this.identifyer = e;
      },

      updatePassword(e) {
         this.userPassword = e;
      },

      async auth() {
         if (this.isFormValid) {
            const userValue = {
               password: this.userPassword,
            };

            let provider;
            if (this.isEmailSelection) {
               userValue.email = this.identifyer;
               provider = 'email';
            } else {
               userValue.phone = this.identifyer;
               provider = 'phone';
            }
            this.isLoading = true;
            try {
               const data = await this.$http.post(`auth/user_login/${provider}`, userValue);
               localStorage.user_token = data.data.token;
               if (navigator.serviceWorker.controller) {
                  navigator.serviceWorker.controller.postMessage({
                     type: 'logout',
                  });
               }
               this.$router.push({ name: 'app' });
            } catch (error) {
               if (error?.data?.message === 'User NOT verified') {
                  alert('Please, confirm your email/phone for authorization');
               } else {
                  this.isWrongData = true;
               }
            } finally {
               this.isLoading = false;
            }
         }
      },
   },
};
</script>

<style lang="sass" scoped>
.form-box
   height: 100%
   z-index: 2100
   padding: 20px

.item
   margin-top: 20px

.error-message
   font-size: 1rem
   color: red
   margin-left: 5px
   margin-top: 5px

.btns
   text-align: center
   margin-top: 20px

   & > *:last-child
      margin-top: 20px
</style>