<template>
   <header class="header" ref="header">
      <div class="header__content">
         <button type="button" class="header__btn-back" v-if="!isMainPage" @click="goBack">
            <svg>
               <use xlink:href="../../assets/img/icons.svg#header-arrow-back"></use>
            </svg>
         </button>
         <h1 class="header__title" ref="title">
            <span ref="titleMove"
               ><AdviceText :biggerOn="6">{{ title }}</AdviceText></span
            >
         </h1>

         <div class="header__search" v-show="!isProfilePage">
            <AdviceSearch />
         </div>

         <div class="header__btns">
            <AdviceSettingsBtn class="header__settings" v-if="!isProfilePage && isOnline" />

            <router-link v-if="!isProfilePage" :to="{ name: 'app-profile' }" class="profile-img__box">
               <img v-if="profileImg" class="profile-img__img" :src="profileImg" alt="profile image" />
               <img
                  v-else
                  class="profile-img__img"
                  src="../../assets/img/advices/default-profile.png"
                  alt="profile image"
               />
            </router-link>
            <button v-if="isProfilePage && !isModalOpen" @click="goBack" class="profile-close">
               <svg><use xlink:href="../../assets/img/icons.svg#cross-icon"></use></svg>
            </button>
            <div class="header__play" v-show="!isProfilePage">
               <AdvicePlay />
            </div>
            <AdviceLogoutBtn @logout="openLogoutModal" v-if="isProfilePage && !isModalOpen" />
         </div>
      </div>
   </header>
</template>

<script>
import AdviceLogoutBtn from './AdviceLogoutBtn.vue';
import AdviceSettingsBtn from './AdviceSettingsBtn.vue';
import AdviceSearch from './AdviceSearch.vue';
import AdvicePlay from './AdvicePlay.vue';
import AdviceText from '../AdviceComponents/AdviceText.vue';
import REMOTE_ORIGIN from '../../store/remoteOrigin';
import remoteOrigin from '../../store/remoteOrigin';

export default {
   prevScrollPos: 0,
   scrollElement: '',
   inject: ['openLogoutModal'],

   components: {
      AdviceSettingsBtn,
      AdviceLogoutBtn,
      AdviceSearch,
      AdvicePlay,
      AdviceText,
   },

   computed: {
      isProfilePage() {
         return this.$router.currentRoute.name === 'app-profile';
      },

      isMainPage() {
         return this.$route.name === 'app-main-all';
      },

      title() {
         return this.$store.getters['advice/getTitle'];
      },

      classesSettingsBtn() {
         return !this.isProfilePage ? ['header__settings'] : ['header__settings_alone'];
      },

      isEditMode() {
         return this.$store.getters['advice/getEditMode'];
      },

      fontSize() {
         return Number(this.$store.getters['advice/getCurrentFontSize']);
      },

      isModalOpen() {
         return this.$store.getters['advice/getIsModalOpen'];
      },

      profile() {
         return this.$store.getters['profile/getProfile'];
      },

      profileImg() {
         let url;
         if (this?.profile?.img) {
            url = `${remoteOrigin}${this.profile.img}`;
         } else if (
            localStorage.social_img &&
            localStorage.social_img !== 'undefined' &&
            localStorage.social_img !== 'null'
         ) {
            url = localStorage.social_img;
         } else {
            url = null;
         }
         return url;
      },

      REMOTE_ORIGIN() {
         return REMOTE_ORIGIN;
      },

      currentCategory() {
         return this.$store.getters['advice/getCategory'];
      },

      categories() {
         return this.$store.getters['advice/getAllCategories'];
      },

      isSearch() {
         return this.$store.getters['advice/getIsSearch'];
      },

      isPlaying() {
         return this.$store.getters['advice/getPlay'];
      },

      isPlayerPlaing() {
         return this.$store.getters['advice/getIsPlayerPlaying'];
      },

      isOnline() {
         return window.navigator.onLine;
      },

      isLogOutOpen() {
         return this.$store.getters['profile/getIsLogOutOpen'];
      },
   },

   watch: {
      isModalOpen() {
         if (this.isModalOpen) {
            this.$refs.header.style.top = '0';
         }
      },
   },

   mounted() {
      this.$options.scrollElement = document.querySelector('.scroll');
      this.$options.scrollElement.addEventListener('scroll', this.headerShowOrHideOnScroll);
   },

   destroyed() {
      this.$options.scrollElement.removeEventListener('scroll', this.headerShowOrHideOnScroll);
   },

   methods: {
      goBack() {
         if (this.currentCategory && this.isProfilePage) {
            !this.currentCategory.parent
               ? this.$router.push({ name: 'app-main' })
               : this.$router.push({
                    name: 'app-main-category',
                    params: { id: this.findParentId(this.currentCategory.parent) },
                 });
         } else {
            this.$router.go(-1);
         }
      },

      headerShowOrHideOnScroll() {
         let currentScrollPos = this.$options.scrollElement.pageYOffset || this.$options.scrollElement.scrollTop;
         let headerHeight = this.$refs?.header?.offsetHeight ?? 0;

         const updateHeaderTop = value => {
            this.$refs?.header ? (this.$refs.header.style.top = value) : '';
         };

         if (this.$options.prevScrollPos < currentScrollPos && currentScrollPos > headerHeight) {
            updateHeaderTop('-80px');
         } else if (this.$options.prevScrollPos > currentScrollPos) {
            updateHeaderTop('0');
         }
         this.$options.prevScrollPos = currentScrollPos;
      },

      goToMain() {
         this.$router.push({ name: 'app-main' });
      },

      findParentId(parent) {
         let parentItem = this.categories.filter(item => item.code === parent && !item.parent);
         return parentItem[0]._id;
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

@keyframes textMove
   0%
      transform: translateX(0)
   20%
      transform: translateX(0)
   90%
      transform: translateX(-90%)
   100%
      transform: translateX(-90%)

.header
   position: fixed
   top: 0
   width: 100%
   background-color: $app-main-color
   padding: 0 20px
   box-shadow: 0px 5px 5px #f3f3f3
   transition: all 0.3s
   z-index: 1000

   &__content
      max-width: 1300px
      width: 100%
      display: flex
      height: 100%
      justify-content: space-between
      align-items: center
      margin: 0 auto
      padding: 3px 0
      gap: 0 10px

   &__title
      color: $font-color-white
      font-weight: 400
      font-size: 1.8rem
      width: 100%
      max-width: 200px
      overflow-x: auto
      white-space: nowrap
      position: relative
      margin-right: auto

   &__btn-back
      display: block
      width: 30px
      height: 30px
      margin-right: 20px
      z-index: 10000
      transform: rotate(-90deg)
      & svg
         fill: #fff
         width: inherit
         height: inherit
         stroke: #fff
         stroke-width: 2.5

      &::hover
         cursor: pointer

   &__search
      max-width: 600px
      margin: 0 auto
      width: 100%
      height: 100%

   &__settings
      margin-right: 25px
      height: 25px

   &__btns
      display: flex
      align-items: center

.play-searh
   margin-left: auto
   margin: 0 10px
   width: 100%

   &__box
      min-height: 34px
      display: flex
      align-items: center
      justify-content: space-around

.header__title::-webkit-scrollbar
   display: none

.header__title
   -ms-overflow-style: none
   scrollbar-width: none

.profile-close
   cursor: pointer
   height: 20px
   width: 20px
   margin-right: 30px

   & svg
      width: inherit
      height: inherit
      fill: #fff

.hidden
   position: relative

.hidden:after
   content: ''
   position: absolute
   top: 0
   right: -2px
   height: 100%
   width: 5px
   background: linear-gradient(90deg, rgba(255,255,255,0.06626400560224088) 0%, rgba(255,255,255,0.396796218487395) 100%)

@media(max-width: 600px)
   .header__title
      max-width: 80px
      font-size: 25px

   .header__btn-back
      margin-right: 10px

#sepia
   .header
      box-shadow: 0px 5px 5px lighten($app-sepia-border, 30%)

#dark
   .header
      box-shadow: 0px 5px 5px lighten($app-sepia-border, 0%)

.profile-img__box
   width: 32px
   margin-right: 32px
   height: 32px

.profile-img__img
   border-radius: 100%
   width: 32px
   height: 32px

@media(max-width: 600px)
   .header
      padding: 0 15px
      &__title
         display: none

@media(max-width: 450px)
   .profile-img__box
      padding-top: 1px
      margin-right: 10px

   .header__settings
      margin-right: 15px
</style>