<template>
   <button class="messanger" type="button" :class="{ added: isAdded }" @click="$emit('click')">
      <slot></slot>
   </button>
</template>

<script>
export default {
   props: {
      isAdded: {
         required: true,
         type: Boolean,
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.fade-enter-active, .fade-leave-active
   transition: opacity .8s
   opacity: 1

.fade-enter, .fade-leave-to
   position: absolute
   opacity: 0
   right: 10000%

.messanger
   border-radius: 4px
   border: solid 1.6px #ebebeb
   display: flex
   align-items: center
   justify-content: center
   padding: 0.5rem 1rem
   cursor: pointer

   &:hover
      box-shadow: -2px 2px 14px 0 #ebedef

   & img
      height: 32px

   & p
      font-size: 1rem

   &.added::hover
      box-shadow: none

   &.added
      opacity: 0.4
      cursor: not-allowed

#sepia .messanger
   border: 1px solid $app-sepia-border

#dark .messanger
   border: 1px solid #f1f1f1
</style>