<template>
   <div class="themes">
      <div class="themes__icon">
         <svg fill="red" width="20" height="20">
            <svg><use xlink:href="../../assets/img/icons.svg#font-size"></use></svg>
         </svg>
      </div>
      <div class="themes__content">
         <p class="themes__title">
            <AdviceText :smaller="true">{{ $t('Profile.fontLabel') }}</AdviceText>
         </p>
         <div class="themes__box" @change="changeFontSize">
            <label for="small" ref="small" class="themes__theme small">
               A
               <input type="radio" id="small" name="theme" value="small" />
            </label>

            <label for="medium" ref="medium" class="themes__theme medium">
               A
               <input type="radio" id="medium" name="theme" value="medium" />
            </label>

            <label for="large" ref="large" class="themes__theme large">
               A
               <input type="radio" id="large" name="theme" value="large" />
            </label>
         </div>
      </div>
   </div>
</template>

<script>
import AdviceText from '../AdviceComponents/AdviceText.vue';
export default {
   inject: ['getCurrentFontSize'],

   components: {
      AdviceText,
   },

   data() {
      return {
         currentFontSize: localStorage.fontSize ? localStorage.fontSize : 'medium',
         fontSizes: ['small', 'medium', 'large'],
      };
   },

   mounted() {
      localStorage.fontSize
         ? this.$refs[localStorage.fontSize].classList.add('active')
         : this.$refs.medium.classList.add('active');
   },

   methods: {
      changeFontSize(e) {
         if (this.currentFontSize === e.target.value) {
            return;
         }
         this.currentFontSize = e.target.value;
         localStorage.fontSize = this.currentFontSize;
         this.getCurrentFontSize();
         for (let font of this.fontSizes) {
            this.currentFontSize === font
               ? this.$refs[this.currentFontSize].classList.add('active')
               : this.$refs[font].classList.remove('active');
         }
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'
.themes
   display: flex
   align-items: center

   &__icon
      width: 25px
      height: 25px
      margin-right: 20px
      & svg
         width: 100%
         height: 100%
         fill: #76c4ba

   &__title
      margin-bottom: 10px

   &__box
      display: flex
      align-items: center

   &__theme
      display: inline-flex
      justify-content: center
      align-items: flex-end
      width: 25px
      height: 25px
      transition: all 0.3s
      margin: 0 10px
      position: relative

      & span
         height: 100%
         width: 100%
         display: inline-flex
         justify-content: center
         align-items: center

      & input
         transform: translateX(-1000px)
         position: relative
         opacity: 0

   & .small
      font-size: 20px

      &.active::after
         left: -40%
         bottom: -18%
         width: 30px
         height: 30px

   & .medium
      font-size: 24px

      &.active::after
         left: -45%
         bottom: -20%
         width: 35px
         height: 35px

   & .large
      font-size: 28px
      &.active::after
         left: -45%
         bottom: -10%
         width: 35px
         height: 35px

   & .active::after
      content: ''
      position: absolute
      border-radius: 100%
      border: 1px solid #f1f1f1

#sepia .active::after
   border: 1px solid $app-sepia-border

#dark .active::after
   border: 1px solid #f1f1f1

#dark label
   color: $app-dark-font-color

@media (max-width:600px)
   .themes
      &__theme
         width: 25px
         height: 25px
         margin: 0 5px

      & .active::after
         width: 25px
         height: 25px

      & .small
         font-size: 18px
         &.active::after
            left: -26%
            bottom: -14%
            width: 25px
            height: 25px

      & .medium
         font-size: 20px
         &.active::after
            left: -26.5%
            bottom: -12%
            width: 25px
            height: 25px

      & .large
         font-size: 22px
         &.active::after
            left: -26.5%
            bottom: -5%
            width: 25px
            height: 25px
</style>