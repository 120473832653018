<template>
   <div>
      <AdviceText class="error" v-if="error">{{ error }}</AdviceText>
      <form @submit.prevent="changePassword" ref="form" v-if="!isEnterCode">
         <ProfilePhoneInput
            :errorMessage="$t('Auth.phoneError')"
            :label="'New phone'"
            :value.sync="phone"
            :isValid.sync="isPhoneValid"
         />
         <ProfilePasswordInput
            v-if="!profile.email"
            style="margin-top: 20px"
            :errorMessage="$t('Auth.passwordError')"
            :label="$t('Profile.addNewPass')"
            :value.sync="currentPassowrd"
            :isValid.sync="isCurrentValid"
            :disabled="!isPhoneValid"
         />
         <AdviceBtn
            style="margin-top: 20px; margin-left: auto"
            :disabled="!isCurrentValid && !isPhoneValid"
            @click.native="changePhone"
            >{{ $t('Profile.saveBtn') }}</AdviceBtn
         >
      </form>
      <div v-if="isEnterCode">
         <AdviceText class="ttu" :smaller="true">ENTER CODE</AdviceText>
         <AdviceText>
            <input class="email-input" type="text" v-model="code" />
         </AdviceText>
         <AdviceBtn style="margin-top: 20px; margin-left: auto" :disabled="!isCodeValid" @click.native="sendCode"
            >Confirm</AdviceBtn
         >
      </div>
   </div>
</template>

<script>
import actions from '../../store/action-types';
import AdviceBtn from '../AdviceComponents/AdviceBtn.vue';
import AdviceText from '../AdviceComponents/AdviceText.vue';
import ProfilePasswordInput from './ProfilePasswordInput.vue';
import ProfilePhoneInput from './ProfilePhoneInput.vue';
export default {
   components: {
      AdviceBtn,
      AdviceText,
      ProfilePasswordInput,
      ProfilePhoneInput,
   },
   data() {
      return {
         isPhoneValid: false,
         isPasswordValid: false,
         phone: '',
         userPassword: '',
         currentPassowrd: '',
         isCurrentValid: false,
         error: '',
         isEnterCode: false,
         code: null,
      };
   },

   computed: {
      isCodeValid() {
         return new RegExp(/^[0-9]{6}$/).test(this.code);
      },

      profile() {
         return this.$store.getters['profile/getProfile'];
      },
   },

   methods: {
      async changePhone() {
         try {
            const data = { phone: this.phone };
            if (this.currentPassowrd) data.password = this.currentPassowrd;
            const response = await this.$http.put('resources/profile/asign/phone', data);
            //alert('Successfully changed. Check your email for verify!');
            if (response.status === 200) {
               this.$store.dispatch(`profile/${actions.FETCH}`);
               this.$emit('closeModal');
               alert('Phone was added');
            }
         } catch (error) {
            this.error = error.data.message;
            let errorTimeout = setTimeout(() => {
               this.error = '';
               clearTimeout(errorTimeout);
            }, 2000);
            this.$refs['form'].reset();
         }
      },

      async sendCode() {
         try {
            await this.$http.post('auth/verify', { hash: this.code, provider: 'phone' });
            this.$emit('mobileConfirmed');
            this.$emit('closeModal');
         } catch (error) {
            alert('Wrong code, try again');
         }
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.ttu
   text-transform: uppercase
   text-align: left
   width: 100%

.email-input
   width: 100%
   border: 1px solid #ccc
   border-radius: 4px
   padding: 1rem 1rem 1rem 1rem
   margin-top: 0.5rem

   &:disabled
      cursor: not-allowed
      border: 1px solid lighten(#ccc, 12%)

.error
   color: darken(red, 10%)
   text-align-last: center
   font-weight: bold

#sepia .email-input
   border-color: $app-sepia-border
</style>