<template>
   <TheModalWindow :disableCross="true">
      <div class="new-password">
         <form @submit.prevent="sendNewPassword">
            <InputPassword
               @isValid="updateIsPasswordValid"
               @userInput="updatePassword"
               :errorMessage="$t('Auth.passwordError')"
               :label="$t('ForgotPassword.newPass')"
            />
            <InputPassword
               @isValid="updateIsPasswordConfirmValid"
               @userInput="updatePasswordConfirm"
               :label="$t('ForgotPassword.passConfirm')"
               :disabled="!isNewPassVaild"
               :needValidation="!isConfirmAllowed"
               :errorMessage="$t('Registration.Form.passwordConfirmError')"
            />
            <LoginBtn :isDisabled="isConfirmAllowed">
               {{ $t('ForgotPassword.ChangePassword') }}
            </LoginBtn>
         </form>
      </div>
   </TheModalWindow>
</template>

<script>
import LoginBtn from '../../components/LoginComponents/LoginBtn.vue';
import TheModalWindow from '../../components/TheModalWindow.vue';
import InputPassword from '../../components/UI/InputPassword.vue';
import smsTokenLength from '../../store/smsTokenLength';
export default {
   components: { InputPassword, TheModalWindow, LoginBtn },

   data() {
      return {
         code: '',
         isNewPassVaild: false,
         newPass: '',
         confirmPass: '',
         isConfirmPassValid: false,
         phone: '',
      };
   },

   computed: {
      isConfirmAllowed() {
         return this.newPass.length === 0 ? false : this.newPass === this.confirmPass;
      },
   },

   mounted() {
      if (this.$route.params.code || sessionStorage.code) {
         this.code = this.$route.params.code || sessionStorage.code;
         sessionStorage.code = this.code;
      }

      if (this.$route.params.phone || sessionStorage.resetPhone) {
         this.phone = this.$route.params.phone || sessionStorage.resetPhone;
      }
   },

   methods: {
      updateIsPasswordValid(data) {
         this.isNewPassVaild = data;
      },

      updatePassword(data) {
         this.newPass = data;
      },

      updateIsPasswordConfirmValid(data) {
         this.isConfirmPassValid = data;
      },

      updatePasswordConfirm(data) {
         this.confirmPass = data;
      },

      async sendNewPassword() {
         const data = {
            code: this.code,
            password: this.newPass,
         };
         this.code.length === smsTokenLength ? (data.phone = this.phone) : '';
         try {
            const response = await this.$http.post(
               `auth/forgot/password/${this.code.length === smsTokenLength ? 'phone' : 'email'}`,
               data,
            );

            if (response.status === 200) {
               this.$router.push({ name: 'app-login' });
            }
         } catch (error) {
            if (error.status === 422) {
               alert('Password was not updated. Try again');
            } else if (error.status === 401) {
               this.$router.push({ name: 'app-login' });
            }
         }
      },
   },
};
</script>

<style lang="sass" scoped>
.new-password
   padding: 20px

   & > form > *
      margin-top: 20px

      &:last-child
         margin-bottom: 20px
</style>