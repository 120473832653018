<template>
   <div>
      <AdviceText class="error" v-if="error">{{ error }}</AdviceText>
      <form @submit.prevent="changePassword" ref="form">
         <label for="email" class="ttu">
            <AdviceText :smaller="true">
               {{ $t('Profile.newEmail') }}
            </AdviceText>
         </label>
         <AdviceText>
            <input
               type="email"
               id="email"
               name="email"
               autocomplete="email"
               v-model="email"
               class="email-input"
               placeholder="example@gmail.com"
            />
         </AdviceText>
         <ProfilePasswordInput
            v-if="!profile.phone"
            style="margin-top: 20px"
            :errorMessage="$t('Auth.passwordError')"
            :label="$t('Profile.addNewPass')"
            :value.sync="currentPassowrd"
            :isValid.sync="isCurrentValid"
            :disabled="!isEmailValid"
         />
         <AdviceBtn
            style="margin-top: 20px; margin-left: auto"
            :disabled="!isCurrentValid && !isEmailValid"
            @click.native="changeEmail"
            >{{ $t('Profile.saveBtn') }}</AdviceBtn
         >
      </form>
   </div>
</template>

<script>
import actions from '../../store/action-types';
import AdviceBtn from '../AdviceComponents/AdviceBtn.vue';
import AdviceText from '../AdviceComponents/AdviceText.vue';
import ProfilePasswordInput from './ProfilePasswordInput.vue';
export default {
   components: {
      AdviceBtn,
      AdviceText,
      ProfilePasswordInput,
   },
   data() {
      return {
         isEmailOrPhoneValid: false,
         isPasswordValid: false,
         email: '',
         userPassword: '',
         currentPassowrd: '',
         isCurrentValid: false,
         error: '',
      };
   },

   computed: {
      isEmailValid() {
         const regexp =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         return new RegExp(regexp).test(this.email);
      },

      profile() {
         return this.$store.getters['profile/getProfile'];
      },
   },

   methods: {
      async changeEmail() {
         try {
            const data = { email: this.email };
            if (this.currentPassowrd) data.password = this.currentPassowrd;
            const response = await this.$http.put('resources/profile/asign/email', data);
            if (response.status === 200) {
               this.$store.dispatch(`profile/${actions.FETCH}`);
               this.$emit('closeModal');
               alert('New email successfully added!');
            }
         } catch (error) {
            this.error = error.data.message;
            let errorTimeout = setTimeout(() => {
               this.error = '';
               clearTimeout(errorTimeout);
            }, 2000);
            this.$refs['form'].reset();
         }
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.ttu
   text-transform: uppercase
   text-align: left
   width: 100%

.email-input
   width: 100%
   border: 1px solid #ccc
   border-radius: 4px
   padding: 1rem 1rem 1rem 1rem
   margin-top: 0.5rem

   &:disabled
      cursor: not-allowed
      border: 1px solid lighten(#ccc, 12%)

.error
   color: darken(red, 10%)
   text-align-last: center
   font-weight: bold

#sepia .email-input
   border-color: $app-sepia-border
</style>