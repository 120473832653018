<template>
   <TheModalWindow :disableCross="true">
      <div>
         <AuthForm />
         <div class="messangers">
            <Messangers :auth="true" />
         </div>
      </div>
   </TheModalWindow>
</template>

<script>
import TheModalWindow from '../../components/TheModalWindow.vue';
import Messangers from '../../components/ContactsConponents/ContactsMessangers.vue';
import AuthForm from '../../components/AuthForm.vue';
export default {
   components: {
      TheModalWindow,
      AuthForm,
      Messangers,
   },
};
</script>

<style lang="sass" scoped>
.messangers
   padding: 0 10px
</style>