<template>
   <button
      :type="additionalType"
      :disabled="isDisabled"
      :style="additionalStyles"
      @click="$emit('clickBtn')"
      class="result-btn"
   >
      <img class="result-btn__img" src="../../assets/img/resultsCheck.svg" alt="" />
      <span class="result-btn__text">
         <slot>{{ $t('Results.btn') }}</slot>
      </span>
   </button>
</template>

<script>
export default {
   props: {
      additionalStyles: {
         type: String,
         default: '',
      },

      isDisabled: {
         type: Boolean,
         default: false,
      },

      additionalType: {
         type: String,
         default: 'button',
      },
   },

   emits: ['clickBtn'],
};
</script>

<style lang="sass" scope>
@import '../../sass/_variables'

.result-btn
	display: flex
	align-items: center

	padding: 1rem

	font-size: 1.2rem
	color: $font-color-white !important

	border-radius: 24px

	background-color: $color-quiz-primary
	box-shadow: 0 6px 21px 0 rgba(102, 185, 64, 0.5)

	transition: all 0.3s

	&__img
		margin-right: 0.6rem
		width: 20px
		height: 20px

	&:hover
		cursor: pointer
		transform: scale(1.1)

	&:active
		background-color: darken($color-quiz-primary , 5%)
		box-shadow: 0 6px 21px 0 darken(rgba(102, 185, 64, 0.5) , 5%)

	&:disabled
		cursor: not-allowed
		background-color: lighten($color-quiz-primary, 20%)
</style>