<template>
   <button class="edit-btn" type="button" :disabled="disabled ? disabled : false" @click="$emit('click')">
      <!--    <img ref="btnImg" src="" alt="" /> -->
      <AdviceText :disable="checkIsMobile">{{ text }}</AdviceText>
   </button>
</template>

<script>
import AdviceText from '../AdviceComponents/AdviceText.vue';
export default {
   components: {
      AdviceText,
   },
   props: {
      imgLink: {
         type: String,
      },
      text: {
         type: String,
      },

      disabled: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         isMobild: false,
      };
   },

   computed: {
      checkIsMobile() {
         return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      },
   },
   /* 
   created() {
      this.$refs.btnImg.src = this.imgLink ? this.imgLink : '';
   }, */
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.edit-btn
   display: flex
   align-items: center
   justify-content: center
   font-size: 18px
   padding: 15px 20px
   width: 100%
   border-right: 1px solid $app-secondary-color
   border-left: 1px solid $app-secondary-color
   transition: all 0.3s

   & > *
      cursor: pointer

   &:disabled
      cursor: not-allowed
      color: #ccc
      & p
         cursor: not-allowed

#sepia .edit-btn
   background-color: $app-sepia-background
   border-right: 1px solid $app-sepia-border
   border-left: 1px solid $app-sepia-border

#dark .edit-btn
   background-color: $app-dark-background
   color: $app-dark-font-color
   border-left: 1px solid #fff

@media (max-width: 400px)
   .edit-btn
      padding: 5px
</style>