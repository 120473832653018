<template>
   <button @click="handleClick" class="btn" type="button">
      <img src="../../assets/img/advices/profile-pencil-icon.svg" alt="pencil" />
   </button>
</template>

<script>
export default {
   methods: {
      handleClick() {
         this.$emit('edit');
      },
   },
};
</script>

<style lang="sass" scoped>
.btn
   width: 18px
   height: 18px
   font-size: 1.5rem
   color: white
   letter-spacing: 0.05em

   & img
      width: 100%
</style>