<template>
   <div class="buy-banner">
      <div v-if="subscription && !isPayment">
         <div class="buy-banner__header">
            <div class="buy-banner__icon">
               <img src="/ms-icon-310x310.png" />
            </div>
            <div class="buy-banner__title">
               <AdviceText :disable="true">
                  {{ subscription.name }}
               </AdviceText>
               <AdviceText :smallerOn="8"> {{ subscription.description }} </AdviceText>
            </div>
         </div>
         <div class="buy-banner__chargers">
            <div class="buy-banner__title">
               <AdviceText :disable="true"> {{ $t('Payment.changes') }} </AdviceText>
            </div>
            <div class="buy-banner__chargers-box">
               <div class="buy-banner__chargers-icon buy-banner__icon">
                  <img src="/ms-icon-310x310.png" />
               </div>
               <dl class="buy-banner__chargers-list">
                  <div class="buy-banner__chargers-item">
                     <dt>
                        <AdviceText :smallerOn="8">{{ $t('Payment.start_today') }}</AdviceText>
                     </dt>
                     <dd>
                        <AdviceText :smallerOn="8">
                           {{ $t('Subscription.trialDays', { days: subscription.free_trial_days }) }}
                        </AdviceText>
                     </dd>
                  </div>
                  <div class="buy-banner__chargers-item">
                     <dt>
                        <AdviceText :smallerOn="8">{{
                           $t('Payment.start_date', { date: startSubscriptionDate })
                        }}</AdviceText>
                     </dt>
                     <dd>
                        <div class="buy-banner__chargers-price">
                           <AdviceText :smallerOn="8">
                              <span :class="{ 'buy-banner__chargers-price_line': youSave && youSave != finalPrice }">
                                 {{ subscription.price }}$
                              </span>
                              /{{ subscription.name }}
                           </AdviceText>
                           <AdviceText :smallerOn="8">
                              You Save <span class="buy-banner__chargers-price_red">{{ youSave }}$</span>
                           </AdviceText>
                           <AdviceText :smallerOn="8" class="buy-banner__chargers-price_bold">
                              To Pay {{ finalPrice }}$
                           </AdviceText>
                        </div>
                     </dd>
                  </div>
               </dl>
            </div>
         </div>
         <div>
            <div class="buy-banner__title">
               <AdviceText :disable="true">
                  {{ $t('Payment.subscribe_on') }}
               </AdviceText>
            </div>
            <ul class="list-with-dots">
               <li>
                  <AdviceText :smallerOn="8">{{ $t('Payment.subsctibe_step_1') }}</AdviceText>
               </li>
               <li>
                  <AdviceText :smallerOn="8">{{
                     $t('Payment.subsctibe_step_2', { date: startSubscriptionDate })
                  }}</AdviceText>
               </li>
               <li>
                  <AdviceText :smallerOn="8">{{ $t('Payment.subsctibe_step_3') }}</AdviceText>
               </li>
               <!--             <li>
               <AdciveText :smallerOn="8">{{ $t('Payment.subsctibe_step_4') }}</AdciveText>
            </li> -->
            </ul>
         </div>
         <div>
            <AdviceText :smallerOn="8">
               {{ $t('Payment.billing_tip', { price: finalPrice, date: startSubscriptionDate }) }}
            </AdviceText>
            <AdviceText :smallerOn="8">
               {{ $t('Payment.by_tapping') }}
            </AdviceText>
         </div>
         <AdviceBtn class="subscription__btn" @click.native="isPayment = true">{{ $t('Payment.subscribe') }}</AdviceBtn>
      </div>

      <form class="form" @submit.prevent="pay" v-if="isPayment">
         <div class="form__input">
            <label for="firstName"> firstName </label>
            <input type="string" id="firstName" v-model="payment.firstName" name="firstName" />
         </div>
         <div class="form__input">
            <label for="lastName"> lastName </label>
            <input type="string" id="lastName" v-model="payment.lastName" name="lastName" />
         </div>
         <div class="form__input">
            <label for="zip"> zip </label>
            <input type="string" id="zip" v-model="payment.zip" name="zip" />
         </div>
         <div class="form__input">
            <label for="expirationYear"> expirationYear </label>
            <input type="string" id="expirationYear" v-model="payment.expirationYear" name="expirationYear" />
         </div>
         <div class="form__input">
            <label for="cardNumber"> securityCode </label>
            <input type="string" id="cardNumber" v-model="payment.securityCode" name="securityCode" />
         </div>
         <div class="form__input">
            <label for="expirationMonth"> expirationMonth </label>
            <input type="string" id="expirationMonth" v-model="payment.expirationMonth" name="expirationMonth" />
         </div>
         <div class="form__input">
            <label for="cardNumber"> Сard Number </label>
            <input type="string" id="cardNumber" v-model="payment.cardNumber" name="cardNumber" />
         </div>

         <button type="submit">Pay</button>
      </form>
   </div>
</template>

<script>
import AdviceText from '../AdviceComponents/AdviceText.vue';
import AdviceBtn from '../AdviceComponents/AdviceBtn.vue';
export default {
   components: { AdviceText, AdviceBtn },

   props: {
      subscription: {
         type: Object,
         required: true,
      },
      youSave: {
         type: Number,
         required: true,
      },
   },

   data() {
      return {
         payment: {
            transaction_type: 'card',
            firstName: '',
            lastName: '',
            zip: '',
            expirationYear: '',
            securityCode: '',
            expirationMonth: '',
            cardNumber: '',
         },
         isPayment: false,
      };
   },

   computed: {
      monthNames() {
         return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      },
      startSubscriptionDate() {
         let date = new Date();
         const oneDay = 1;
         date.setDate(date.getDate() + this.subscription.free_trial_days + oneDay);
         return `${this.monthNames[date.getMonth()]}, ${date.getDate()} ${date.getFullYear()}`;
      },

      finalPrice() {
         return this.subscription.price - this.youSave;
      },
   },
   methods: {
      pay() {
         this.$http.put('resources/payment/create', this.payment);
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.buy-banner
   padding: 0 10px
   & > *
      padding-bottom: 10px
      margin-bottom: 10px
      border-bottom: 1px solid #f1f1f1
      &:last-child
         border-bottom: none

   &__header
      display: flex
      align-items: center

   &__icon
      max-width: 30px
      margin-right: 10px
      border-radius: 10%
      display: inline-flex
      justify-content: center
      align-items: center
      flex-direction: column

      & > img
         border-radius: 10%
         width: 100%

   &__title
      margin-bottom: 5px

   &__chargers
      &-box
         display: flex
         align-items: center

      &-list
         display: grid
         row-gap: 5px
         width: 100%

      &-item
         display: grid
         grid-template-columns: repeat(2, 1fr)
         column-gap: 5px

         & > dd
            align-self: end
            text-align: right
      &-price
         position: relative

         &_line
            text-decoration: line-through
            text-decoration-color: red
         &_red
            color: red
         &_bold
            font-weight: 700

.form__input
   display: flex
   margin-bottom: 10px

   & input
      margin-left: 20px
      border: 1px solid #ccc

.form button
   border: 1px solid #ccc
   padding: 0.5em 0.5em

.subscription__btn
   width: 100%
   text-align: center

.list-with-dots
   padding-left: 18px
   list-style-type: disc

#sepia .buy-banner
   & > *
      bordr-color: $app-sepia-border
</style>