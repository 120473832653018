<template>
   <div class="welcome" :style="{ paddingTop: 30 + fontSize + 'px' }">
      <AdviceHeader ref="header" v-if="$route.name !== 'app-instructions'" />
      <transition name="edit">
         <div class="editText" v-if="isEditMode">
            <AdviceText>{{ editingText }}</AdviceText>
         </div>
      </transition>
      <transition name="fade" mode="out-in" v-if="profile">
         <router-view></router-view>
      </transition>
      <Spinner v-if="isLoading" />
   </div>
</template>

<script>
import AdviceHeader from '../../components/AdviceComponents/AdviceHeader.vue';
import AdviceText from '../../components/AdviceComponents/AdviceText';
import Spinner from '../../components/UI/Spinner.vue';
import actions from '../../store/action-types';
import { isIOS, isSafari } from '../../store/isDevices';
import remoteOrigin from '../../store/remoteOrigin';

export default {
   components: {
      AdviceHeader,
      Spinner,
      AdviceText,
   },

   provide() {
      return {
         openBuyModal: this.openBuyModal,
      };
   },

   data() {
      return {
         modalOpen: false,
         modalSecondOpen: false,
         subscriptionOpen: false,
         headerHeigth: 0,
      };
   },

   computed: {
      isMainPage() {
         return this.$route.name === 'app-main-all';
      },

      profile() {
         return this.$store.getters['profile/getProfile'];
      },

      isLoading() {
         return this.$store.getters['advice/getLoading'];
      },

      advices() {
         return this.$store.getters['advice/getAdvices'];
      },

      fontSize() {
         return Number(this.$store.getters['advice/getCurrentFontSize']);
      },

      isEditMode() {
         return this.$store.getters['advice/getEditMode'];
      },

      isHiddenMode() {
         return this.$store.getters['advice/getHiddenMode'];
      },

      editingText() {
         if (this.isEditMode && !this.isHiddenMode) {
            return this.$t('Advice.editActive');
         } else if (this.isEditMode && this.isHiddenMode) {
            return this.$t('Advice.editHidden');
         } else {
            return '';
         }
      },
   },

   mounted() {
      if (navigator.serviceWorker.controller) {
         navigator.serviceWorker.controller.postMessage({
            type: 'setDevices',
            isIOS: isIOS,
            isSafari: isSafari,
         });
      }
   },

   watch: {
      profile() {
         if (sessionStorage.modalClose) return;
         if (!this.profile.Disturb && !this.profile.gender && !this.profile.birthday) {
            this.$router.push({ name: 'app-data' });
         } else if (!this.profile.DisturbSecond) {
            this.$router.push({ name: 'app-instructions' });
            this.modalSecondOpen = true;
         }
         sessionStorage.modalClose = true;
      },

      advices() {
         if (!sessionStorage.audioLoaded && !isIOS && !isSafari) {
            this.loadAudio();
         }
      },
   },

   created() {
      this.$store.dispatch(`profile/${actions.FETCH}`);
      this.$store.dispatch(`advice/${actions.FETCH}`);
   },

   methods: {
      closeModalFirst() {
         this.modalOpen = false;
         this.modalSecondOpen = true;
      },

      closeModalSecond() {
         this.modalSecondOpen = false;
         this.$store.dispatch('profile/updateProfile');
      },

      openBuyModal() {
         this.subscriptionOpen = true;
      },

      loadAudio() {
         let audio = [];
         this.advices.map(element => {
            if (element.media) {
               audio.push(new Audio(remoteOrigin + element.media));
            }
         });
         sessionStorage.audioLoaded = true;
      },
   },
};
</script>

<style lang="sass" scoped>
.welcome
   height: 100%
   padding-top: 50px

.editText
   max-width: 1300px
   padding-left: 8px
   margin: 0 auto
   font-weight: bold

.edit-enter
   opacity: 0
   transform: translateX(100%)

.edit-enter-active
   transition: all 0.3s ease

.edit-leave-active
   transition: all 0.3s ease
   opacity: 0
   transform: translateX(100%)
</style>