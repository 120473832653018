<template>
   <label for="number">
      <span>{{ label }}</span>
      <div class="icon">
         <input
            type="tel"
            name="phone"
            autocomplete="phone"
            id="number"
            placeholder="+1 (900) 000-0000"
            ref="inputField"
            maxlength="13"
            @blur="loseFocus"
            v-model="userInput"
            @input="validatePhone"
         />
      </div>
   </label>
</template>

<script>
export default {
   props: {
      needValidation: {
         type: Boolean,
         default: true,
      },
      label: {
         type: String,
      },
   },

   data() {
      return {
         userInput: '',
      };
   },

   computed: {
      isInputValid() {
         const regexp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

         return new RegExp(regexp).test(this.userInput);
      },
   },

   watch: {
      userInput() {
         this.$emit('userInput', this.userInput);
         this.$emit('isValid', this.isInputValid);
         this.$refs['inputField'].classList.remove('error');
      },
   },

   methods: {
      loseFocus() {
         if (this.isInputValid) {
            return;
         }
         if (this.userInput.length === 1) {
            this.userInput = '';
         }
         if (this.needValidation) {
            this.$refs['inputField'].classList.add('error');

            let removeError = setTimeout(() => {
               this.$refs['inputField'].classList.remove('error');
               clearTimeout(removeError);
            }, 2000);
         }
      },

      validatePhone() {
         this.userInput = this.userInput.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
         this.userInput = '+' + this.userInput;
      },
   },
};
</script>

<style lang="sass" scoped>
label
   width: 100%
   display: block

   & .icon
      position: relative
      margin-top: 0.5rem

   & .icon::before
      content: url('../../assets/img/phone-call.svg')
      position: absolute
      top: 50%
      left: 1rem
      width: 20px
      height: 20px
      transform: translateY(-50%)

   & span
      text-transform: uppercase
      font-size: 0.8rem
      letter-spacing: 0.04em

   & > *
      display: block

input
   width: 100%
   border: 1px solid #ccc
   border-radius: 4px
   padding: 1rem 1rem 1rem 3rem
   font-size: 1rem

.error
   border: 1px solid red !important
</style>