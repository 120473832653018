<template>
   <div class="themes">
      <div class="themes__icon">
         <svg fill="red" width="20" height="20">
            <svg><use xlink:href="../../assets/img/icons.svg#moon-icon"></use></svg>
         </svg>
      </div>
      <div class="themes__content">
         <p class="themes__title">
            <AdviceText :smaller="true">{{ $t('Profile.themeLabel') }}</AdviceText>
         </p>
         <div class="themes__box" @change="changeTheme">
            <label for="themeSepia" ref="sepia" class="themes__theme sepia">
               <input type="radio" id="themeSepia" name="theme" value="sepia" />
            </label>

            <label for="white" ref="white" class="themes__theme white">
               <input type="radio" id="white" name="theme" value="white" />
            </label>

            <label for="dark" ref="dark" class="themes__theme dark">
               <input type="radio" id="dark" name="theme" value="dark" />
            </label>
         </div>
      </div>
   </div>
</template>

<script>
import AdviceText from '../AdviceComponents/AdviceText.vue';
export default {
   inject: ['setCurrentTheme'],

   components: {
      AdviceText,
   },

   data() {
      return {
         currentTheme: localStorage.theme ? localStorage.theme : 'white',
         themes: ['white', 'sepia', 'dark'],
      };
   },

   mounted() {
      localStorage.theme
         ? this.$refs[localStorage.theme].classList.add('active')
         : this.$refs.white.classList.add('active');
   },

   methods: {
      changeTheme(e) {
         if (this.currentTheme === e.target.value) {
            return;
         }

         this.currentTheme = e.target.value;
         localStorage.theme = this.currentTheme;
         this.setCurrentTheme();
         for (let theme of this.themes) {
            this.currentTheme === theme
               ? this.$refs[this.currentTheme].classList.add('active')
               : this.$refs[theme].classList.remove('active');
         }
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'
.themes
   display: flex
   align-items: center

   &__icon
      width: 25px
      height: 25px
      margin-right: 20px
      & svg
         width: 100%
         height: 100%
         fill: #76c4ba

   &__content
      width: fit-content

   &__title
      margin-bottom: 10px

   &__box
      height: 40px
      display: flex
      align-items: center

   &__theme
      width: 30px
      height: 30px
      border-radius: 100%
      border: 2px solid
      transition: all 0.3s
      margin: 0 10px

      & input
         position: relative
         transform: translateX(-1000px)
         opacity: 0

   & .white
      background-color: #fff
      border-color: darken(#fff, 20%)

   & .dark
      background-color: lighten($app-dark-background, 25%)
      border-color: lighten($app-dark-background, 20%)

   & .sepia
      background-color: $app-sepia-background
      border-color: darken($app-sepia-background, 20%)

   & .active
      width: 40px
      height: 40px

@media (max-width: 600px)
   .themes
      &__icon
         margin-right: 10px

      &__theme
         width: 28px
         height: 28px
         margin: 0 5px

      & .active
         width: 33px
         height: 33px
</style>