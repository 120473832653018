<template>
   <ProfileItem
      :labelText="labelText"
      :labelEditText="labelEditText"
      :itemImg="itemImg"
      :variableName="variableName"
      :currentValue="currentValue"
      :showInput="true"
   >
      <template>
         <AdviceText>
            <select v-model="value" :name="variableName" :id="variableName" class="item__value item__input">
               <option v-if="!options.includes(value)" value="default" selected disabled>
                  {{ labelEditText }}
               </option>
               <option
                  v-for="option in options"
                  :key="option"
                  :value="option.toLowerCase()"
                  :selected="option.toLowerCase() === value.toLowerCase()"
               >
                  {{ option }}
               </option>
            </select>
         </AdviceText>
      </template>
   </ProfileItem>
</template>

<script>
import mutations from '../../store/mutation-types';
import AdviceText from '../AdviceComponents/AdviceText.vue';
import ProfileItem from './ProfileItem.vue';

export default {
   components: {
      AdviceText,
      ProfileItem,
   },

   props: {
      labelText: {
         type: String,
         required: true,
      },

      labelEditText: {
         type: String,
         required: true,
      },

      currentValue: {
         type: String,
         default: 'Enter your data',
      },

      options: {
         type: Array,
         required: true,
      },

      itemImg: {
         type: String,
      },

      variableName: {
         type: String,
         required: true,
      },

      function: {
         type: Function,
      },
   },

   data() {
      return {
         value: this.currentValue ? this.currentValue : 'default',
      };
   },

   watch: {
      value() {
         if (this.function) {
            this.function(this.value);
         } else {
            this.$store.commit(`profile/${mutations.UPDATE_PROFILE}`, { [this.variableName]: this.value });
            this.$store.dispatch(`profile/updateProfile`);
         }
      },
   },

   methods: {
      updateProfileItemEditing() {
         this.$store.commit(`profile/${mutations.PROFILE_ITEM_EDITING}`, true);
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.item
   display: flex
   align-items: center

   &__icon
      width: 40px

      & img
         width: 20px

   &__box
      width: 97%
      position: relative
      border-bottom: 1px solid #f1f1f1
      padding: 15px 0

   &__value
      font-weight: 500
      text-transform: capitalize

   &__input
      width: 90%

      &:first-letter
         text-transform: capitalize

   &__edit-btn
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)

#dark .item, #dark select
   color: $app-dark-font-color

#sepia .item__box
   border-bottom: 1px solid $app-sepia-border
</style>