<template>
   <router-view></router-view>
</template>

<script>
export default {
   mounted() {
      const verify_token = this.$route.query.verify_token;
      if (verify_token) {
         this.$http
            .post('auth/forgot/check/email', { code: verify_token })
            .then(response => {
               if (response.status === 202) {
                  this.$router.push({ name: 'app-forgot-reset', params: { code: verify_token } });
               }
            })
            .catch(() => {
               alert('Wrong code');
            });
      }
   },
};
</script>