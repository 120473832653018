<template>
   <div></div>
</template>

<script>
export default {
   mounted() {
      this.$router.push({ name: 'app-main-all' });
   },
};
</script>

<style>
</style>