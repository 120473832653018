<template>
   <div class="contauner">
      <div class="root__box"><ImprovePrompt /></div>
   </div>
</template>

<script>
import ImprovePrompt from '../ImprovePrompt.vue';

export default {
   components: {
      ImprovePrompt,
   },
   props: {},
   data() {
      return {
         isModal: false,
      };
   },
   methods: {
      openModal() {
         this.isModal = true;
      },
   },
};
</script>

<style scoped></style>
