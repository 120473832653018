<template>
   <div class="overlay">
      <div class="spinner">
         <div class="lds-circle"><div></div></div>
      </div>
   </div>
</template>

<script>
export default {};
</script>

<style scoped>
.overlay {
   height: 100%;
   width: 100%;
   position: fixed;
   top: 0;
   left: 0;
   background-color: rgba(000, 000, 000, 0.1);
}

.spinner {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.lds-circle {
   display: inline-block;
   transform: translateZ(1px);
}
.lds-circle > div {
   display: inline-block;
   width: 64px;
   height: 64px;
   margin: 8px;
   border-radius: 50%;
   background: #92d076;
   animation: lds-circle 5.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes lds-circle {
   0%,
   100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
   }
   0% {
      transform: rotateY(0deg);
   }
   50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
   }
   100% {
      transform: rotateY(3600deg);
   }
}
</style>