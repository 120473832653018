//import { authUser } from '../middleware/isAuth';

export const advicesRouter = [
   {
      path: 'profiles',
      name: 'app-profile',
      component: () => import('../../views/AdvicesApp/TheProfile.vue'),
      meta: {
         /*  middleware: [
            authUser
         ] */
      },
   },
   {
      path: '/auth/google',
      name: 'auth-google',
      component: () => import('../../views/TheAuthGoogle.vue'),
   },
   {
      path: '/auth/facebook',
      name: 'auth-facebook',
      component: () => import('../../views/TheAuthFacebook.vue'),
   },
   {
      path: '/auth/twitter',
      name: 'auth-twitter',
      component: () => import('../../views/TheAuthTwitter.vue'),
   },
   {
      path: '/login',
      name: 'app-login',
      component: () => import('../../views/AdvicesApp/TheLogin.vue'),
      meta: { doNotComeBack: true },
   },
   {
      path: '/forgot',
      name: 'app-forgot',
      component: () => import('../../views/Login/TheForgot.vue'),
      children: [
         {
            path: 'new-password',
            name: 'app-forgot-reset',
            component: () => import('../../views/Login/TheForgotPasswordReset.vue'),
         },
      ],
   },
   {
      path: '/modal',
      name: 'modal',
      component: () => import('../../components/UI/CallModal.vue'),
   },
   {
      path: '/contentmodal',
      name: 'content-modal',
      component: () => import('../../components/ImprovePrompt.vue'),
   },
   {
      path: '/layoutApplication',
      name: 'layoutApplication',
      component: () => import('../../components/Layouts/LayoutAplication.vue'),
   },

   {
      path: '/advice',
      name: 'advice',
      component: () => import('../../components/Layouts/LayoutAplication.vue'),
   },
   {
      path: '/habits',
      name: 'habits',
      component: () => import('../../components/Layouts/LayoutAplication.vue'),
   },
   {
      path: '/setting',
      name: 'setting',
      component: () => import('../../components/Layouts/LayoutAplication.vue'),
   },
   {
      path: '',
      name: 'app-main',
      component: () => import('../../views/AdvicesApp/TheMain.vue'),
      redirect: { name: 'app-main-all-r' },
      children: [
         {
            path: '',
            name: 'app-main-all-r',
            component: () => import('../../views/AdvicesApp/Back.vue'),
            meta: {
               /*  middleware: [
                  authUser
               ]*/
            },
         },
         {
            path: 'all',
            name: 'app-main-all',
            component: () => import('../../views/AdvicesApp/AdvicesCategories.vue'),
            meta: {
               /*   middleware: [
                  authUser
               ] */
            },
         },
         {
            path: '/data',
            name: 'app-data',
            component: () => import('../../views/AdvicesApp/TheDataUser.vue'),
            meta: {
               /*   middleware: [
                  authUser
               ] */
            },
         },
         {
            path: '/instructions',
            name: 'app-instructions',
            component: () => import('../../views/AdvicesApp/TheInstructions.vue'),
            meta: {
               /*   middleware: [
                  authUser
               ] */
            },
         },
         {
            path: ':id',
            name: 'app-main-category',
            component: () => import('../../views/AdvicesApp/AdviceCategoryAdvicesAndSubcategories.vue'),
            meta: {
               /*   middleware: [
                  authUser
               ] */
            },
         },

         {
            path: 'subcategory/:id',
            name: 'app-main-subcategory',
            component: () => import('../../views/AdvicesApp/AdviceCategoriesSubcategory.vue'),
            meta: {
               /*  middleware: [
                  authUser
               ] */
            },
         },
      ],
   },
];
