<template>
   <div class="profile-img">
      <div class="profile-img__box">
         <img class="profile-img__img" ref="profileImg" :src="imgLink || image" alt="" />
      </div>
      <label class="profile-img__upload" type="button" @click="isTooLarge = false">
         <img src="../../assets/img/advices/profile-img-camera.svg" alt="camera" />
         <input style="display: none" type="file" accept="image/*, .heic, .heif" @change="readFileUrl" />
      </label>
      <transition name="fade">
         <AdviceText class="error-size" :smallerOn="5" v-if="isTooLarge">{{ $t('Profile.imageLarge') }}</AdviceText>
      </transition>
   </div>
</template>

<script>
import actions from '../../store/action-types';
import mutations from '../../store/mutation-types';
import AdviceText from '../AdviceComponents/AdviceText.vue';
import remoteOrigin from '../../store/remoteOrigin';
export default {
   MAX_FILE_SIZE: 1000000,

   components: {
      AdviceText,
   },

   props: {
      imgLink: {
         type: String,
      },
   },

   data() {
      return {
         isEditModeEnable: false,
         file: '',
         isTooLarge: false,
      };
   },
   computed: {
      image() {
         return require('../../assets/img/advices/default-profile.png');
      },
      remoteOrigin() {
         return remoteOrigin;
      },
   },

   methods: {
      changeProfileImg(img) {
         this.$refs.profileImg.src = img;
      },

      async readFileUrl(e) {
         this.$store.commit(`advice/${mutations.UPDATE_LOADING}`, true);
         const file = e.target.files[0];
         if (file.size >= this.$options.MAX_FILE_SIZE) {
            this.isTooLarge = true;
            this.$store.commit(`advice/${mutations.UPDATE_LOADING}`, false);
            return;
         }
         const formData = new FormData();
         formData.append('img', file);
         this.$http
            .put('resources/profile', formData, {
               headers: {
                  'Content-Type': 'multipart/form-data',
               },
            })
            .then(() => {
               this.$store.commit(`advice/${mutations.UPDATE_LOADING}`, false);
               this.$store.dispatch(`profile/${actions.FETCH}`);
            })
            .catch(() => {
               this.isTooLarge = true;
               this.$store.commit(`advice/${mutations.UPDATE_LOADING}`, false);
            });
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.profile-img
   max-width: 165px
   width: 100%
   height: 155px
   border: 2px solid #ccc
   margin: 0 auto
   border-radius: 50%
   position: relative

   &__box
      display: inline-flex
      align-items: center
      justify-content: center
      height: 100%
      width: 100%

   &__img
      max-width: 165px
      width: 100%
      max-height: 160px
      border-radius: 50%

   &__upload
      width: 45px
      -webkit-appearance: none
      height: 45px
      position: absolute
      bottom: 0
      right: 0
      background-color: $app-main-color
      border-radius: 100%
      text-align: center

      & img
         height: 100%
         width: 50%

   & .error-size
      text-align: center
      color: red
</style>