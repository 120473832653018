import mutations from '../mutation-types';
import actions from '../action-types';
import http from '../../plugins/http';
import router from '../../router/router';
import { END_OF_TRIAL_SUBSCRIPTION, JWT_EXPIRED, JWT_MUST_PROVIDED } from '../apiErrors';
const store = {
   namespaced: true,

   state: {
      title: 'Sleep Well',
      editMode: false,
      categories: [],
      tempUnvisible: [],
      hiddenCategories: [],
      advices: [],
      subcategories: [],
      hiddenSubcategories: [],
      currentSubcategories: {},
      subcategory: {},
      category: {},
      fontSize: '',
      isDataLoading: false,
      search: '',
      play: false,
      idOfPlayingAdvice: '',
      advicesHidden: [],
      isModalOpen: false,
      editItems: [],
      unvisible: [],
      isCategoriesLoaded: false,
      isSerach: false,
      isHiddenMode: false,
      isConfirmAdvices: false,
      isPlayerPlaying: false,
      updateScroll: true,
      playerIndex: null,
      isPlayDelimeter: false,
   },

   getters: {
      getCategories(state) {
         if (state.search) {
            let filteredByAdvices = state.categories.filter(item =>
               Boolean(item?.text?.toLowerCase().match(state.search)),
            );
            let filteredCategories = state.categories.filter(item =>
               filteredByAdvices.some(advice => advice.parent === item.code && !item.parent),
            );
            return filteredCategories;
         }
         return state.categories.filter(item => !item.parent && item.title);
      },

      getIsConfirmAdvices: state => state.isConfirmAdvices,

      getHiddenMode: state => state.isHiddenMode,

      getAllCategories: state => state.categories,

      getCategoriesFilterSearch(state) {
         return state.categories.filter(item => Boolean(item?.text?.toLowerCase().match(state.search)));
      },

      getHiddenCategories: state => state.hiddenCategories.filter(item => !item.parent && item.title),

      getCategory: state => state.category,

      getSubcategories: state => {
         if (state.search) {
            let subcategoriesChilds = state.categories.filter(({ parent, text }) =>
               state.subcategories.some(({ code }) => {
                  return parent === code && Boolean(text?.toLowerCase().match(state.search));
               }),
            );
            let filteredSubcategories = state.categories.filter(({ code }) =>
               subcategoriesChilds.some(({ parent }) => code === parent),
            );
            return filteredSubcategories;
         }
         return state.subcategories;
      },

      getHiddenSubcategories: state => state.hiddenSubcategories,

      getAdvices: state => {
         if (state.search) {
            return state.advices.filter(({ text }) => Boolean(text.toLowerCase().match(state.search)));
         }
         return state.advices;
      },

      getHiddenAdvices: state => {
         if (state.search) {
            return state.advicesHidden.filter(({ text }) => Boolean(text.toLowerCase().match(state.search)));
         }
         return state.advicesHidden;
      },

      getTitle: state => state.title,

      getEditMode: state => state.editMode,

      getCurrentFontSize: state => state.fontSize,

      getLoading: state => state.isDataLoading,

      getPlay: state => state.play,

      getIdOfPlayingAdvice: state => state.idOfPlayingAdvice,

      getIsModalOpen: state => state.isModalOpen,

      getAdvicesToPlay: state => {
         if (state.search) {
            return state[state.isHiddenMode ? 'advicesHidden' : 'advices'].filter(({ text }) =>
               Boolean(text.toLowerCase().match(state.search)),
            );
         } else {
            return state[state.isHiddenMode ? 'advicesHidden' : 'advices'];
         }
      },

      getEditItems: state => state.editItems,

      getUnvisible: state => state.unvisible,

      getIsCategoriesLoaded: state => state.isCategoriesLoaded,

      getIsSearch: state => state.isSerach,

      getIsPlayerPlaying: state => state.isPlayerPlaying,

      getIsUpdatedScroll: state => state.updateScroll,

      getPlayerIndex: state => state.playerIndex,

      getIsPlayDelimeter: state => state.isPlayDelimeter,
   },

   mutations: {
      [mutations.FIND_CATEGORY](state, id) {
         state.category = state.categories.filter(item => item._id == id)[0];
      },

      [mutations.SET_HIDDEN_MODE](state, payload) {
         state.isHiddenMode = payload;
      },

      [mutations.FIND_SUBCATEGORIES](state, parentCode) {
         state.subcategories = state.categories.filter(item => item.parent === parentCode && !item.text);
         state.hiddenSubcategories = state.hiddenCategories.filter(item => item.parent === parentCode && !item.text);
      },

      [mutations.FIND_ADVICES](state, parentCode = null) {
         if (!parentCode) {
            state.advices = state.categories.filter(item => item.text);
         } else {
            state.advices = state.categories.filter(item => item.parent === parentCode && item.text);
            state.advicesHidden = state.hiddenCategories.filter(item => item.parent === parentCode && item.text);
         }
      },

      [mutations.UPDATE_TITLE](state, title) {
         state.title = title;
      },

      [mutations.UPDATE_EDITE_MODE](state, isEnable) {
         state.editMode = isEnable;
      },

      [mutations.UPDATE_THEME](state, theme) {
         state.theme = theme;
      },

      [mutations.UPDATE_FONT_SIZE](state, font) {
         state.fontSize = font;
      },

      [mutations.UPDATE_CATEGORIES](state, data) {
         state.categories = [...data];
      },

      [mutations.UPDATE_HIDDEN_CATEGORIES](state, data) {
         state.hiddenCategories = data;
      },

      [mutations.UPDATE_LOADING](state, data) {
         state.isDataLoading = data;
      },

      [mutations.UPDATE_SEARCH](state, data) {
         state.search = data;
      },

      [mutations.SET_IS_SEARCH](state, data) {
         state.isSerach = data;
      },

      [mutations.UPDATE_PLAY](state, data) {
         state.play = data;
      },

      [mutations.UPDATE_IS_PLAYER](state, data) {
         state.isPlayerPlaying = data;
      },

      [mutations.SET_ID_OF_PLAYING_ADVICE](state, id) {
         state.idOfPlayingAdvice = id;
      },

      [mutations.UPDATE_UNVISIBLE](state, ids) {
         state.unvisible = ids;
      },

      [mutations.REMOVE_UNVISIBLE](state, ids) {
         let clearIds = [...state.unvisible];
         for (let id of ids) {
            if (clearIds.includes(id)) {
               clearIds.splice(clearIds.indexOf(id), 1);
            }
         }
         state.unvisible = clearIds;
      },

      [mutations.SET_IS_MODAL_OPEN](state, payload) {
         state.isModalOpen = payload;
      },

      [mutations.UPDATE_EDIT_ITEMS](state, payload) {
         state.editItems = [...payload];
      },

      updateCategoriesLoaded(state, payload) {
         state.isCategoriesLoaded = payload;
      },

      [mutations.SAVE_UNVISIBLE_TO_TEMP](state) {
         state.tempUnvisible = [...state.unvisible];
      },

      [mutations.SET_IS_CONFIRM_ADVICES](state, payload) {
         if (
            state.unvisible.length === state.tempUnvisible.length &&
            state.unvisible.every((val, index) => val === state.tempUnvisible[index])
         ) {
            state.isConfirmAdvices = false;
         } else {
            state.isConfirmAdvices = payload;
         }
      },

      updateScroll(state, payload) {
         state.updateScroll = payload;
      },

      [mutations.UPDATE_PLAYER_INDEX](state, payload) {
         state.playerIndex = payload;
      },

      [mutations.PLAY_DELIMETER](state, payload) {
         state.isPlayDelimeter = payload;
      },
   },

   actions: {
      async [actions.FETCH]({ commit }) {
         commit(mutations.UPDATE_LOADING, true);
         commit('updateCategoriesLoaded', false);
         try {
            let data = await http.post('resources/categories');
            commit(mutations.UPDATE_CATEGORIES, data.data.visible);
            commit(mutations.UPDATE_HIDDEN_CATEGORIES, data.data.unvisible);
            commit(mutations.FIND_ADVICES);
            let idsUnvisible = data.data.unvisible.map(item => item._id);
            commit(mutations.UPDATE_UNVISIBLE, idsUnvisible);
         } catch (err) {
            if (
               err?.data?.message?.message?.message === JWT_EXPIRED ||
               err?.data?.message?.message?.message === JWT_MUST_PROVIDED
            ) {
               //router.push({ name: 'app-login' });
            } else if (err?.data?.message === END_OF_TRIAL_SUBSCRIPTION) {
               router.push({ name: 'app-profile', params: { isOpenSubscription: true } });
            } else {
               console.log(err);
            }
         } finally {
            commit('updateCategoriesLoaded', true);
            commit(mutations.UPDATE_LOADING, false);
         }
      },

      async updateUnvisible({ state, commit, dispatch }, unvisible) {
         commit(mutations.UPDATE_UNVISIBLE, unvisible);
         const response = await http.put('resources/profile/unvisible', { unvisible: state.unvisible });
         if (response.status === 200) {
            if (navigator.serviceWorker.controller) {
               navigator.serviceWorker.controller.postMessage({
                  type: 'clearCategories',
               });
            }
            dispatch(actions.FETCH);
         }
      },

      updateData({ commit, state }) {
         if (router.history.current.name === 'app-main-subcategory') {
            commit(mutations.FIND_CATEGORY, router.history.current.params.id);
            commit(mutations.UPDATE_TITLE, state.category.title);
            commit(mutations.FIND_ADVICES, state.category.code);
         } else if (router.history.current.name === 'app-main-category') {
            commit(mutations.FIND_CATEGORY, router.history.current.params.id);
            commit(mutations.UPDATE_TITLE, state.category.title);
            commit(mutations.FIND_ADVICES, state.category.code);
            commit(mutations.FIND_SUBCATEGORIES, state.category.code);
         } else {
            commit(mutations.UPDATE_TITLE, 'Sleep Well');
            commit(mutations.FIND_ADVICES);
         }
      },

      doNotSaveAdvices({ state, dispatch }) {
         dispatch('updateUnvisible', state.tempUnvisible);
      },
   },
};

export default store;
