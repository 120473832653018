<template>
   <div>
      <button :class="classes" ref="item" @click.prevent="handleClick">
         <AdviceText class="item__title">{{ item.title }}</AdviceText>
         <AdviceText class="item__advices" :smallerOn="5" v-if="advicesFountBySerachLength > 0"
            >Found advices: {{ advicesFountBySerachLength }}</AdviceText
         >
      </button>
   </div>
</template>

<script>
import AdviceText from '../AdviceComponents/AdviceText.vue';
import mutations from '../../store/mutation-types';
export default {
   components: {
      AdviceText,
   },

   props: {
      item: {
         type: Object,
         required: true,
      },

      isEditModeEnable: {
         type: Boolean,
      },

      isSubCategory: {
         type: Boolean,
         default: false,
      },

      advicesFountBySerachLength: {
         type: Number,
         default: 0,
      },

      isHidden: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      classes() {
         let classes = [];
         if (this.isSubCategory) {
            classes.push('item-advice');
         } else {
            classes.push('item');
         }
         if (this.isHidden) {
            classes.push('hidden');
         }
         return classes;
      },
   },

   watch: {
      isEditModeEnable() {
         if (!this.isEditModeEnable) this.$refs.item.classList.remove('checked');
      },
   },

   methods: {
      handleClick() {
         this.isEditModeEnable
            ? (this.$emit('editItemsUpdate', this.item._id), this.$refs.item.classList.toggle('checked'))
            : this.goToSignleCategory();
      },

      goToSignleCategory() {
         this.isSubCategory
            ? this.$router.push({ name: 'app-main-subcategory', params: { id: this.item._id } })
            : this.$router.push({ name: 'app-main-category', params: { id: this.item._id } });

         this.$store.commit(`advice/${mutations.UPDATE_TITLE}`, this.item.title);
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.item
   display: block
   cursor: pointer
   width: 100%
   border: 1px solid darken(#f1f1f1, 5%)
   padding: 15px 30px
   box-shadow: 0 0 8px #f1f1f1
   margin: 5px 0
   transition: all 0.3s

   &:active
      background-color: darken(#fff, 5%)
      padding: 20px 30px
      margin: 8px 0

      border-top: 1px solid darken(#f1f1f1, 2%)
      border-bottom: 1px solid darken(#f1f1f1, 2%)

   &__title
      cursor: pointer
      font-size: 1.4rem

.item__advices
   margin-top: 5px

.item.checked
   background-color: darken(#fff, 0.5%)
   padding: 18px 30px
   margin: 8px 0

   position: relative

   &:active
      padding: 22px 30px
      margin: 8px 0

   &::after
      content: ''
      position: absolute
      right: 20px
      top: 50%
      width: 20px
      height: 20px
      border: 2px solid #ccc
      border-radius: 100%
      background-color: lighten($app-secondary-color, 20%)
      transform: translate(0, -50%)

.item-advice
   width: 100%
   border: 1px solid #f1f1f1
   padding: 15px 30px
   box-shadow: 0 0 8px #f1f1f1
   margin-bottom: 10px
   text-align: left
   transition: all 0.3s

   & .item__title
      font-weight: bold

   & p
      cursor: pointer
      font-size: 1.1rem
      font-weight: 500

.item-advice.checked
   background-color: darken(#fff, 0.5%)
   margin-bottom: 12px

   position: relative

   &:active
      margin-top: 6px

   &::after
      content: ''
      position: absolute
      right: 5px
      top: 50%
      width: 20px
      height: 20px
      border: 2px solid #ccc
      border-radius: 100%
      background-color: lighten($app-secondary-color, 20%)
      transform: translate(0, -50%)

.hidden
   background-color: darken(white, 3%)

#sepia .item.checked, #sepia .item-advice.checked
   background-color: darken($app-sepia-background, 0.8%)

#sepia .item, #sepia .item-advice
   border: 1px solid $app-sepia-border
   box-shadow: 0 0 8px lighten($app-sepia-border, 30%)

   &:active
      background-color: darken($app-sepia-background, 0.8%)

#dark .item.checked, #dark .item-advice.checked
   background-color: darken($app-dark-background, 0.8%)
   color: $app-dark-font-color

#dark .item, #dark .item-advice
   border: 1px solid darken(#f1f1f1, 5%)

   &:active
      background-color: darken($app-dark-background, 0.8%)
   & p
      color: $app-dark-font-color

   &::after
      background-color: lighten($app-secondary-color, 0)

#sepia .hidden
   background-color: darken($app-sepia-background, 2%)

#dark .hidden
   background-color: darken($app-dark-background, 2%)

@media (max-width: 600px)
   .item, .item-advice
      padding: 2px 15px
</style>