<template>
   <div class="search">
      <div class="search__icon search__search">
         <svg>
            <use xlink:href="../../assets/img/icons.svg#search-header"></use>
         </svg>
      </div>

      <input v-model="search" type="text" placeholder="Type here to find advices..." />

      <button @click.stop="clearSearch" class="search__icon search__clear">
         <svg><use xlink:href="../../assets/img/icons.svg#cross-icon"></use></svg>
      </button>
   </div>
</template>

<script>
import mutations from '../../store/mutation-types';
export default {
   data() {
      return {
         search: '',
      };
   },

   watch: {
      search() {
         this.search = this.search.replace(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, '');
         if (this.search.length > 0) {
            this.$store.commit(`advice/${mutations.SET_IS_SEARCH}`, true);
         } else {
            this.$store.commit(`advice/${mutations.SET_IS_SEARCH}`, false);
         }
         this.$store.commit(`advice/${mutations.UPDATE_SEARCH}`, this.search.toLowerCase());
      },
   },

   methods: {
      clearSearch() {
         this.search = '';
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'
.search
   width: 100%
   position: relative
   height: 100%
   border: 2px solid #ccc
   border-radius: 20px
   background-color: #fff
   display: inline-flex
   align-items: center
   padding: 5px 30px

   & input
      font-size: 14px
      width: 100%

   &__icon
      position: absolute
      top: 50%
      transform: translateY(-50%)

   &__search
      width: 15px
      height: 15px
      left: 8px

      & svg
         fill: #ccc
         width: inherit
         height: inherit

   &__clear
      height: 100%
      width: 30px
      color: #ccc
      right: 3px
      display: inline-flex
      justify-content: center
      align-items: center

      & svg
         width: 14px
         fill: #ccc
         height: 14px
</style>