<template>
   <button type="submit" class="btn" :disabled="!isDisabled">
      <div>
         <span><slot></slot></span>
         <div class="lds-ring" v-if="isLoading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   </button>
</template>

<script>
export default {
   props: {
      isLoading: {
         type: Boolean,
         default: false,
      },

      isDisabled: {
         type: Boolean,
         default: false,
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.btn
   display: block
   border: 1px solid #ccc
   padding: 10px
   margin: 0 auto 0
   max-width: 200px
   width: 100%
   cursor: pointer
   transition: all 0.2s

   & > div
      display: flex
      justify-content: space-around
      align-items: center

   &:disabled
      cursor: not-allowed
      color: #ccc

   &:hover:not(&:disabled)
      background-color: lighten(#ccc, 10%)

   &:active
      background-color: lighten(#ccc, 5%) !important

.lds-ring
   display: inline-block
   position: relative
   width: 20px
   height: 20px

   & div
      box-sizing: border-box
      display: block
      position: absolute
      width: 20px
      height: 20px
      border: 4px solid $app-main-color
      border-radius: 50%
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
      border-color: $app-main-color transparent transparent transparent

   & div:nth-child(1)
      animation-delay: -0.45s

   & div:nth-child(2)
      animation-delay: -0.3s

   & div:nth-child(3)
      animation-delay: -0.15s

@keyframes lds-ring
   0%
      transform: rotate(0deg)

   100%
      transform: rotate(360deg)
</style>