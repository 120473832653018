<template>
   <div>
      <label for="checkbox">
         <input type="checkbox" name="checkbox" id="checkbox" @change="updateValue" />
         <p><slot></slot></p>
      </label>
   </div>
</template>

<script>
export default {
   methods: {
      updateValue(e) {
         this.$emit('changed', e.target.checked);
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

label
   display: flex
   align-items: center

   & p
      font-size: 1.1rem
      margin-left: 5px
      color: darken($app-secondary-color, 20%)
</style>