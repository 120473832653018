<template>
   <AdviceModal @closeModal="closeModal">
      <div class="welcome__box">
         <div class="welcome__greet">
            <AdviceText :biggerOn="6">{{ $t('Welcome.welcomeText') }}</AdviceText>
         </div>
         <div class="welcome__data">
            <div class="welcome__item">
               <label for="birthday">{{ $t('Profile.birthdayEditLabel') }}</label>
               <div style="z-index: 200000">
                  <DatePicker
                     :input-attr="{ id: 'birthday' }"
                     :editable="false"
                     :popup-style="popUpStyle"
                     v-model="birthday"
                     :placeholder="'04.10.1990'"
                  />
               </div>
            </div>
            <div class="welcome__item select">
               <label for="gender">{{ $t('Profile.genderEditLabel') }}</label>
               <select name="gender" v-model="gender" id="gender">
                  <option :style="background" value="male">Male</option>
                  <option :style="background" value="female">Female</option>
                  <option :style="background" value="uncertain" selected>Uncertain</option>
               </select>
            </div>
            <div class="welcome__item">
               <div class="welcome__actions">
                  <CheckBox @changed="doNotDisturbToggle">{{ $t('Welcome.doNotDisturb') }}</CheckBox>
                  <button class="welcome__btn" type="button" @click="btnContinue">
                     {{ $t('Welcome.btnContinue') }}
                  </button>
               </div>
            </div>
         </div>
      </div>
   </AdviceModal>
</template>

<script>
import AdviceModal from '../../components/AdviceComponents/AdviceModal.vue';
import AdviceText from '../../components/AdviceComponents/AdviceText.vue';
import CheckBox from '../../components/UI/CheckBox.vue';
import DatePicker from 'vue2-datepicker';
import '../../css/WelcomeDatapicker.css';
import mutations from '../../store/mutation-types';

export default {
   components: {
      AdviceModal,
      CheckBox,
      DatePicker,
      AdviceText,
   },

   data() {
      return {
         birthday: '',
         gender: 'uncertain',
         doNotDisturb: false,
      };
   },

   computed: {
      popUpStyle() {
         return {
            zIndex: 10000,
            top: 'unset',
            bottom: '132px',
            position: 'fixed',
         };
      },

      profile() {
         return this.$store.getters['profile/getProfile'];
      },
   },

   watch: {
      birthday() {
         this.$store.commit(`profile/${mutations.UPDATE_PROFILE}`, { birthday: this.birthday });
      },
      gender() {
         this.$store.commit(`profile/${mutations.UPDATE_PROFILE}`, { gender: this.gender });
      },
   },

   methods: {
      doNotDisturbToggle(data) {
         this.doNotDisturb = data;
         this.$store.commit(`profile/${mutations.UPDATE_PROFILE}`, { Disturb: this.doNotDisturb });
      },

      closeModal() {
         if (this.birthday || this.gender !== 'uncertain' || this.doNotDisturb)
            this.$store.dispatch('profile/updateProfile');

         this.profile.DisturbSecond
            ? this.$router.push({ name: 'app-main-all' })
            : this.$router.push({ name: 'app-instructions' });
      },

      btnContinue() {
         this.closeModal();
      },

      background() {
         return this.currentTheme === 'white'
            ? ''
            : {
                 background: this.currentTheme.background,
              };
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.welcome
   &__box
      margin: 30px auto 0
      padding: 0 20px
      max-width: 1300px
      width: 100%

   &__greet
      font-size: 2rem

   &__img
      margin-bottom: 30px

   &__item
      margin: 20px 0

      & label
         font-size: 1.5rem
         margin-bottom: 10px

      & input, & select
         display: block
         width: 100%
         font-size: 1.3rem
         padding: 10px
         border: 1px solid $app-main-color
         border-radius: 5px
         background: #fff

   &__btn
      background-color: $app-main-color
      color: $font-color-white
      padding: 10px 20px
      border-radius: 20px 20px 20px 20px
      font-size: 1.5rem
      display: block
      transition: all 0.3s
      margin-left: 40px

      &:active
         background-color: darken($app-main-color, 20%)

      &:hover
         background-color: darken($app-main-color, 10%)

   &__actions
      display: flex
      align-items: center
      justify-content: center

#dark .welcome__greet, #dark label, #dark select, #dark option
   color: $app-dark-font-color

#dark select
   background-color: lighten($app-dark-background, 20%)

@media (max-width: 380px)
   .welcome__btn
      font-size: 1.3rem

.select
   position: relative

   &::after
      content: ''
      position: absolute
      width: 0
      height: 0
      border-style: solid
      border-width: 10px 5px 0 5px
      border-color: #008545 transparent transparent transparent
      top: 76%
      right: 1rem
      transform: translateY(-50%)
</style>